/*!
 * Gameforest 4.1.2 (https://yakuthemes.com)
 * Copyright 2013-2017 yakuthemes.com
 * Theme URL: https://themeforest.net/item/gameforest-responsive-gaming-html-theme/5007730
 * Licensed under Themeforest Standard (https://themeforest.net/licenses/standard)
 */
body {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 1.5;
  color: #848484;
  background: #fafafa;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

a:hover, a:active, a:focus {
  color: #2575dc;
  text-decoration: none;
  outline: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #4f4f4f;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  margin: 0 0 10px 0;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 17px;
}

h5 + .form-group {
  margin-top: 20px;
}

h6 {
  font-size: 15px;
}

button {
  cursor: pointer;
}

pre {
  padding: 25px;
  background-color: #FFF;
  border-color: #EAEAEA;
}

b, strong {
  font-weight: bold;
}

p {
  margin-bottom: 20px;
}

p b, p strong {
  color: #5d5d5d;
}

i {
  display: inline-block;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul ul {
  padding-left: 20px;
}

b, strong {
  color: #585858;
}

code {
  background-color: #E6F1FF;
  color: #2776dc;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  padding: 3px 6px;
}

.lead {
  font-size: 18px;
  line-height: 25px;
}

iframe {
  width: 100%;
  border: 0;
}

button.close {
  outline: 0 !important;
}

img {
  max-width: 100%;
  image-rendering: -webkit-optimize-contrast;
}

.blockquote {
  border-width: 0 0 0 3px;
  border-style: solid;
  border-color: #696969;
  margin-bottom: 30px;
  margin-top: 30px;
  background-color: #e4e4e4;
  padding: 25px 30px;
  font-weight: 500;
  font-size: 17px;
  color: #5d5d5d;
}

.blockquote p {
  margin-bottom: 0;
}

.subtitle {
  border-left: 3px solid #2575dc;
  padding: 5px 0 5px 15px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 20px;
}

.breadcrumbs {
  padding: 11px 1rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid #e2e2e2;
}

.breadcrumb {
  background: transparent;
  border-radius: 0;
  margin: 0;
  border: 0;
  padding: 0;
}

.breadcrumb > li {
  float: left;
  font-size: 14px;
}

.breadcrumb > li > a {
  color: #737373;
  border-radius: 0px;
  font-size: 14px;
}

.breadcrumb > li:first-child a {
  padding-left: 0;
}

.breadcrumb > li + li.pull-right:before {
  content: "";
  display: none;
}

.breadcrumb > li + li:before {
  content: "/";
  color: #b5b5b5;
  margin: 0 7px;
}

.breadcrumb > li.active {
  font-size: 14px;
}

#header {
  width: 100%;
  height: 62px;
  background: #242629;
  position: relative;
  z-index: 999;
}

#header .logo {
  float: left;
  height: 62px;
  margin-right: 25px;
  padding: 20px 0;
}

#header .container {
  position: static;
}

#header .navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 0;
  padding: 0;
  border-radius: 0;
  margin: 0;
  height: 62px;
  position: static;
}

#header .navbar .navbar-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#header .navbar .navbar-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#header .nav > ul > li,
#header .nav > ul > li {
  display: inline-block;
  position: relative;
}

#header .nav > ul > li.has-dropdown > a:after,
#header .nav > ul > li.has-dropdown > a:after {
  content: "\f078";
  font-family: "FontAwesome";
  font-size: 8px;
  float: right;
  margin-left: 5px;
}

#header .nav > ul > li.has-dropdown.open > a,
#header .nav > ul > li.has-dropdown.open > a {
  background-color: inherit;
  color: #fff;
}

#header .nav > ul > li:hover > ul,
#header .nav > ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
}

#header .nav > ul > li > a,
#header .nav > ul > li > a {
  color: #B1B1B1;
  font-size: 15px;
  font-weight: 500;
  padding: 0 10px;
  line-height: 62px;
  display: inline-block;
}

#header .nav > ul > li > a:hover,
#header .nav > ul > li > a:hover {
  color: #fff;
}

#header .nav > ul > li > a > .badge,
#header .nav > ul > li > a > .badge {
  position: absolute;
  top: 10px;
  right: 0;
  font-weight: bold;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  padding: 0;
}

#header .nav > ul > li.dropdown-profile > a:after,
#header .nav > ul > li.dropdown-profile > a:after {
  content: "\f078";
  font-family: "FontAwesome";
  font-size: 8px;
  float: right;
  margin-left: 8px;
}

#header .nav > ul > li.dropdown-notification > .dropdown-menu,
#header .nav > ul > li.dropdown-notification > .dropdown-menu {
  min-width: 240px !important;
}

#header .nav > ul > li.dropdown-notification > .dropdown-menu .badge,
#header .nav > ul > li.dropdown-notification > .dropdown-menu .badge {
  padding: 5px 2px;
  margin-right: 7px;
}

#header .nav > ul > li.dropdown-notification > .dropdown-menu .date,
#header .nav > ul > li.dropdown-notification > .dropdown-menu .date {
  float: right;
  background-color: #f5f5f5;
  padding: 4px 7px;
  font-size: 11px;
  margin-top: -1px;
  color: #8c8c8c;
  font-weight: 500;
}

#header .nav > ul > li.dropdown-notification > .dropdown-menu a,
#header .nav > ul > li.dropdown-notification > .dropdown-menu a {
  border-bottom: 1px solid #f3f3f3;
  padding: 11px 13px;
}

#header .nav > ul > li.dropdown-notification > .dropdown-menu .dropdown-block,
#header .nav > ul > li.dropdown-notification > .dropdown-menu .dropdown-block {
  max-height: 200px;
  overflow-y: scroll;
}

#header .nav > ul > li.dropdown.open > a,
#header .nav > ul > li.dropdown.open > a {
  color: #fff;
  background-color: transparent;
}

#header .nav > ul > li.dropdown > .dropdown-menu,
#header .nav > ul > li.dropdown > .dropdown-menu {
  opacity: 1;
  visibility: visible;
  background-color: #fff;
  min-width: 190px;
  margin-top: 0px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  padding-bottom: 5px;
  border-top: 0;
}

#header .nav > ul > li.dropdown > .dropdown-menu .dropdown-header,
#header .nav > ul > li.dropdown > .dropdown-menu .dropdown-header {
  background-color: #fafafa;
  border-bottom: 1px solid #efefef;
  padding: 12px 15px;
  font-weight: 700;
  font-size: 14px;
  color: #545454;
  margin-top: -5px;
}

#header .nav > ul > li.dropdown > .dropdown-menu .dropdown-header i,
#header .nav > ul > li.dropdown > .dropdown-menu .dropdown-header i {
  margin-right: 5px;
}

#header .nav > ul > li.dropdown > .dropdown-menu .dropdown-footer,
#header .nav > ul > li.dropdown > .dropdown-menu .dropdown-footer {
  border-top: 1px solid #efefef;
  text-align: center;
}

#header .nav > ul > li.dropdown > .dropdown-menu .dropdown-footer a,
#header .nav > ul > li.dropdown > .dropdown-menu .dropdown-footer a {
  background-color: #fafafa;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

#header .nav > ul > li.dropdown > .dropdown-menu .divider,
#header .nav > ul > li.dropdown > .dropdown-menu .divider {
  margin: 5px 0;
}

#header .nav > ul > li > ul,
#header .nav > ul > li > ul {
  position: absolute;
  left: 0;
  z-index: 99;
  top: 62px;
  min-width: 220px;
  background-color: #242629;
  padding: 5px 0 10px 0;
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: .12s ease-in-out;
  transition: .12s ease-in-out;
  background-repeat: no-repeat;
  background-position: bottom right;
}

#header .nav > ul > li > ul > li.has-dropdown.open > a,
#header .nav > ul > li > ul > li.has-dropdown.open > a {
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
}

#header .nav > ul > li > ul > li a,
#header .nav > ul > li > ul > li a {
  padding: 6px 23px;
  font-size: 14px;
  font-weight: 400;
  display: block;
  color: #949494;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

#header .nav > ul > li > ul > li a:hover,
#header .nav > ul > li > ul > li a:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
}

#header .nav > ul > li > ul > li:hover > ul,
#header .nav > ul > li > ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
}

#header .nav > ul > li > ul > li > ul,
#header .nav > ul > li > ul > li > ul {
  position: absolute;
  min-width: 220px;
  margin-top: -40px;
  padding: 7px 0 10px 0;
  left: 220px;
  background-color: #242629;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

#header .nav > ul > li > ul > li > ul:before,
#header .nav > ul > li > ul > li > ul:before {
  content: "";
  width: 30px;
  height: 100%;
  position: absolute;
  left: -30px;
}

#header .nav > ul > li.mega-menu,
#header .nav > ul > li.mega-menu {
  position: static;
}

#header .nav > ul > li.mega-menu > ul,
#header .nav > ul > li.mega-menu > ul {
  width: 100%;
  padding: 20px 0 30px 0;
}

#header .nav > ul > li.mega-menu > ul a,
#header .nav > ul > li.mega-menu > ul a {
  padding: 6px 0;
}

#header .nav > ul > li.mega-menu > ul a:hover,
#header .nav > ul > li.mega-menu > ul a:hover {
  background-color: transparent;
}

#header .nav > ul > li.mega-menu > ul a i,
#header .nav > ul > li.mega-menu > ul a i {
  width: 20px;
  text-align: center;
  margin-right: 5px;
}

#header .nav > ul > li.mega-menu > ul .title,
#header .nav > ul > li.mega-menu > ul .title {
  text-transform: uppercase;
  font-weight: 500;
  color: #e4e4e4;
  font-size: 13px;
  display: block;
  margin-bottom: 10px;
}

#header .nav > ul > li.mega-menu.mega-games > ul,
#header .nav > ul > li.mega-menu.mega-games > ul {
  padding: 0 0 5px 0;
}

#header .nav > ul > li.mega-menu.mega-games .row,
#header .nav > ul > li.mega-menu.mega-games .row {
  margin: 0 -10px;
}

#header .nav > ul > li.mega-menu.mega-games .col,
#header .nav > ul > li.mega-menu.mega-games .col {
  padding: 0 10px;
}

#header .nav > ul > li.mega-menu.mega-games .img,
#header .nav > ul > li.mega-menu.mega-games .img {
  position: relative;
  margin-bottom: 15px;
}

#header .nav > ul > li.mega-menu.mega-games .img > a,
#header .nav > ul > li.mega-menu.mega-games .img > a {
  display: block;
  padding: 0;
}

#header .nav > ul > li.mega-menu.mega-games .img img,
#header .nav > ul > li.mega-menu.mega-games .img img {
  width: 100%;
}

#header .nav > ul > li.mega-menu.mega-games .img .badge,
#header .nav > ul > li.mega-menu.mega-games .img .badge {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 11px;
  padding: 6px 10px;
}

#header .nav > ul > li.mega-menu.mega-games h4,
#header .nav > ul > li.mega-menu.mega-games h4 {
  margin: 0;
}

#header .nav > ul > li.mega-menu.mega-games .row a,
#header .nav > ul > li.mega-menu.mega-games .row a {
  color: #ccc;
  font-size: 15px;
  font-weight: 400;
  padding: 0;
}

#header .nav > ul > li.mega-menu.mega-games .row a:hover,
#header .nav > ul > li.mega-menu.mega-games .row a:hover {
  color: #fff;
  background-color: transparent;
}

#header .nav > ul > li.mega-menu.mega-games .col > span,
#header .nav > ul > li.mega-menu.mega-games .col > span {
  font-size: 14px;
  font-weight: 400;
  color: #848484;
  margin-top: 3px;
  margin-bottom: 10px;
  display: block;
}

#header .dropdown-profile img {
  width: 36px;
  height: 36px;
  margin-right: 10px;
  border-radius: 50%;
}

#header .navbar-right {
  margin: 0;
}

#header .navbar-right .dropdown-menu {
  left: auto;
  right: 0;
}

#header .navbar-backdrop {
  background-color: #242629;
}

.navbar-search {
  background-color: #242629;
  padding: 5px 15px 15px 15px;
  position: absolute;
  width: 100%;
  top: -62px;
  left: 0;
  z-index: -2;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.navbar-search form {
  position: relative;
}

.navbar-search .form-control {
  font-size: 15px;
  font-weight: 400;
  border-color: transparent;
  height: 38px;
  line-height: 38px;
  padding-right: 45px;
}

.navbar-search .form-control:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: transparent;
}

.navbar-search .close {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 18px;
  opacity: .5;
  text-shadow: none;
  height: 38px;
  line-height: 38px;
  width: 38px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}

.navbar-search .close:hover {
  opacity: .7;
}

.navbar-search-open .navbar-search {
  top: 62px;
}

.navbar-toggle,
.nav-responsive {
  display: none;
}

#footer {
  background-color: #242629;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.4);
  padding: 65px 0 20px 0;
}

#footer .footer-title {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0 0 25px 0;
}

#footer .footer-social {
  text-align: center;
  margin-bottom: 15px;
}

#footer .footer-social a {
  font-size: 16px;
  color: #fff;
  padding: 0 10px;
}

#footer .footer-bottom {
  text-align: center;
  margin-top: 45px;
}

#footer ul > li > a {
  color: rgba(255, 255, 255, 0.4);
  padding: 0 0 13px 0;
  display: block;
  font-size: 0.75rem;
  text-transform: uppercase;
}

#footer ul > li > a:hover {
  color: #fff;
}

#footer a {
  color: rgba(255, 255, 255, 0.4);
}

#footer a:hover {
  color: #fff;
}

#footer .input-group .form-control {
  border: 0;
  color: #848484;
}

#footer .input-group .btn {
  margin-left: 0;
}

.search-open.search-active {
  padding-top: 98px;
}

.fixed-header {
  padding-top: 62px;
  -webkit-transition: 0.25s;
  transition: 0.25s;
}

.fixed-header #header {
  position: fixed;
  z-index: 1040;
  top: 0;
}

.header-scroll #header {
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
}

.search-open header {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.sidebar {
  border-left: 1px solid #ececec;
  padding-left: 29px;
  height: 100%;
}

.sidebar-left {
  border-left: 0;
  border-right: 1px solid #ececec;
  padding-left: 0;
  padding-right: 29px;
}

section {
  padding: 50px 0;
}

section::after {
  display: block;
  content: "";
  clear: both;
}

section > .container-fluid {
  padding: 0;
}

section > .container-fluid > .row {
  margin: 0;
}

section > .container-fluid > .row > div {
  padding: 0;
}

section.bg-image {
  position: relative;
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
  padding: 80px 0;
  overflow: hidden;
}

section.bg-image .overlay {
  background: #252525;
  opacity: .76;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

section.bg-image .overlay-dark {
  background: #252525;
  opacity: .9;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

section.bg-image .overlay-light {
  background: #252525;
  opacity: .55;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

section.bg-image .container {
  position: relative;
  z-index: 3;
}

.video-play {
  position: relative;
  background-color: #000;
}

.video-play .video-play-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  z-index: 3;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.video-play h5 {
  position: absolute;
  top: 30px;
  left: 30px;
  background-color: #242629;
  color: rgba(255, 255, 255, 0.9);
  padding: 13px 20px;
  font-weight: 300;
  font-size: 15px;
  z-index: 5;
  border-radius: 5px;
  border-left: 3px solid #2575dc;
}

.video-play h5 i {
  font-size: 13px;
  color: #dc2745;
  margin-right: 5px;
}

.video-play.video-live h5 {
  border-color: #dc2745;
}

.video-play .length {
  position: absolute;
  bottom: 30px;
  right: 30px;
  background-color: rgba(25, 25, 25, 0.9);
  color: rgba(255, 255, 255, 0.9);
  padding: 3px 8px;
  font-weight: 300;
  font-size: 14px;
  border-radius: 3px;
}

.video-play .fb-like {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 5;
}

.video-play .video-play-icon i {
  background-color: #242629;
  display: inline-block;
  color: #fff;
  font-size: 22px;
  width: 84px;
  height: 84px;
  line-height: 84px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: .1s ease-in-out;
  transition: .1s ease-in-out;
  border-radius: 50%;
}

.video-play .video-play-icon i:hover {
  color: #fff;
  background-color: #dc2745;
  -webkit-box-shadow: 0 1px 25px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 25px 0 rgba(0, 0, 0, 0.2);
}

.video-caption {
  position: static !important;
}

section.bg-secondary {
  background-color: #fff;
  border-width: 1px 0;
  border-style: solid;
  border-color: #e8e8e8;
}

.toolbar-custom {
  margin-bottom: 25px;
}

.toolbar-custom::after {
  display: block;
  content: "";
  clear: both;
}

.toolbar-custom .form-group {
  margin-bottom: 0;
}

.toolbar {
  background-color: #fff;
  padding: 20px 0;
  height: 80px;
  margin-bottom: 30px;
  border-bottom: 1px solid #e8e8e8;
}

.toolbar + section {
  padding-top: 0;
}

.toolbar form {
  float: left;
  min-width: 400px;
}

.toolbar h5 {
  float: left;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  padding-top: 10px;
  margin-right: 20px;
}

.toolbar h5 i {
  margin-right: 13px;
}

.toolbar h5 span {
  margin-left: 5px;
  font-weight: 400;
}

.toolbar ul.toolbar-nav {
  float: right;
}

.toolbar ul.toolbar-nav li {
  display: inline-block;
  padding: 0 8px;
}

.toolbar ul.toolbar-nav li:last-child {
  padding-right: 0;
}

.toolbar ul.toolbar-nav li a {
  color: #5a5a5a;
  font-weight: 500;
  font-size: 15px;
  padding: 23px 3px;
  display: inline-block;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.toolbar ul.toolbar-nav li:hover a {
  color: #2575dc;
  -webkit-box-shadow: 0 3px 0 0 #2575dc;
          box-shadow: 0 3px 0 0 #2575dc;
}

.toolbar ul.toolbar-nav li.active a {
  color: #2575dc;
  font-weight: 500;
  -webkit-box-shadow: 0 3px 0 0 #2575dc;
          box-shadow: 0 3px 0 0 #2575dc;
}

.toolbar.toolbar-links {
  padding: 0;
  height: auto;
  min-height: 69px;
}

.toolbar.toolbar-links h5 {
  margin-top: 16px;
}

.toolbar.toolbar-links .btn {
  margin-top: 16px;
}

.toolbar.toolbar-links form {
  margin-top: 15px;
  min-width: 380px;
}

.toolbar.toolbar-links .form-group {
  margin: 0;
}

.toolbar.toolbar-profile {
  padding: 0;
  height: auto;
}

.toolbar.toolbar-profile .profile-avatar {
  position: absolute;
  margin-top: -110px;
  z-index: 3;
  background-color: #fff;
  padding: 6px;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
}

.toolbar.toolbar-profile .profile-avatar img {
  border-radius: 3px;
  width: 200px;
  height: 200px;
}

.toolbar.toolbar-profile .sticky {
  display: none;
  float: left;
}

.toolbar.toolbar-profile .sticky .profile-info {
  float: left;
  margin-top: 8px;
}

.toolbar.toolbar-profile .sticky h5 {
  float: none;
  font-size: 15px;
  margin-top: 0;
  display: block;
}

.toolbar.toolbar-profile .sticky img {
  float: left;
  border-radius: 3px;
  width: 45px;
  height: 45px;
  margin-right: 15px;
  margin-top: 12px;
}

.toolbar.toolbar-profile .sticky span {
  float: left;
  display: inline-block;
  font-size: 14px;
}

.toolbar.toolbar-profile ul.toolbar-nav {
  float: none;
  padding-left: 235px;
}

.toolbar.toolbar-profile h5 {
  margin-top: 15px;
}

.toolbar.toolbar-profile .btn {
  margin-top: 16px;
}

.is-sticky .toolbar-profile .profile-avatar {
  position: relative;
  margin: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 0;
}

.is-sticky .toolbar-profile .profile-avatar img {
  width: 45px;
  height: 45px;
  border-radius: 4px;
  display: none;
}

.is-sticky .toolbar-profile .sticky {
  display: inline-block;
}

.is-sticky .toolbar-profile .sticky img {
  display: inline-block;
}

.is-sticky .toolbar-profile ul.toolbar-nav {
  float: left;
  padding-left: 10px;
}

.toolbar-secondary {
  background-color: transparent;
  border: 0;
}

.promo {
  padding: 35px 0;
}

.promo > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.promo > div h2 {
  color: #FFF;
  font-weight: 300;
  font-size: 20px;
  margin: 0 30px 0 0;
  display: inline-block;
}

.promo > div h3 {
  color: #FFF;
  font-weight: 300;
  font-size: 18px;
  margin: 0 30px 0 0;
  display: inline-block;
}

.promo-left > div {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.promo-subscribe {
  padding: 17px 0;
}

.promo-subscribe > div h3 {
  font-size: 16px;
}

section.error-404 {
  text-align: center;
  padding: 140px 0;
}

section.error-404 p {
  color: #d3d3d3;
  font-weight: 400;
}

section.error-404 .heading {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

section.error-404 .heading h2 {
  font-size: 100px;
  font-weight: 700;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #fff;
}

section.error-404 form {
  margin: 30px 0 10px 0;
}

.review {
  color: #d3d3d3;
}

.review .review-title {
  color: #fff;
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: 700;
}

.review > p {
  color: #d3d3d3;
  font-size: 15px;
  margin-bottom: 40px;
}

.review .progress {
  margin-bottom: 18px;
}

.review .review-good {
  padding-left: 20px;
}

.review .review-bad,
.review .review-good {
  display: block;
}

.review .review-bad h5,
.review .review-good h5 {
  color: #6ab961;
  font-weight: 700;
  margin-bottom: 20px;
}

.review .review-bad p,
.review .review-good p {
  color: #d3d3d3;
  margin-bottom: 3px;
  font-size: 15px;
  padding-left: 20px;
}

.review .review-bad p:before,
.review .review-good p:before {
  content: "\f00c";
  font-family: "FontAwesome";
  margin-left: -15px;
  margin-right: 10px;
  color: #6ab961;
}

.review .review-bad.review-bad h5,
.review .review-good.review-bad h5 {
  color: #e9594a;
}

.review .review-bad.review-bad p:before,
.review .review-good.review-bad p:before {
  content: "\f068";
  color: #e9594a;
}

.hero {
  padding: 0;
  min-height: 350px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.hero .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: .8;
  z-index: 2;
  background-color: #2d2d2d;
}

.hero .overlay-light {
  background: #252525;
  opacity: .75;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.hero .overlay-primary {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: .85;
  z-index: 2;
  background-color: #2575dc;
}

.hero .btn .fa-play {
  font-size: 12px;
}

.hero .hero-block {
  z-index: 3;
  position: relative;
  text-align: center;
}

.hero .hero-block p {
  color: #d3d3d3;
  font-size: 16px;
  margin-top: 10px;
}

.hero .hero-title {
  font-size: 28px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 5px;
}

.hero .btn {
  margin-top: 10px;
}

.hero-blog {
  margin-bottom: 40px;
}

.hero-block .hero-left {
  float: left;
}

.hero-block .hero-right {
  float: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.hero-review {
  min-height: 430px;
}

.hero-game {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.hero-game .hero-block {
  text-align: left;
}

.hero-game .hero-block p {
  margin-top: 10px;
  font-weight: 300;
}

.hero-game .hero-review {
  margin-left: 25px;
  text-align: center;
  min-height: inherit;
}

.hero-game .hero-review .easypiechart:hover {
  color: #fff;
}

.hero-game .hero-review a:not(.easypiechart) {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px dashed rgba(255, 255, 255, 0.4);
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  display: inline-block;
  color: #fff;
  text-align: center;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.hero-game .hero-review a:not(.easypiechart):hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.hero-game .hero-review > span {
  display: block;
  margin-bottom: 10px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}

.hero-profile {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.hero-profile .hero-block {
  text-align: left;
  padding-left: 240px;
  padding-bottom: 10px;
}

.hero-profile .hero-block::after {
  display: block;
  content: "";
  clear: both;
}

.hero-profile .hero-block h5 {
  color: #fff;
  font-size: 18px;
  float: left;
}

.hero-profile .hero-block .btn-add {
  float: left;
  margin-top: -5px;
  margin-left: 15px;
  padding: 6px 10px;
}

.btn {
  border-color: transparent;
  border-radius: 4px;
  padding: 10px 15px;
  -webkit-transition: 0.25s;
  transition: 0.25s;
  line-height: 1.25;
  font-size: 14px;
  cursor: pointer;
}

.btn i {
  margin-left: 5px;
}

.btn:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn.active, .btn:active:focus {
  -webkit-box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
          box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.btn-effect {
  position: relative;
}

.btn-effect:hover {
  padding-right: 35px;
}

.btn-effect:hover.btn-rounded {
  padding-right: 45px;
}

.btn-effect:after {
  position: absolute;
  margin-left: -10px;
  margin-top: 3px;
  opacity: 0;
  font-size: 11px;
  content: "\f054";
  font-family: "FontAwesome";
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.btn-effect:hover:after {
  opacity: 1;
  margin-left: 10px;
}

.btn-rounded {
  border-radius: 30px;
  padding: 14px 20px;
}

.btn-lg {
  font-size: 15px;
  padding: 16px 27px;
}

.btn-sm {
  font-size: 13px;
  padding: 7px 10px;
}

.btn-xs {
  padding: 5px 8px;
}

.btn-shadow {
  -webkit-box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.2);
}

.btn-circle {
  border-radius: 50%;
  padding: 0;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
}

.btn-circle i {
  margin: 0;
}

.btn-icon-circle {
  border-radius: 50%;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.btn-icon-circle i {
  margin: 0;
}

.btn-icon-circle.btn-xs {
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.btn-icon-circle.btn-sm {
  width: 36px;
  height: 36px;
  line-height: 36px;
}

.btn-icon-circle.btn-lg {
  width: 46px;
  height: 46px;
  line-height: 46px;
  font-size: 18px;
}

.btn-icon {
  padding: 10px 12px;
}

.btn-icon i {
  margin: 0;
}

.btn-icon-left {
  text-align: left;
}

.btn-icon-left i {
  margin-right: 5px;
  margin-left: 0;
}

.btn.btn-link {
  color: rgba(0, 0, 0, 0.6);
}

.btn.btn-link:hover {
  color: #2776dc;
  text-decoration: none;
}

.input-group .btn {
  line-height: 1.3;
}

.btn-social i {
  width: 15px;
}

.btn-social.btn-icon {
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
}

.btn-social.btn-icon i {
  font-size: 15px;
  width: auto;
}

.btn-social.btn-link {
  background-color: transparent;
  padding: 0 5px;
  margin: 0;
  width: auto;
  border: 0;
}

.btn-social.btn-link i {
  width: auto;
  font-size: 18px;
  margin: 0;
}

.btn-social.btn-link:focus, .btn-social.btn-link:hover {
  background-color: transparent;
}

.btn-social.btn-block {
  text-align: left;
}

.btn-social.btn-block i {
  margin-left: 0;
  margin-right: 5px;
}

.btn-social.btn-default {
  background-color: #222;
  border-color: #222;
}

.btn-social.btn-default:active:focus, .btn-social.btn-default:active, .btn-social.btn-default:focus, .btn-social.btn-default:hover {
  color: #fff;
}

.btn-social.btn-effect {
  position: relative;
  overflow: hidden;
}

.btn-social.btn-effect i {
  line-height: inherit;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  display: block;
}

.btn-social.btn-effect:hover {
  padding: 0;
}

.btn-social.btn-effect:hover i:first-child {
  margin-top: -40px;
}

.btn-social.btn-effect:after {
  display: none;
}

.btn-primary {
  color: #fff;
  background-color: #2575dc;
  border-color: #2575dc;
}

.btn-primary:focus, .btn-primary:hover,
.show > .btn-primary.dropdown-toggle,
.show > .btn-primary.dropdown-toggle:hover,
.show > .btn-primary.dropdown-toggle:focus {
  color: #fff;
  background-color: #327dde;
  border-color: #327dde;
  outline: 0;
}

.btn-primary:active:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: 0;
}

.btn-primary.active, .btn-primary:active {
  color: #fff;
  background-color: #2575dc;
  border-color: #2575dc;
}

.btn-primary.active:hover, .btn-primary.active:focus, .btn-primary.active.focus, .btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus {
  color: #fff;
  background-color: #2575dc;
  border-color: #2575dc;
}

.btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary.focus {
  background-color: #2575dc;
  border-color: #2575dc;
}

.btn-info {
  color: #fff;
  background-color: #52BAFF;
  border-color: #52BAFF;
}

.btn-info:focus, .btn-info:hover,
.show > .btn-info.dropdown-toggle,
.show > .btn-info.dropdown-toggle:hover,
.show > .btn-info.dropdown-toggle:focus {
  color: #fff;
  background-color: #61c0ff;
  border-color: #61c0ff;
  outline: 0;
}

.btn-info:active:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: 0;
}

.btn-info.active, .btn-info:active {
  color: #fff;
  background-color: #52BAFF;
  border-color: #52BAFF;
}

.btn-info.active:hover, .btn-info.active:focus, .btn-info.active.focus, .btn-info:active:hover, .btn-info:active:focus, .btn-info:active.focus {
  color: #fff;
  background-color: #52BAFF;
  border-color: #52BAFF;
}

.btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled.focus, .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled].focus,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info.focus {
  background-color: #52BAFF;
  border-color: #52BAFF;
}

.btn-danger {
  color: #fff;
  background-color: #e9594a;
  border-color: #e9594a;
}

.btn-danger:focus, .btn-danger:hover,
.show > .btn-danger.dropdown-toggle,
.show > .btn-danger.dropdown-toggle:hover,
.show > .btn-danger.dropdown-toggle:focus {
  color: #fff;
  background-color: #eb6658;
  border-color: #eb6658;
  outline: 0;
}

.btn-danger:active:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: 0;
}

.btn-danger.active, .btn-danger:active {
  color: #fff;
  background-color: #e9594a;
  border-color: #e9594a;
}

.btn-danger.active:hover, .btn-danger.active:focus, .btn-danger.active.focus, .btn-danger:active:hover, .btn-danger:active:focus, .btn-danger:active.focus {
  color: #fff;
  background-color: #e9594a;
  border-color: #e9594a;
}

.btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled].focus,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger.focus {
  background-color: #e9594a;
  border-color: #e9594a;
}

.btn-warning {
  color: #fff;
  background-color: #FFA14F;
  border-color: #FFA14F;
}

.btn-warning:focus, .btn-warning:hover,
.show > .btn-warning.dropdown-toggle,
.show > .btn-warning.dropdown-toggle:hover,
.show > .btn-warning.dropdown-toggle:focus {
  color: #fff;
  background-color: #ffa95e;
  border-color: #ffa95e;
  outline: 0;
}

.btn-warning:active:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: 0;
}

.btn-warning.active, .btn-warning:active {
  color: #fff;
  background-color: #FFA14F;
  border-color: #FFA14F;
}

.btn-warning.active:hover, .btn-warning.active:focus, .btn-warning.active.focus, .btn-warning:active:hover, .btn-warning:active:focus, .btn-warning:active.focus {
  color: #fff;
  background-color: #FFA14F;
  border-color: #FFA14F;
}

.btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled].focus,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning.focus {
  background-color: #FFA14F;
  border-color: #FFA14F;
}

.btn-success {
  color: #fff;
  background-color: #6ab961;
  border-color: #6ab961;
}

.btn-success:focus, .btn-success:hover,
.show > .btn-success.dropdown-toggle,
.show > .btn-success.dropdown-toggle:hover,
.show > .btn-success.dropdown-toggle:focus {
  color: #fff;
  background-color: #74be6c;
  border-color: #74be6c;
  outline: 0;
}

.btn-success:active:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: 0;
}

.btn-success.active, .btn-success:active {
  color: #fff;
  background-color: #6ab961;
  border-color: #6ab961;
}

.btn-success.active:hover, .btn-success.active:focus, .btn-success.active.focus, .btn-success:active:hover, .btn-success:active:focus, .btn-success:active.focus {
  color: #fff;
  background-color: #6ab961;
  border-color: #6ab961;
}

.btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled].focus,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success.focus {
  background-color: #6ab961;
  border-color: #6ab961;
}

.btn-dark {
  color: #fff;
  background-color: #2c3035;
  border-color: #2c3035;
}

.btn-dark:focus, .btn-dark:hover,
.show > .btn-dark.dropdown-toggle,
.show > .btn-dark.dropdown-toggle:hover,
.show > .btn-dark.dropdown-toggle:focus {
  color: #fff;
  background-color: #33383d;
  border-color: #33383d;
  outline: 0;
}

.btn-dark:active:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: 0;
}

.btn-dark.active, .btn-dark:active {
  color: #fff;
  background-color: #2c3035;
  border-color: #2c3035;
}

.btn-dark.active:hover, .btn-dark.active:focus, .btn-dark.active.focus, .btn-dark:active:hover, .btn-dark:active:focus, .btn-dark:active.focus {
  color: #fff;
  background-color: #2c3035;
  border-color: #2c3035;
}

.btn-dark.disabled:hover, .btn-dark.disabled:focus, .btn-dark.disabled.focus, .btn-dark[disabled]:hover, .btn-dark[disabled]:focus, .btn-dark[disabled].focus,
fieldset[disabled] .btn-dark:hover,
fieldset[disabled] .btn-dark:focus,
fieldset[disabled] .btn-dark.focus {
  background-color: #2c3035;
  border-color: #2c3035;
}

.btn-secondary {
  color: #787a7b;
  background-color: #fafafa;
  border-color: #e6e6e6;
}

.btn-secondary:focus, .btn-secondary:hover,
.show > .btn-secondary.dropdown-toggle,
.show > .btn-secondary.dropdown-toggle:hover,
.show > .btn-secondary.dropdown-toggle:focus {
  color: #787a7b;
  background-color: white;
  border-color: white;
  outline: 0;
}

.btn-secondary:active:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: 0;
}

.btn-secondary.active, .btn-secondary:active {
  color: #787a7b;
  background-color: #fafafa;
  border-color: #e6e6e6;
}

.btn-secondary.active:hover, .btn-secondary.active:focus, .btn-secondary.active.focus, .btn-secondary:active:hover, .btn-secondary:active:focus, .btn-secondary:active.focus {
  color: #787a7b;
  background-color: #fafafa;
  border-color: #e6e6e6;
}

.btn-secondary.disabled:hover, .btn-secondary.disabled:focus, .btn-secondary.disabled.focus, .btn-secondary[disabled]:hover, .btn-secondary[disabled]:focus, .btn-secondary[disabled].focus,
fieldset[disabled] .btn-secondary:hover,
fieldset[disabled] .btn-secondary:focus,
fieldset[disabled] .btn-secondary.focus {
  background-color: #fafafa;
  border-color: #e6e6e6;
}

.btn-secondary.active, .btn-secondary:active:focus, .btn-secondary:focus, .btn-secondary:hover,
.show > .btn-secondary.dropdown-toggle,
.show > .btn-secondary.dropdown-toggle:hover,
.show > .btn-secondary.dropdown-toggle:focus {
  color: #787a7b;
  border-color: #d9d9d9;
  background-color: #fafafa;
}

.btn-light,
.btn-default {
  color: #717171;
  background-color: #FFF;
  border-color: #e2e2e2;
}

.btn-light:focus, .btn-light:hover,
.show > .btn-light.dropdown-toggle,
.show > .btn-light.dropdown-toggle:hover,
.show > .btn-light.dropdown-toggle:focus,
.btn-default:focus,
.btn-default:hover,
.show >
.btn-default.dropdown-toggle,
.show >
.btn-default.dropdown-toggle:hover,
.show >
.btn-default.dropdown-toggle:focus {
  color: #717171;
  background-color: white;
  border-color: white;
  outline: 0;
}

.btn-light:active:focus,
.btn-default:active:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: 0;
}

.btn-light.active, .btn-light:active,
.btn-default.active,
.btn-default:active {
  color: #717171;
  background-color: #FFF;
  border-color: #e2e2e2;
}

.btn-light.active:hover, .btn-light.active:focus, .btn-light.active.focus, .btn-light:active:hover, .btn-light:active:focus, .btn-light:active.focus,
.btn-default.active:hover,
.btn-default.active:focus,
.btn-default.active.focus,
.btn-default:active:hover,
.btn-default:active:focus,
.btn-default:active.focus {
  color: #717171;
  background-color: #FFF;
  border-color: #e2e2e2;
}

.btn-light.disabled:hover, .btn-light.disabled:focus, .btn-light.disabled.focus, .btn-light[disabled]:hover, .btn-light[disabled]:focus, .btn-light[disabled].focus,
fieldset[disabled] .btn-light:hover,
fieldset[disabled] .btn-light:focus,
fieldset[disabled] .btn-light.focus,
.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled.focus,
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled].focus,
fieldset[disabled]
.btn-default:hover,
fieldset[disabled]
.btn-default:focus,
fieldset[disabled]
.btn-default.focus {
  background-color: #FFF;
  border-color: #e2e2e2;
}

.btn-light.active, .btn-light:active:focus, .btn-light:focus, .btn-light:hover,
.show > .btn-light.dropdown-toggle,
.show > .btn-light.dropdown-toggle:hover,
.show > .btn-light.dropdown-toggle:focus,
.btn-default.active,
.btn-default:active:focus,
.btn-default:focus,
.btn-default:hover,
.show >
.btn-default.dropdown-toggle,
.show >
.btn-default.dropdown-toggle:hover,
.show >
.btn-default.dropdown-toggle:focus {
  color: #717171;
  border-color: #d8d8d8;
  background-color: #fafafa;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.btn-outline-primary {
  color: #2575dc;
  background-color: transparent;
  border-color: #8ab5ec;
}

.btn-outline-primary.active, .btn-outline-primary:active:focus, .btn-outline-primary:focus, .btn-outline-primary:hover,
.open > .btn-outline-primary.dropdown-toggle,
.open > .btn-outline-primary.dropdown-toggle:hover,
.open > .btn-outline-primary.dropdown-toggle:focus {
  color: #fff;
  background-color: #2575dc;
  border-color: #2575dc;
}

.btn-outline-info {
  color: #52BAFF;
  background-color: transparent;
  border-color: #85ceff;
}

.btn-outline-info.active, .btn-outline-info:active:focus, .btn-outline-info:focus, .btn-outline-info:hover,
.open > .btn-outline-info.dropdown-toggle,
.open > .btn-outline-info.dropdown-toggle:hover,
.open > .btn-outline-info.dropdown-toggle:focus {
  color: #fff;
  background-color: #52BAFF;
  border-color: #52BAFF;
}

.btn-outline-danger {
  color: #e9594a;
  background-color: transparent;
  border-color: #f6b9b3;
}

.btn-outline-danger.active, .btn-outline-danger:active:focus, .btn-outline-danger:focus, .btn-outline-danger:hover,
.open > .btn-outline-danger.dropdown-toggle,
.open > .btn-outline-danger.dropdown-toggle:hover,
.open > .btn-outline-danger.dropdown-toggle:focus {
  color: #fff;
  background-color: #e9594a;
  border-color: #e9594a;
}

.btn-outline-warning {
  color: #FFA14F;
  background-color: transparent;
  border-color: #ffbc82;
}

.btn-outline-warning.active, .btn-outline-warning:active:focus, .btn-outline-warning:focus, .btn-outline-warning:hover,
.open > .btn-outline-warning.dropdown-toggle,
.open > .btn-outline-warning.dropdown-toggle:hover,
.open > .btn-outline-warning.dropdown-toggle:focus {
  color: #fff;
  background-color: #FFA14F;
  border-color: #FFA14F;
}

.btn-outline-success {
  color: #6ab961;
  background-color: transparent;
  border-color: #8bc984;
}

.btn-outline-success.active, .btn-outline-success:active:focus, .btn-outline-success:focus, .btn-outline-success:hover,
.open > .btn-outline-success.dropdown-toggle,
.open > .btn-outline-success.dropdown-toggle:hover,
.open > .btn-outline-success.dropdown-toggle:focus {
  color: #fff;
  background-color: #6ab961;
  border-color: #6ab961;
}

.btn-outline-dark {
  color: #3f464f;
  background-color: transparent;
  border-color: #3f464f;
}

.btn-outline-dark.active, .btn-outline-dark:active:focus, .btn-outline-dark:focus, .btn-outline-dark:hover,
.open > .btn-outline-dark.dropdown-toggle,
.open > .btn-outline-dark.dropdown-toggle:hover,
.open > .btn-outline-dark.dropdown-toggle:focus {
  color: #fff;
  background-color: #3f464f;
  border-color: #3f464f;
}

.btn-outline-default {
  color: #FFF;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.2);
}

.btn-outline-default:hover, .btn-outline-default:focus {
  color: #FFF;
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.2);
}

.btn-facebook {
  color: #fff;
  background-color: #3b5999;
  font-weight: 500;
}

.btn-facebook:hover, .btn-facebook:focus, .btn-facebook:active {
  color: #fff;
  background: #4264ab;
}

.btn-facebook.btn-link:focus, .btn-facebook.btn-link:hover {
  color: #3b5999;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-facebook.btn-default:focus, .btn-facebook.btn-default:hover {
  background-color: #3b5999;
  border-color: #3b5999;
}

.btn-twitter {
  color: #fff;
  background-color: #55acee;
  font-weight: 500;
}

.btn-twitter:hover, .btn-twitter:focus, .btn-twitter:active {
  color: #fff;
  background: #6cb7f0;
}

.btn-twitter.btn-link:focus, .btn-twitter.btn-link:hover {
  color: #55acee;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-twitter.btn-default:focus, .btn-twitter.btn-default:hover {
  background-color: #55acee;
  border-color: #55acee;
}

.btn-google-plus {
  color: #fff;
  background-color: #dd4b39;
  font-weight: 500;
}

.btn-google-plus:hover, .btn-google-plus:focus, .btn-google-plus:active {
  color: #fff;
  background: #e15f4f;
}

.btn-google-plus.btn-link:focus, .btn-google-plus.btn-link:hover {
  color: #dd4b39;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-google-plus.btn-default:focus, .btn-google-plus.btn-default:hover {
  background-color: #dd4b39;
  border-color: #dd4b39;
}

.btn-twitch {
  color: #fff;
  background-color: #6441a5;
  font-weight: 500;
}

.btn-twitch:hover, .btn-twitch:focus, .btn-twitch:active {
  color: #fff;
  background: #6f48b7;
}

.btn-twitch.btn-link:focus, .btn-twitch.btn-link:hover {
  color: #6441a5;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-twitch.btn-default:focus, .btn-twitch.btn-default:hover {
  background-color: #6441a5;
  border-color: #6441a5;
}

.btn-steam {
  color: #fff;
  background-color: #111;
  font-weight: 500;
}

.btn-steam:hover, .btn-steam:focus, .btn-steam:active {
  color: #fff;
  background: #1e1e1e;
}

.btn-steam.btn-link:focus, .btn-steam.btn-link:hover {
  color: #111;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-steam.btn-default:focus, .btn-steam.btn-default:hover {
  background-color: #111;
  border-color: #111;
}

.btn-linkedin {
  color: #fff;
  background-color: #0077B5;
  font-weight: 500;
}

.btn-linkedin:hover, .btn-linkedin:focus, .btn-linkedin:active {
  color: #fff;
  background: #0088cf;
}

.btn-linkedin.btn-link:focus, .btn-linkedin.btn-link:hover {
  color: #0077B5;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-linkedin.btn-default:focus, .btn-linkedin.btn-default:hover {
  background-color: #0077B5;
  border-color: #0077B5;
}

.btn-skype {
  color: #fff;
  background-color: #00AFF0;
  font-weight: 500;
}

.btn-skype:hover, .btn-skype:focus, .btn-skype:active {
  color: #fff;
  background: #0bbdff;
}

.btn-skype.btn-link:focus, .btn-skype.btn-link:hover {
  color: #00AFF0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-skype.btn-default:focus, .btn-skype.btn-default:hover {
  background-color: #00AFF0;
  border-color: #00AFF0;
}

.btn-vk {
  color: #fff;
  background-color: #4c75a3;
  font-weight: 500;
}

.btn-vk:hover, .btn-vk:focus, .btn-vk:active {
  color: #fff;
  background: #5882b1;
}

.btn-vk.btn-link:focus, .btn-vk.btn-link:hover {
  color: #4c75a3;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-vk.btn-default:focus, .btn-vk.btn-default:hover {
  background-color: #4c75a3;
  border-color: #4c75a3;
}

.btn-vimeo {
  color: #fff;
  background-color: #1ab7ea;
  font-weight: 500;
}

.btn-vimeo:hover, .btn-vimeo:focus, .btn-vimeo:active {
  color: #fff;
  background: #31beec;
}

.btn-vimeo.btn-link:focus, .btn-vimeo.btn-link:hover {
  color: #1ab7ea;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-vimeo.btn-default:focus, .btn-vimeo.btn-default:hover {
  background-color: #1ab7ea;
  border-color: #1ab7ea;
}

.btn-youtube {
  color: #fff;
  background-color: #cd201f;
  font-weight: 500;
}

.btn-youtube:hover, .btn-youtube:focus, .btn-youtube:active {
  color: #fff;
  background: #de2827;
}

.btn-youtube.btn-link:focus, .btn-youtube.btn-link:hover {
  color: #cd201f;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-youtube.btn-default:focus, .btn-youtube.btn-default:hover {
  background-color: #cd201f;
  border-color: #cd201f;
}

.btn-reddit {
  color: #fff;
  background-color: #ff5700;
  font-weight: 500;
}

.btn-reddit:hover, .btn-reddit:focus, .btn-reddit:active {
  color: #fff;
  background: #ff681a;
}

.btn-reddit.btn-link:focus, .btn-reddit.btn-link:hover {
  color: #ff5700;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-reddit.btn-default:focus, .btn-reddit.btn-default:hover {
  background-color: #ff5700;
  border-color: #ff5700;
}

.btn-pinterest {
  color: #fff;
  background-color: #bd081c;
  font-weight: 500;
}

.btn-pinterest:hover, .btn-pinterest:focus, .btn-pinterest:active {
  color: #fff;
  background: #d50920;
}

.btn-pinterest.btn-link:focus, .btn-pinterest.btn-link:hover {
  color: #bd081c;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-pinterest.btn-default:focus, .btn-pinterest.btn-default:hover {
  background-color: #bd081c;
  border-color: #bd081c;
}

.btn-instagram {
  color: #fff;
  background-color: #3F729B;
  font-weight: 500;
}

.btn-instagram:hover, .btn-instagram:focus, .btn-instagram:active {
  color: #fff;
  background: #467fad;
}

.btn-instagram.btn-link:focus, .btn-instagram.btn-link:hover {
  color: #3F729B;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-instagram.btn-default:focus, .btn-instagram.btn-default:hover {
  background-color: #3F729B;
  border-color: #3F729B;
}

.btn-dribbble {
  color: #fff;
  background-color: #ea4c89;
  font-weight: 500;
}

.btn-dribbble:hover, .btn-dribbble:focus, .btn-dribbble:active {
  color: #fff;
  background: #ed6398;
}

.btn-dribbble.btn-link:focus, .btn-dribbble.btn-link:hover {
  color: #ea4c89;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-dribbble.btn-default:focus, .btn-dribbble.btn-default:hover {
  background-color: #ea4c89;
  border-color: #ea4c89;
}

.btn-paypal {
  color: #fff;
  background-color: #00588B;
  font-weight: 500;
}

.btn-paypal:hover, .btn-paypal:focus, .btn-paypal:active {
  color: #fff;
  background: #0068a5;
}

.btn-paypal.btn-link:focus, .btn-paypal.btn-link:hover {
  color: #00588B;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-paypal.btn-default:focus, .btn-paypal.btn-default:hover {
  background-color: #00588B;
  border-color: #00588B;
}

.btn-rss {
  color: #fff;
  background-color: #f26522;
  font-weight: 500;
}

.btn-rss:hover, .btn-rss:focus, .btn-rss:active {
  color: #fff;
  background: #f3763a;
}

.btn-rss.btn-link:focus, .btn-rss.btn-link:hover {
  color: #f26522;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-rss.btn-default:focus, .btn-rss.btn-default:hover {
  background-color: #f26522;
  border-color: #f26522;
}

.btn-wordpress {
  color: #fff;
  background-color: #0087be;
  font-weight: 500;
}

.btn-wordpress:hover, .btn-wordpress:focus, .btn-wordpress:active {
  color: #fff;
  background: #0099d8;
}

.btn-wordpress.btn-link:focus, .btn-wordpress.btn-link:hover {
  color: #0087be;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-wordpress.btn-default:focus, .btn-wordpress.btn-default:hover {
  background-color: #0087be;
  border-color: #0087be;
}

.btn-drupal {
  color: #fff;
  background-color: #0077c0;
  font-weight: 500;
}

.btn-drupal:hover, .btn-drupal:focus, .btn-drupal:active {
  color: #fff;
  background: #0087da;
}

.btn-drupal.btn-link:focus, .btn-drupal.btn-link:hover {
  color: #0077c0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-drupal.btn-default:focus, .btn-drupal.btn-default:hover {
  background-color: #0077c0;
  border-color: #0077c0;
}

.btn-joomla {
  color: #fff;
  background-color: #5091cd;
  font-weight: 500;
}

.btn-joomla:hover, .btn-joomla:focus, .btn-joomla:active {
  color: #fff;
  background: #649dd3;
}

.btn-joomla.btn-link:focus, .btn-joomla.btn-link:hover {
  color: #5091cd;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-joomla.btn-default:focus, .btn-joomla.btn-default:hover {
  background-color: #5091cd;
  border-color: #5091cd;
}

.btn-tumblr {
  color: #fff;
  background-color: #34526F;
  font-weight: 500;
}

.btn-tumblr:hover, .btn-tumblr:focus, .btn-tumblr:active {
  color: #fff;
  background: #3c5f80;
}

.btn-tumblr.btn-link:focus, .btn-tumblr.btn-link:hover {
  color: #34526F;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-tumblr.btn-default:focus, .btn-tumblr.btn-default:hover {
  background-color: #34526F;
  border-color: #34526F;
}

.btn-github {
  color: #fff;
  background-color: #333;
  font-weight: 500;
}

.btn-github:hover, .btn-github:focus, .btn-github:active {
  color: #fff;
  background: #404040;
}

.btn-github.btn-link:focus, .btn-github.btn-link:hover {
  color: #333;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-github.btn-default:focus, .btn-github.btn-default:hover {
  background-color: #333;
  border-color: #333;
}

.btn-soundcloud {
  color: #fff;
  background-color: #ff5500;
  font-weight: 500;
}

.btn-soundcloud:hover, .btn-soundcloud:focus, .btn-soundcloud:active {
  color: #fff;
  background: #ff661a;
}

.btn-soundcloud.btn-link:focus, .btn-soundcloud.btn-link:hover {
  color: #ff5500;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-soundcloud.btn-default:focus, .btn-soundcloud.btn-default:hover {
  background-color: #ff5500;
  border-color: #ff5500;
}

.btn-spotify {
  color: #fff;
  background-color: #1db954;
  font-weight: 500;
}

.btn-spotify:hover, .btn-spotify:focus, .btn-spotify:active {
  color: #fff;
  background: #20cf5e;
}

.btn-spotify.btn-link:focus, .btn-spotify.btn-link:hover {
  color: #1db954;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-spotify.btn-default:focus, .btn-spotify.btn-default:hover {
  background-color: #1db954;
  border-color: #1db954;
}

.btn-slack {
  color: #fff;
  background-color: #e01563;
  font-weight: 500;
}

.btn-slack:hover, .btn-slack:focus, .btn-slack:active {
  color: #fff;
  background: #ea2470;
}

.btn-slack.btn-link:focus, .btn-slack.btn-link:hover {
  color: #e01563;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-slack.btn-default:focus, .btn-slack.btn-default:hover {
  background-color: #e01563;
  border-color: #e01563;
}

.btn-windows {
  color: #fff;
  background-color: #00bcf2;
  font-weight: 500;
}

.btn-windows:hover, .btn-windows:focus, .btn-windows:active {
  color: #fff;
  background: #0dc9ff;
}

.btn-windows.btn-link:focus, .btn-windows.btn-link:hover {
  color: #00bcf2;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-windows.btn-default:focus, .btn-windows.btn-default:hover {
  background-color: #00bcf2;
  border-color: #00bcf2;
}

.btn-linux {
  color: #fff;
  background-color: #1793d1;
  font-weight: 500;
}

.btn-linux:hover, .btn-linux:focus, .btn-linux:active {
  color: #fff;
  background: #1ca2e6;
}

.btn-linux.btn-link:focus, .btn-linux.btn-link:hover {
  color: #1793d1;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-linux.btn-default:focus, .btn-linux.btn-default:hover {
  background-color: #1793d1;
  border-color: #1793d1;
}

.dropdown-menu {
  border-color: rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
}

.dropdown-menu .dropdown-item {
  font-size: 13px;
  padding: 7px 17px;
  color: #5a5a5a;
}

.dropdown-menu .dropdown-item i {
  min-width: 22px;
}

.dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item:hover {
  color: rgba(0, 0, 0, 0.7);
  background-color: #f5f5f5;
}

.dropdown-menu .active,
.dropdown-menu .active:focus,
.dropdown-menu .active:hover {
  color: #fff;
  background-color: #2575dc;
}

.dropcap {
  float: left;
  font-size: 30px;
  line-height: 32px;
  font-weight: bold;
  margin: 0 8px 0 0;
}

.dropdown-toggle::after {
  float: right;
  margin-top: 7px;
  margin-left: 5px;
}

.btn-group .dropdown-toggle:not(:first-child)::after {
  margin-top: 0px;
  margin-right: -4px;
}

.dropdown-notification .dropdown-header {
  background-color: #fafafa;
  border-bottom: 1px solid #efefef;
  padding: 12px 15px;
  font-weight: 700;
  font-size: 14px;
  color: #545454;
  margin-top: -8px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.dropdown-notification .dropdown-header i {
  margin-right: 5px;
}

.dropdown-notification > .dropdown-menu {
  min-width: 240px !important;
}

.dropdown-notification > .dropdown-menu .badge {
  padding: 5px 2px;
  margin-right: 7px;
}

.dropdown-notification > .dropdown-menu .date {
  float: right;
  background-color: #f5f5f5;
  padding: 4px 7px;
  font-size: 11px;
  margin-top: -1px;
  color: #8c8c8c;
  font-weight: 500;
}

.dropdown-notification > .dropdown-menu a {
  border-bottom: 1px solid #f3f3f3;
  padding: 11px 13px;
}

.dropdown-notification > .dropdown-menu .dropdown-block {
  max-height: 200px;
  overflow-y: scroll;
}

.form-control {
  border-color: #e2e2e2;
  font-size: 15px;
  padding: 0 15px;
  color: #666;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-weight: 300;
  height: 40px;
  line-height: 40px;
}

.form-control:focus {
  background-color: #fff;
  border-color: #93bbee;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.form-control::-webkit-input-placeholder {
  color: #878787;
}

.form-control + .form-control {
  margin-top: 20px;
}

.form-control[readonly] {
  background-color: #eee;
}

.form-control-secondary {
  background-color: #fafafa;
}

.form-control-secondary:focus {
  background-color: #fff;
}

select.form-control {
  line-height: inherit;
  height: auto;
}

textarea.form-control {
  height: auto;
  line-height: inherit;
  padding: 10px 15px;
}

.form-group {
  margin-bottom: 25px;
}

.form-text {
  font-size: 13px;
  margin-top: 8px;
  font-weight: 400;
  display: block;
  color: #8a8a8a;
}

.form-control-lg {
  height: 46px;
  line-height: 46px;
  font-size: 15px;
  border-radius: 4px;
}

.form-control-sm {
  height: 36px;
  line-height: 36px;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #eee;
}

input:focus {
  outline: 0 !important;
}

legend {
  border: 0;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
  color: #5a5a5a;
}

label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
  color: #5a5a5a;
  white-space: nowrap;
}

.col-form-label {
  padding-top: 10px;
  padding-bottom: 0;
}

.input-icon-right {
  position: relative;
}

.input-icon-right .form-control {
  padding-right: 35px;
}

.input-icon-right i {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 43px;
  line-height: 39px;
  text-align: center;
  height: 39px;
  font-size: 15px;
}

.input-icon-left {
  position: relative;
}

.input-icon-left .form-control {
  padding-left: 40px;
}

.input-icon-left i {
  position: absolute;
  left: 0px;
  top: 1px;
  width: 43px;
  line-height: 39px;
  text-align: center;
  height: 39px;
  font-size: 15px;
}

.input-group-addon {
  border-color: rgba(0, 0, 0, 0.1);
  color: #75797b;
  font-size: 14px;
  background-color: #eee;
}

.form-check .form-check-input {
  position: absolute;
}

.form-check label {
  font-weight: 300;
  color: #6f6f6f;
}

.form-control-file {
  font-weight: 300;
}

input[type="file"] {
  font-size: 14px;
}

.has-success .form-control,
.has-warning .form-control,
.has-danger .form-control {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.has-success .form-control:focus,
.has-warning .form-control:focus,
.has-danger .form-control:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.has-success .form-control {
  color: #499342;
  border-color: #7fc378;
}

.has-success .form-control:focus {
  border-color: #52a549;
}

.has-success small,
.has-success .control-label {
  color: #499342;
}

.has-warning .form-control {
  color: #ff8b24;
  border-color: #ffc18a;
}

.has-warning .form-control:focus {
  border-color: #ff983e;
}

.has-warning small,
.has-warning .control-label {
  color: #ff8b24;
}

.has-danger .form-control {
  color: #ff4a38;
  border-color: #ffa79e;
}

.has-danger .form-control:focus {
  border-color: #ff6252;
}

.has-danger small,
.has-danger .control-label {
  color: #ff4a38;
}

.divider {
  margin: 18px 0;
  z-index: 1;
  text-align: center;
  position: relative;
}

.divider span {
  font-size: 14px;
  color: #7b7b7b;
  font-weight: normal;
  background-color: #FAFAFA;
  display: inline-block;
  padding-left: 12px;
  padding-right: 12px;
}

.divider span:before {
  content: "";
  width: 100%;
  height: 1px;
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 0;
  background-color: #ececec;
}

.input-group-addon {
  border-color: #e2e2e2;
}

.checkbox.form-inline label input {
  float: left;
  margin-right: 5px;
}

.checkbox.form-inline + .checkbox {
  margin-left: 10px;
}

.checkbox + .btn {
  margin-top: 10px;
}

.checkbox label {
  font-size: 14px;
  font-weight: 300;
}

.custom-control {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-height: 20px;
  cursor: pointer;
  color: #7d7d7d;
  margin: 0;
}

.custom-control + .custom-control {
  margin-top: -10px;
}

.custom-control-input {
  position: absolute;
  z-index: 2;
  width: 20px;
  height: 20px;
  opacity: 0;
  cursor: pointer;
}

.custom-control-input:checked ~ .custom-control-indicator {
  color: #fff;
  background-color: #0275d8;
}

.custom-control-input:focus ~ .custom-control-indicator {
  -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 3px #0275d8;
          box-shadow: 0 0 0 1px #fff, 0 0 0 3px #0275d8;
}

.custom-control-input:active ~ .custom-control-indicator {
  color: #fff;
  background-color: #8fcafe;
}

.custom-control-input:disabled ~ .custom-control-indicator {
  cursor: not-allowed;
  background-color: #eceeef;
}

.custom-control-input:disabled ~ .custom-control-description {
  color: #636c72;
  cursor: not-allowed;
}

.custom-control-description {
  font-size: 14px;
  font-weight: 300;
  margin-left: 4px;
  margin-bottom: 10px;
}

.custom-control-description a {
  text-decoration: underline;
}

.custom-control-indicator {
  position: absolute;
  background-color: #fff;
  border: 1px solid #dadada;
  top: 0px;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-indicator {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23333' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23333' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox-primary .custom-control-input:checked ~ .custom-control-indicator,
.custom-checkbox-success .custom-control-input:checked ~ .custom-control-indicator,
.custom-checkbox-danger .custom-control-input:checked ~ .custom-control-indicator,
.custom-checkbox-info .custom-control-input:checked ~ .custom-control-indicator,
.custom-checkbox-warning .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox-primary .custom-control-input:indeterminate ~ .custom-control-indicator,
.custom-checkbox-success .custom-control-input:indeterminate ~ .custom-control-indicator,
.custom-checkbox-danger .custom-control-input:indeterminate ~ .custom-control-indicator,
.custom-checkbox-info .custom-control-input:indeterminate ~ .custom-control-indicator,
.custom-checkbox-warning .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  border-color: #dadada;
  background-color: #fff;
  color: #000;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-color: #fff;
}

.custom-checkbox .custom-control-input:active ~ .custom-control-indicator,
.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-color: #fff;
  border-color: #dadada;
  color: #000;
}

.custom-checkbox-primary .custom-control-input:checked ~ .custom-control-indicator {
  border-color: #2575dc;
  background-color: #2575dc;
}

.custom-checkbox-primary .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-color: #2575dc;
}

.custom-checkbox-primary .custom-control-input:active ~ .custom-control-indicator,
.custom-checkbox-primary .custom-control-input:checked ~ .custom-control-indicator {
  background-color: #2575dc;
  border-color: #2575dc;
}

.custom-checkbox-danger .custom-control-input:checked ~ .custom-control-indicator {
  border-color: #e9594a;
  background-color: #e9594a;
}

.custom-checkbox-danger .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-color: #e9594a;
}

.custom-checkbox-danger .custom-control-input:active ~ .custom-control-indicator,
.custom-checkbox-danger .custom-control-input:checked ~ .custom-control-indicator {
  background-color: #e9594a;
  border-color: #e9594a;
}

.custom-checkbox-info .custom-control-input:checked ~ .custom-control-indicator {
  border-color: #52BAFF;
  background-color: #52BAFF;
}

.custom-checkbox-info .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-color: #52BAFF;
}

.custom-checkbox-info .custom-control-input:active ~ .custom-control-indicator,
.custom-checkbox-info .custom-control-input:checked ~ .custom-control-indicator {
  background-color: #52BAFF;
  border-color: #52BAFF;
}

.custom-checkbox-warning .custom-control-input:checked ~ .custom-control-indicator {
  border-color: #FFA14F;
  background-color: #FFA14F;
}

.custom-checkbox-warning .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-color: #FFA14F;
}

.custom-checkbox-warning .custom-control-input:active ~ .custom-control-indicator,
.custom-checkbox-warning .custom-control-input:checked ~ .custom-control-indicator {
  background-color: #FFA14F;
  border-color: #FFA14F;
}

.custom-checkbox-success .custom-control-input:checked ~ .custom-control-indicator {
  border-color: #6ab961;
  background-color: #6ab961;
}

.custom-checkbox-success .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-color: #6ab961;
}

.custom-checkbox-success .custom-control-input:active ~ .custom-control-indicator,
.custom-checkbox-success .custom-control-input:checked ~ .custom-control-indicator {
  background-color: #6ab961;
  border-color: #6ab961;
}

.custom-radio .custom-control-indicator {
  border-radius: 50%;
  border: 0;
  -webkit-box-shadow: 0 0 0 1px #dadada;
          box-shadow: 0 0 0 1px #dadada;
}

.custom-radio .custom-control-input:active ~ .custom-control-indicator,
.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23333'/%3E%3C/svg%3E");
  -webkit-box-shadow: 0 0 0 1px #dadada;
          box-shadow: 0 0 0 1px #dadada;
}

.custom-radio-primary .custom-control-input:active ~ .custom-control-indicator,
.custom-radio-primary .custom-control-input:checked ~ .custom-control-indicator,
.custom-radio-success .custom-control-input:active ~ .custom-control-indicator,
.custom-radio-success .custom-control-input:checked ~ .custom-control-indicator,
.custom-radio-warning .custom-control-input:active ~ .custom-control-indicator,
.custom-radio-warning .custom-control-input:checked ~ .custom-control-indicator,
.custom-radio-danger .custom-control-input:active ~ .custom-control-indicator,
.custom-radio-danger .custom-control-input:checked ~ .custom-control-indicator,
.custom-radio-info .custom-control-input:active ~ .custom-control-indicator,
.custom-radio-info .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
  -webkit-box-shadow: none;
          box-shadow: none;
}

.custom-radio-primary .custom-control-input:active ~ .custom-control-indicator,
.custom-radio-primary .custom-control-input:checked ~ .custom-control-indicator {
  border-color: #2575dc;
  background-color: #2575dc;
}

.custom-radio-success .custom-control-input:active ~ .custom-control-indicator,
.custom-radio-success .custom-control-input:checked ~ .custom-control-indicator {
  border-color: #6ab961;
  background-color: #6ab961;
}

.custom-radio-warning .custom-control-input:active ~ .custom-control-indicator,
.custom-radio-warning .custom-control-input:checked ~ .custom-control-indicator {
  border-color: #FFA14F;
  background-color: #FFA14F;
}

.custom-radio-danger .custom-control-input:active ~ .custom-control-indicator,
.custom-radio-danger .custom-control-input:checked ~ .custom-control-indicator {
  border-color: #e9594a;
  background-color: #e9594a;
}

.custom-radio-info .custom-control-input:active ~ .custom-control-indicator,
.custom-radio-info .custom-control-input:checked ~ .custom-control-indicator {
  border-color: #52BAFF;
  background-color: #52BAFF;
}

.custom-control-input:focus ~ .custom-control-indicator {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.checkbox-toggle {
  display: inline-block;
}

.checkbox-toggle label {
  padding-left: 36px;
  display: inline-block;
  position: relative;
  float: left;
  height: 20px;
}

.checkbox-toggle label:before {
  content: "\f204";
  position: absolute;
  left: -2px;
  top: -8px;
  font-size: 26px;
  font-family: 'FontAwesome';
}

.checkbox-toggle input {
  display: none;
}

.checkbox-toggle input[type=checkbox]:disabled + label:before {
  content: "\f205";
  color: #AFAFAF;
}

.checkbox-toggle input[type=checkbox]:checked + label:before {
  content: "\f205";
}

.checkbox-toggle .disabled input[type=checkbox]:disabled + label:before {
  background-color: transparent;
  border-color: #DADADA;
}

.checkbox-toggle.checkbox-primary input[type=checkbox]:checked + label:before {
  color: #2575dc;
}

.checkbox-toggle.checkbox-danger input[type=checkbox]:checked + label:before {
  color: #e9594a;
}

.checkbox-toggle.checkbox-warning input[type=checkbox]:checked + label:before {
  color: #FFA14F;
}

.checkbox-toggle.checkbox-success input[type=checkbox]:checked + label:before {
  color: #6ab961;
}

.checkbox-toggle.checkbox-info input[type=checkbox]:checked + label:before {
  color: #52BAFF;
}

.alert {
  font-size: 14px;
  padding: 15px;
  margin-bottom: 20px;
}

.alert b, .alert strong {
  color: inherit;
}

.alert-success {
  color: #5B865B;
  background-color: #F0F9ED;
  border-color: #D6E6D0;
}

.alert-success b, .alert-success strong {
  color: #517751;
}

.alert-info {
  color: #5086A0;
  background-color: #effaff;
  border-color: #b8e9ff;
}

.alert-info b, .alert-info strong {
  color: #48788f;
}

.alert-warning {
  color: #968C53;
  background-color: #FDFBEF;
  border-color: #ECE8CE;
}

.alert-warning b, .alert-warning strong {
  color: #867d4a;
}

.alert-danger {
  color: #986F68;
  background-color: #FDEDEA;
  border-color: #f1d6d1;
}

.alert-danger b, .alert-danger strong {
  color: #89645d;
}

.notification {
  position: fixed;
  padding: 13px 15px;
  font-size: 15px;
  top: 75px;
  display: inline-block;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  left: 0;
  z-index: 9;
  text-align: center;
}

.notification .alert {
  display: inline-block;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
  text-transform: none;
  font-size: 15px;
}

.notification-left {
  text-align: left;
}

.notification-right {
  text-align: right;
}

.notification-bottom-left {
  text-align: left;
  top: auto;
  bottom: 0px;
}

.notification-bottom-right {
  text-align: right;
  top: auto;
  bottom: 0px;
}

.notification-bottom {
  top: auto;
  bottom: 0px;
}

.progress {
  background-color: #ebedef;
  border-radius: 30px;
  height: 6px;
  -webkit-box-shadow: none;
          box-shadow: none;
  margin-bottom: 25px;
}

.progress-bar {
  background-color: #2575dc;
  line-height: 15px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 11px;
  font-weight: bold;
  color: #FFF;
}

.progress-lg {
  height: 12px;
}

.progress-sm {
  height: 8px;
}

.progress-bar-success {
  background-color: #6ab961;
}

.progress-bar-warning {
  background-color: #FFA14F;
}

.progress-bar-danger {
  background-color: #e9594a;
}

.progress-bar-info {
  background-color: #52BAFF;
}

.pagination-results {
  margin-top: 20px;
}

.pagination-results::after {
  display: block;
  content: "";
  clear: both;
}

.pagination-results > span {
  float: left;
  display: inline-block;
  margin-right: 15px;
  font-size: 14px;
  padding: 7px 12px;
  background-color: #FFF;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}

.pagination-results nav {
  display: inline-block;
  float: right;
}

.pagination-results nav ul {
  margin: 0;
}

.pagination {
  margin-top: 30px;
  margin-bottom: 0;
}

.pagination > li > span {
  display: inline-block;
}

.pagination > li > span,
.pagination > li > a {
  font-size: 14px;
  padding: 8px 13px;
  color: #808080;
  background-color: #fff;
  border-color: #e4e4e4;
  font-weight: 300;
  line-height: 1.25;
}

.pagination > li > span:hover,
.pagination > li > a:hover {
  color: #808080;
  background-color: #f5f5f5;
}

.pagination > li.separate span {
  background-color: transparent;
  border-color: transparent;
  padding-top: 10px;
}

.pagination .active > a {
  background-color: #2575dc !important;
  border-color: #2575dc !important;
}

.pagination .active > a:hover {
  background-color: #2575dc !important;
}

.pager li > a {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.01);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.01);
  padding: 10px 16px;
  border-radius: 30px;
  margin-right: 5px;
}

.pager li > a:focus, .pager li > a:hover {
  color: inherit;
  background-color: #fafafa;
}

.modal-open {
  padding-right: 0 !important;
  overflow: inherit;
}

.modal-open .modal {
  overflow: hidden;
}

.modal-header {
  background-color: #fafafa;
  border-top-right-radius: .3rem;
  border-top-left-radius: .3rem;
}

.modal-header,
.modal-footer {
  padding: 15px 20px;
  border-color: #eaeaea;
}

.modal-content {
  width: 100%;
  border-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
}

.modal {
  text-align: center;
  padding: 0 !important;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  min-width: 500px;
}

.modal-body {
  padding: 20px;
}

.modal-body p:last-child {
  margin-bottom: 0;
}

.modal-body .divider span {
  background-color: #fff;
}

.modal-title {
  color: #585858;
  font-weight: 600;
  font-size: 16px;
}

.modal-title i {
  margin-right: 5px;
}

.modal-top:before {
  display: none;
}

.modal-bottom:before {
  vertical-align: bottom;
}

.modal-sm {
  max-width: 350px;
  min-width: 350px;
}

.modal-xs {
  max-width: 300px;
  min-width: 300px;
}

.modal-backdrop {
  background-color: #1b1b1b;
}

.modal-backdrop.show {
  opacity: .8;
}

.modal-primary .modal-header,
.modal-danger .modal-header,
.modal-success .modal-header,
.modal-danger .modal-header,
.modal-info .modal-header,
.modal-warning .modal-header {
  border: 0;
}

.modal-primary .modal-title,
.modal-danger .modal-title,
.modal-success .modal-title,
.modal-danger .modal-title,
.modal-info .modal-title,
.modal-warning .modal-title {
  color: #fff;
  font-weight: 500;
  font-size: 15px;
}

.modal-primary .modal-content,
.modal-danger .modal-content,
.modal-success .modal-content,
.modal-danger .modal-content,
.modal-info .modal-content,
.modal-warning .modal-content {
  border-color: rgba(0, 0, 0, 0.08);
}

.modal-primary .modal-header {
  background-color: #2575dc;
}

.modal-success .modal-header {
  background-color: #6ab961;
}

.modal-danger .modal-header {
  background-color: #e9594a;
}

.modal-warning .modal-header {
  background-color: #FFA14F;
}

.modal-info .modal-header {
  background-color: #52BAFF;
}

.lightbox {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1090;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.lightbox .lightbox-close {
  position: absolute;
  top: 20px;
  right: 30px;
  display: inline-block;
  font-size: 20px;
}

.lightbox .lightbox-close:focus, .lightbox .lightbox-close:hover {
  color: #fff;
}

.lightbox .lightbox-overlay {
  position: absolute;
  background-color: rgba(37, 37, 37, 0.93);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.lightbox .lightbox-block {
  position: relative;
  display: table;
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
}

.lightbox .lightbox-block .lightbox-img {
  display: table-cell;
  vertical-align: top;
  position: relative;
  text-align: center;
}

.lightbox .lightbox-block .lightbox-img img {
  width: auto;
  max-width: 100%;
  max-height: 800px;
}

.lightbox .lightbox-block .lightbox-img:hover .lightbox-prev,
.lightbox .lightbox-block .lightbox-img:hover .lightbox-next {
  opacity: 1;
}

.lightbox .lightbox-block .lightbox-comment {
  width: 400px;
  height: 100%;
  padding: 10px 25px;
  display: table-cell;
  background-color: #fff;
  overflow: hidden !important;
}

.lightbox .lightbox-block .lightbox-comment .fb-comments {
  display: block !important;
}

.lightbox .lightbox-block .lightbox-comment .fb-comments span {
  display: inline-block;
  max-height: 800px;
  margin-right: -50px;
  overflow-y: scroll !important;
}

.lightbox .lightbox-block .lightbox-comment .fb-comments span iframe {
  position: static !important;
}

.lightbox .lightbox-block .lightbox-prev,
.lightbox .lightbox-block .lightbox-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 13%;
  color: #fff;
  text-align: center;
  font-size: 30px;
  opacity: 0;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  cursor: pointer;
}

.lightbox .lightbox-block .lightbox-prev:hover,
.lightbox .lightbox-block .lightbox-next:hover {
  color: #fff;
}

.lightbox .lightbox-block .lightbox-prev {
  left: 0;
}

.lightbox .lightbox-block .lightbox-next {
  right: 0;
}

.lightbox .lightbox-block .lightbox-video {
  width: 1360px;
}

.badge {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 400;
  padding: 8px 12px;
  border-radius: 30px;
  color: #fff;
}

.badge-playstation-4,
.badge-ps4,
.badge-primary {
  background-color: #2575dc;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.badge-playstation-4[href]:active, .badge-playstation-4[href]:focus, .badge-playstation-4[href]:hover,
.badge-ps4[href]:active,
.badge-ps4[href]:focus,
.badge-ps4[href]:hover,
.badge-primary[href]:active,
.badge-primary[href]:focus,
.badge-primary[href]:hover {
  background-color: #327dde;
}

.badge-xbox-one,
.badge-xbox,
.badge-success {
  background-color: #0E9A49;
}

.badge-pc,
.badge-warning {
  color: #fff;
  background-color: #FF8E2B;
}

.badge-uplay,
.badge-info {
  background-color: #52BAFF;
}

.badge-steam,
.badge-danger {
  background-color: #e74c3c;
}

.badge-twitch {
  background-color: #6441a5;
}

.badge-facebook {
  background-color: #3b5999;
}

.badge-twitter {
  background-color: #55acee;
}

.badge-skype {
  background-color: #00AFF0;
}

.badge-vk {
  background-color: #4c75a3;
}

.badge-vimeo {
  background-color: #1ab7ea;
}

.badge-youtube {
  background-color: #cd201f;
}

.badge-google-plus {
  background-color: #dd4b39;
}

.badge-reddit {
  background-color: #ff5700;
}

.badge-pinterest {
  background-color: #bd081c;
}

.badge-instagram {
  background-color: #3F729B;
}

.badge-outline-primary {
  font-weight: 600;
  color: #2575dc;
  border: 1px solid #5191e3;
  border-radius: 3px;
}

.badge-outline-success {
  font-weight: 600;
  color: #6ab961;
  border: 1px solid #8bc984;
  border-radius: 3px;
}

.badge-outline-danger {
  font-weight: 600;
  color: #e9594a;
  border: 1px solid #ef8377;
  border-radius: 3px;
}

.badge-outline-warning {
  font-weight: 600;
  color: #FFA14F;
  border: 1px solid #ffbc82;
  border-radius: 3px;
}

.badge-outline-info {
  font-weight: 600;
  color: #52BAFF;
  border: 1px solid #85ceff;
  border-radius: 3px;
}

.badge-outline-dark {
  font-weight: 600;
  color: #3f464f;
  border: 1px solid #565f6b;
  border-radius: 3px;
}

.carousel {
  background: #222;
}

.carousel .carousel-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: .7;
  z-index: 2;
  background-color: #2d2d2d;
}

.carousel .carousel-caption {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  left: auto;
  top: 0;
  bottom: auto;
  right: auto;
  z-index: 3;
  height: 100%;
  width: 100%;
  padding: 0 15%;
}

.carousel .carousel-caption .carousel-title {
  color: #fff;
  font-size: 35px;
  padding: 15px 20px 0 20px;
  margin: 0;
  display: block;
  font-weight: 600;
  text-shadow: none;
}

.carousel .carousel-caption p {
  background: none;
  padding: 10px 15px;
  margin-top: 0px;
  display: block;
  color: #d3d3d3;
  font-size: 17px;
  font-weight: 400;
}

.carousel-indicators {
  right: 0;
  bottom: 15px;
}

.carousel-indicators li {
  width: 16px;
  height: 16px;
  border: 0;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  margin: 0 5px;
  border-radius: 50%;
  -webkit-box-shadow: none;
          box-shadow: none;
  cursor: pointer;
}

.carousel-indicators li.active {
  border: 0;
  background-color: #FFF;
  width: 16px;
  height: 16px;
  padding: 0;
  margin: 0 5px;
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.carousel-control {
  opacity: .1;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.carousel-control.right,
.carousel-control.left {
  background: none;
}

.carousel.fade {
  background: #222;
  opacity: 1;
}

.carousel.fade .carousel-inner .carousel-item {
  -webkit-transition-property: opacity;
  transition-property: opacity;
}

.carousel.fade .carousel-inner .carousel-item,
.carousel.fade .carousel-inner .active.carousel-item-left,
.carousel.fade .carousel-inner .active.carousel-item-right {
  opacity: 0;
}

.carousel.fade .carousel-inner .active,
.carousel.fade .carousel-inner .carousel-item-next.carousel-item-left,
.carousel.fade .carousel-inner .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.carousel.fade .carousel-inner .carousel-item-next,
.carousel.fade .carousel-inner .carousel-item-prev,
.carousel.fade .carousel-inner .active.carousel-item-left,
.carousel.fade .carousel-inner .active.carousel-item-right {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.carousel-full {
  border-bottom: 0;
  background-color: #222;
}

.carousel-full .item {
  height: calc(100vh - 62px);
}

.carousel-full .item.active .carousel-caption {
  opacity: 1;
}

.carousel-full .item .carousel-img {
  width: 100%;
  height: 100%;
  background: transparent;
  background-size: cover;
}

.carousel-full .carousel-indicators {
  bottom: 30px;
}

.carousel-full img {
  opacity: .4;
  max-width: inherit !important;
}

.carousel-full .carousel-control {
  z-index: 99;
  width: 10%;
}

.ken-burns .carousel-item img {
  -webkit-transition: all 15s;
  transition: all 15s;
}

.ken-burns .carousel-item img,
.ken-burns .carousel-item.active.item-active img {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.ken-burns .carousel-item.active img {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
}

.owl-carousel {
  position: relative;
}

.owl-carousel.owl-grab {
  cursor: pointer;
}

.owl-carousel .card-video {
  margin-bottom: 0;
}

.owl-carousel .owl-dots {
  position: absolute;
  bottom: 45px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  z-index: 3;
}

.owl-carousel .owl-dots .owl-dot {
  width: 16px;
  height: 16px;
  background-color: #fff;
  opacity: .5;
  display: inline-block;
  margin: 0 5px;
  border-radius: 50%;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.owl-carousel .owl-dots .owl-dot.active {
  opacity: 1;
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

.owl-carousel .carousel-item {
  display: block;
}

.owl-list .owl-nav {
  position: absolute;
  z-index: -1;
  top: 50%;
  margin-top: -60px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.owl-list .owl-nav > div {
  font-size: 0;
  opacity: .25;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.owl-list .owl-nav > div:hover {
  opacity: 1;
}

.owl-list .owl-nav > div.owl-prev {
  margin-left: -70px;
}

.owl-list .owl-nav > div.owl-prev:after {
  content: "\f053";
  font-family: "FontAwesome";
  font-size: 36px;
}

.owl-list .owl-nav > div.owl-next {
  margin-left: auto;
  margin-right: -70px;
}

.owl-list .owl-nav > div.owl-next:after {
  content: "\f054";
  font-family: "FontAwesome";
  font-size: 36px;
}

.owl-slide .carousel-item {
  background-position: center;
  background-size: cover;
}

.owl-slide .carousel-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: .7;
  z-index: 2;
  background-color: #2d2d2d;
}

.owl-slide .carousel-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  color: #fff;
  max-width: 800px;
  margin: 0 auto;
}

.owl-slide .carousel-caption .carousel-title {
  font-size: 35px;
  margin-bottom: 0;
  color: #fff;
  font-weight: 600;
}

.owl-slide .carousel-caption p {
  font-size: 17px;
  color: #d3d3d3;
  margin-top: 10px;
  font-weight: 400;
}

.owl-slide .carousel-caption .btn {
  font-size: 15px;
  margin-top: 25px;
  padding: 18px 27px;
}

.owl-slide .owl-nav > div {
  font-size: 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 10%;
  z-index: 3;
  color: #fff;
  opacity: .15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.owl-slide .owl-nav > div:hover {
  opacity: 1;
}

.owl-slide .owl-nav > div.owl-prev:after {
  content: "\f053";
  font-family: "FontAwesome";
  font-size: 36px;
}

.owl-slide .owl-nav > div.owl-next {
  left: auto;
  right: 0px;
}

.owl-slide .owl-nav > div.owl-next:after {
  content: "\f054";
  font-family: "FontAwesome";
  font-size: 36px;
}

.owl-logos .owl-stage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.card {
  background-color: #fff;
  border: 1px solid #dedede;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07);
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07);
  margin-bottom: 30px;
  border-radius: 6px;
}

.card .card-img {
  position: relative;
  margin: -1px -1px 0 -1px;
  width: auto;
}

.card .card-img img {
  max-width: 100%;
}

.card .card-img .badge {
  margin-right: 10px;
  position: absolute;
  bottom: 20px;
  left: 30px;
}

.card .card-img .card-likes {
  position: absolute;
  bottom: 23px;
  right: 30px;
  font-size: 15px;
}

.card .card-img .card-likes a {
  color: #fff;
}

.card .card-img .card-likes a:before {
  content: "\f08a";
  font-family: "FontAwesome";
  margin-right: 6px;
}

.card .card-img .card-likes a:hover:before {
  content: "\f004";
}

.card .card-img-top {
  width: 100%;
  max-width: 100%;
  height: auto;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.card .card-block {
  padding: 20px;
}

.card .card-block::after {
  display: block;
  content: "";
  clear: both;
}

.card .card-block .card-title {
  font-size: 17px;
  line-height: 22px;
  margin-bottom: 0;
}

.card .card-block .card-meta {
  font-size: 0.875rem;
  margin-top: 3px;
  margin-bottom: 13px;
}

.card .card-block p {
  font-size: 0.875rem;
}

.card .card-block p:last-child {
  margin-bottom: 0;
}

.card .card-block .divider span {
  background-color: #fff;
}

.card-header {
  background-color: transparent;
  border-bottom: 1px solid #efefef;
  padding: 15px 20px;
  border-radius: 6px 6px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.card-header::after {
  display: block;
  content: "";
  clear: both;
}

.card-header .card-title {
  color: #585858;
  font-weight: 600;
  font-size: 15px;
  margin: 0;
}

.card-header .card-title i {
  float: left;
  margin-right: 10px;
}

.card-header .card-tools {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
}

.card-header .card-tools a {
  display: inline-block;
}

.card-footer {
  border-top: 1px solid #efefef;
  padding: 10px 20px;
  background-color: transparent;
}

.card-primary {
  border-color: #2575dc;
}

.card-primary .card-header {
  background-color: #2575dc;
}

.card-primary .card-header .card-title {
  color: #fff;
}

.card-success {
  border-color: #6ab961;
}

.card-success .card-header {
  background-color: #6ab961;
}

.card-success .card-header .card-title {
  color: #fff;
}

.card-danger {
  border-color: #e9594a;
}

.card-danger .card-header {
  background-color: #e9594a;
}

.card-danger .card-header .card-title {
  color: #fff;
}

.card-info {
  border-color: #52BAFF;
}

.card-info .card-header {
  background-color: #52BAFF;
}

.card-info .card-header .card-title {
  color: #fff;
}

.card-warning {
  border-color: #FFA14F;
}

.card-warning .card-header {
  background-color: #FFA14F;
}

.card-warning .card-header .card-title {
  color: #fff;
}

.card-inverse {
  border-color: #3f464f;
}

.card-inverse .card-header {
  background-color: #3f464f;
}

.card-inverse .card-header .card-title {
  color: #fff;
}

.card-review {
  border: 0;
  margin: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: transparent;
}

.card-review .card-img {
  margin: 0 0 40px 0;
  border-radius: 0;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  text-align: center;
}

.card-review .card-img img {
  width: auto;
  display: inline-block;
}

.card-review .card-img .badge {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -20px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 0.875rem;
  font-weight: bold;
  padding: 0;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.08);
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.08);
}

.card-review .card-block {
  padding: 0;
  text-align: center;
}

.card-review .card-block .card-title {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
}

.card-review .card-block p {
  font-size: 14px;
}

.card-video {
  background-color: transparent;
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.card-video:hover .card-img:after {
  width: 100%;
}

.card-video .card-img {
  position: relative;
  margin: 0 0 15px 0;
}

.card-video .card-img:after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 0;
  height: 3px;
  z-index: 3;
  background: red;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.card-video .card-img .card-meta span {
  position: absolute;
  bottom: 15px;
  right: 15px;
  left: auto;
  display: inline-block;
  background-color: rgba(25, 25, 25, 0.9);
  font-size: 12px;
  padding: 2px 7px;
  color: #fff;
  border-radius: 3px;
}

.card-video .card-img-top {
  padding: 0px;
  border-radius: 0;
}

.card-video .card-block {
  padding: 0;
}

.card-video .card-block .card-meta {
  margin-top: 5px;
  font-size: 13px;
  color: #888888;
}

.card-video .card-block .card-meta span:after {
  content: "\002022";
  margin: 0 3px 0 5px;
  display: inline-block;
}

.card-video .card-block .card-meta span:last-child:after {
  display: none;
}

.card-video .card-block .card-meta i {
  margin-right: 3px;
}

.card-video .card-block .card-title {
  font-size: 15px;
  line-height: 1.2;
}

.card-widget {
  background-color: transparent;
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.card-widget .card-img img {
  padding: 0px;
  border-radius: 0;
  margin-bottom: 15px;
}

.card-widget .card-meta {
  margin-top: 5px;
  font-size: 13px;
  color: #888888;
}

.card-widget .card-meta span:after {
  content: "\002022";
  margin: 0 3px 0 5px;
  display: inline-block;
}

.card-widget .card-meta span:last-child:after {
  display: none;
}

.card-widget .card-meta i {
  margin-right: 3px;
}

.card-widget .card-block {
  padding: 0;
}

.card-widget .card-block .card-title {
  font-size: 15px;
  line-height: 1.3;
}

.card-post {
  border-color: #e4e4e4;
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.03);
}

.card-post .card-header {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  padding: 15px 20px;
}

.card-post .card-title {
  font-size: 17px;
  color: #4f4f4f;
}

.card-post .card-img {
  padding: 20px;
}

.card-post .card-block {
  font-size: 15px;
  padding: 0 20px;
}

.card-lg .card-block {
  padding: 30px;
}

.accordion .card {
  border-color: #e4e4e4;
  margin-bottom: 7px;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
          box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
}

.accordion .card-header {
  display: block;
  padding: 0;
  border: 0;
}

.accordion .card-header h5 {
  font-size: 15px;
  margin: 0;
  display: block;
}

.accordion .card-header h5 a {
  padding: 15px 20px;
  display: block;
  border-bottom: 1px solid #efefef;
}

.accordion .card-header h5 a:focus, .accordion .card-header h5 a:hover {
  color: inherit;
}

.accordion .card-header h5 a.collapsed {
  border: 0;
}

.accordion .card-body {
  font-size: 14px;
}

.accordion-collapsed .card {
  margin: 0;
  border-radius: 0;
  border-color: #e4e4e4;
  border-bottom: 0;
  border-top-color: #efefef;
}

.accordion-collapsed .card:first-child {
  border-top-color: #e4e4e4;
}

.accordion-collapsed .card:last-child {
  border-bottom: 1px solid #e4e4e4;
}

.accordion-icon .card-header h5 a:before {
  content: "\f068";
  font-family: "FontAwesome";
  font-size: 12px;
  margin-right: 8px;
}

.accordion-icon .card-header h5 a.collapsed:before {
  content: "\f067";
}

.accordion-icon-custom .card-header h5 a:before {
  content: "\f00d";
  font-family: "FontAwesome";
  font-size: 12px;
  margin-right: 8px;
}

.accordion-icon-custom .card-header h5 a.collapsed:before {
  content: "\f00c";
}

.accordion-transparent .card {
  background-color: transparent;
  margin: 0;
  border-width: 0 0 1px 0;
  border-color: #e6e6e6;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.accordion-transparent .card:last-child {
  border-bottom: 0;
}

.accordion-transparent .card-body {
  padding: 10px 0 25px 0;
}

.accordion-transparent .card-header h5 a {
  padding: 15px 0;
  border-bottom-width: 0px;
  border-color: #e6e6e6;
}

.nav-tabs {
  padding-left: 10px;
}

.nav-tabs .nav-item .nav-link {
  font-size: 14px;
  font-weight: 500;
  padding: 13px 18px;
  background-color: #f3f3f3;
  border-radius: 0;
  margin: 0;
  border-color: #ddd;
  border-width: 1px 0px 1px 1px;
  line-height: 1.25;
  color: #616161;
}

.nav-tabs .nav-item .nav-link i {
  margin-right: 5px;
}

.nav-tabs .nav-item .nav-link:focus, .nav-tabs .nav-item .nav-link:hover {
  color: inherit;
  background-color: transparent;
  border-width: 1px 0 0 1px;
  border-color: #ddd;
  color: #616161;
}

.nav-tabs .nav-item .nav-link.active {
  background-color: #fafafa;
  color: #5a5a5a;
  border-radius: 0;
  border-bottom-color: transparent;
  border-width: 1px 0px 1px 1px;
}

.nav-tabs .nav-item .nav-link.active:focus, .nav-tabs .nav-item .nav-link.active:hover {
  border-width: 1px 0px 1px 1px;
  background-color: #fafafa;
  color: #5a5a5a;
}

.nav-tabs .nav-item:last-child .nav-link.active,
.nav-tabs .nav-item:last-child .nav-link {
  border-width: 1px;
}

.nav-icon-left li a > i {
  margin-right: 6px;
}

.tab-content > p:last-child {
  margin-bottom: 0;
}

.tabs-boxed .nav-tabs {
  margin-bottom: -1px;
  padding-left: 0;
}

.tabs-boxed .nav-tabs .nav-item .nav-link {
  background-color: transparent;
}

.tabs-boxed .nav-tabs .nav-item .nav-link.active {
  background-color: #fff;
}

.tabs-boxed .nav-tabs .nav-item .nav-link.active:focus, .tabs-boxed .nav-tabs .nav-item .nav-link.active:hover {
  background-color: #fff;
}

.tabs-boxed .tab-content {
  padding: 30px;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
}

.tabs-color .nav-tabs {
  margin-bottom: -1px;
  padding-left: 0;
  border: 0;
}

.tabs-color .nav-tabs .nav-item .nav-link {
  background-color: transparent;
  border-width: 0 0 2px 0;
  border-color: transparent;
  padding: 10px 15px 20px 15px;
}

.tabs-color .nav-tabs .nav-item .nav-link:focus, .tabs-color .nav-tabs .nav-item .nav-link:hover {
  border-color: transparent;
  border-width: 0 0 2px 0;
}

.tabs-color .nav-tabs .nav-item:last-child.active .nav-link,
.tabs-color .nav-tabs .nav-item:last-child .nav-link {
  border-width: 0 0 2px 0;
}

.tabs-color .nav-tabs .nav-item .nav-link.active {
  background-color: transparent;
  border-width: 0 0 2px 0;
  border-color: #2575dc;
}

.tabs-color .nav-tabs .nav-item .nav-link.active:focus, .tabs-color .nav-tabs .nav-item .nav-link.active:hover {
  background-color: transparent;
  border-width: 0 0 2px 0;
  border-color: #2575dc;
}

.tabs-color .tab-content {
  padding: 30px;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
}

.popover {
  -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.07);
          box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.07);
  border-color: rgba(128, 128, 128, 0.2);
}

.popover-header {
  background-color: #fafafa;
  padding: 13px 18px;
  margin: -1px;
  color: #4f4f4f;
  font-size: 14px;
}

.popover-body {
  padding: 18px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  line-height: 1.45;
  color: #616161;
}

.popover-body p:last-child {
  margin-bottom: 0;
}

.tooltip {
  font-family: "Roboto", sans-serif;
}

.tooltip.show {
  opacity: 1;
}

.tooltip-inner {
  border-radius: 3px;
  padding: 5px 10px;
  background-color: #202225;
  font-size: 13px;
}

.tooltip.bs-tooltip-top .arrow:before,
.tooltip.bs-tooltip-top-left .arrow:before,
.tooltip.bs-tooltip-top-right .arrow:before {
  border-top-color: #202225;
}

.tooltip.bs-tooltip-right .arrow:before {
  border-right-color: #202225;
}

.tooltip.bs-tooltip-left .arrow:before {
  border-left-color: #202225;
}

.tooltip.bs-tooltip-bottom .arrow:before,
.tooltip.bs-tooltip-bottom-left .arrow:before,
.tooltip.bs-tooltip-bottom-right .arrow:before {
  border-bottom-color: #202225;
}

.heading {
  text-align: center;
  margin: 0 auto 50px auto;
  max-width: 800px;
}

.heading i {
  font-size: 30px;
  color: #2575dc;
  border: 2px solid #2575dc;
  margin-bottom: 25px;
  display: inline-block;
  border-radius: 30px;
  padding: 0;
  width: 60px;
  height: 60px;
  line-height: 56px;
}

.heading h2 {
  font-size: 25px;
  margin-bottom: 20px;
}

.heading p {
  font-size: 16px;
}

.heading-left {
  text-align: left;
  margin: 0 0 50px 0;
}

.heading-left i {
  text-align: center;
}

.table {
  margin-bottom: 0;
}

.table > thead > tr > th {
  border-top: 0;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  border-color: #E6E6E6;
  padding: 8px;
}

.table > thead > tr > th {
  border-bottom: 0;
  padding: 15px 5px;
  color: #616161;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  vertical-align: middle;
  outline: 0;
}

.table-bordered,
.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border-color: #EAEAEA;
}

.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f3f3f3;
}

table.dataTable {
  margin-bottom: 20px !important;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  bottom: 15px;
}

div.dataTables_wrapper div.dataTables_filter label {
  text-align: right;
}

div.dataTables_wrapper div.dataTables_filter input {
  height: 36px;
  line-height: 36px;
}

div.dataTables_wrapper div.dataTables_length select {
  width: auto;
  padding: 0 0 0 5px;
  height: 36px;
  margin: 0 5px;
}

.dataTables_wrapper.container-fluid {
  padding: 0;
}

.table .custom-checkbox {
  margin-left: 8px;
  float: left;
}

.table thead .custom-checkbox {
  margin-left: 11px;
}

.forum {
  display: table;
  width: 100%;
  border: 1px solid #e4e4e4;
  border-radius: 3px;
}

.forum + .forum-headline {
  margin-top: 50px;
}

.forum .forum-head {
  display: table-header-group;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.forum .forum-head > div {
  color: #616161;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 15px;
  border-bottom: 1px solid #e8e8e8;
  display: table-cell;
}

.forum .forum-footer {
  display: table-header-group;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.forum .forum-footer > div {
  color: #616161;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 15px;
  display: table-cell;
}

.forum .forum-group {
  display: table-row-group;
}

.forum .forum-group.forum-closed {
  color: #A9A9A9;
  background-color: #fafafa;
}

.forum .forum-group.forum-closed a,
.forum .forum-group.forum-closed a:hover,
.forum .forum-group.forum-closed .forum-icon i,
.forum .forum-group.forum-closed .forum-title h4 {
  color: #A9A9A9 !important;
}

.forum .forum-group.forum-closed .forum-latest img {
  opacity: .3;
}

.forum .forum-group:last-child > .forum-row > div {
  border-bottom: 0;
}

.forum .forum-group:nth-child(even) .forum-row > div {
  background-color: #f7f7f7;
}

.forum .forum-group .forum-row {
  display: table-row;
}

.forum .forum-group .forum-row > div {
  font-size: 14px;
  padding: 17px 15px;
  display: table-cell;
  border-bottom: 1px solid #e8e8e8;
  vertical-align: middle;
}

.forum .forum-group .forum-row .forum-icon {
  padding-right: 3px;
  padding-left: 20px;
  color: #636363;
  width: 25px;
  text-align: center;
  position: relative;
}

.forum .forum-group .forum-row .forum-icon i {
  font-size: 23px;
}

.forum .forum-group .forum-row .forum-icon .badge {
  position: absolute;
  padding: 4px;
  top: 16px;
  right: -6px;
  font-weight: bold;
  font-size: 10px;
}

.forum .forum-group .forum-row .forum-icon .badge i {
  font-size: 13px;
}

.forum .forum-group .forum-row .forum-title {
  width: 55%;
  padding-left: 15px;
}

.forum .forum-group .forum-row .forum-title p {
  font-size: 13px;
  margin: 0;
}

.forum .forum-group .forum-row .forum-title h4 {
  font-size: 15px;
  margin: 0 0 2px 0;
  color: #676767;
}

.forum .forum-group .forum-row .forum-latest > div {
  float: left;
}

.forum .forum-group .forum-row .forum-latest a {
  display: inline;
}

.forum .forum-group .forum-row .forum-latest img {
  float: left;
  margin-right: 15px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  image-rendering: -webkit-optimize-auto;
}

.forum .forum-group .forum-row .forum-latest span {
  display: inline-block;
  font-size: 13px;
}

.forum .forum-group .forum-row .forum-latest h5 {
  margin: 0;
  font-size: 14px;
  color: #717171;
}

.forum-headline {
  color: #5d5d5d;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 25px;
}

.forum-headline::after {
  display: block;
  content: "";
  clear: both;
}

.forum-headline h5 {
  font-weight: 600;
  margin: 0;
  color: #5d5d5d;
  font-size: 18px;
}

.forum-headline span {
  font-weight: 300;
  display: block;
  font-size: 14px;
  color: #909090;
  margin-top: 6px;
}

.forum-post {
  padding: 20px;
  margin-bottom: 35px;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.03);
}

.forum-post .forum-header {
  margin-bottom: 25px;
}

.forum-post .forum-header::after {
  display: block;
  content: "";
  clear: both;
}

.forum-post .forum-header > div {
  float: left;
}

.forum-post .forum-header > div:first-child {
  width: 50px;
  margin-top: -3px;
}

.forum-post .forum-header > div:first-child img {
  border-radius: 6px;
}

.forum-post .forum-header > div:nth-child(2) {
  padding-left: 17px;
  padding-top: 3px;
  float: left;
}

.forum-post .forum-header > div:nth-child(2) .forum-meta {
  margin-bottom: 0;
}

.forum-post .forum-header > div:last-child {
  float: right;
  text-align: right;
}

.forum-post .forum-header > div:last-child span {
  display: block;
  font-size: 14px;
}

.forum-post .forum-header .forum-title {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0px;
}

.forum-post .forum-header .forum-meta {
  font-size: 14px;
  margin-top: 3px;
  margin-bottom: 25px;
}

.forum-post .forum-header .forum-meta span i {
  margin-right: 5px;
}

.forum-post .forum-header .forum-meta span:after {
  color: #9c9c9c;
  content: "/";
  margin: 0 3px 0 8px;
  display: inline-block;
}

.forum-post .forum-header .forum-meta span:last-child:after {
  display: none;
}

.forum-post .forum-body {
  font-size: 15px;
}

.forum-post .forum-body > h5 {
  font-size: 16px;
}

.forum-post .forum-body .forum-thumbnail {
  margin-bottom: 20px;
  position: relative;
}

.forum-post .forum-body .forum-thumbnail .forum-thumbnail-resize {
  color: #fff;
  display: inline-block;
  background-color: #242629;
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 35px;
  height: 35px;
  line-height: 34px;
  text-align: center;
  border-radius: 3px;
  font-size: 14px;
  -webkit-box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.3);
          box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.3);
}

.forum-post .forum-body .forum-thumbnail .forum-thumbnail-resize:hover {
  background-color: #2575dc;
}

.forum-post .forum-body img + p {
  margin-top: 30px;
}

.forum-post .forum-footer {
  margin-top: 30px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.forum-post .forum-footer .forum-panel {
  background: #fafafa;
  padding: 20px;
  border-top: 1px solid #e6e6e6;
}

.forum-post .forum-footer .forum-panel::after {
  display: block;
  content: "";
  clear: both;
}

.forum-post .forum-footer .forum-users h5 {
  font-size: 14px;
  color: #6d6d6d;
  margin-bottom: 15px;
}

.forum-post .forum-footer .forum-users a {
  display: inline-block;
}

.forum-post .forum-footer .forum-users > div img {
  width: 35px;
}

.forum-post .forum-footer .forum-actions {
  border-top: 1px solid #e6e6e6;
  padding: 12px 20px;
}

.forum-post .forum-footer .forum-actions::after {
  display: block;
  content: "";
  clear: both;
}

.forum-post .forum-footer .forum-actions i {
  margin-right: 5px;
}

.forum-post .forum-footer .forum-actions a {
  margin-left: 15px;
}

.forum-post .forum-footer .forum-actions > div {
  font-size: 13px;
}

.forum-post .forum-footer .forum-actions > div:first-child {
  float: left;
}

.forum-post .forum-footer .forum-actions > div:last-child {
  float: right;
}

.forum-post .forum-attachment {
  display: inline-block;
  padding: 0;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  float: left;
  margin-right: 25px;
}

.forum-post .forum-attachment a {
  font-size: 14px;
  display: block;
  font-weight: 500;
  color: #fff;
  background-color: #2575dc;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding: 10px 15px;
}

.forum-post .forum-attachment a i {
  margin-right: 5px;
}

.forum-post .forum-attachment span {
  font-size: 12px;
  display: block;
  padding: 8px 15px;
  color: rgba(255, 255, 255, 0.6);
  background-color: #3f464f;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.forum-reply {
  padding: 10px 0 20px 0;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 10px;
}

.forum-reply::after {
  display: block;
  content: "";
  clear: both;
}

.forum-reply:last-child {
  border-bottom: 0;
}

.forum-reply .forum-header::after {
  display: block;
  content: "";
  clear: both;
}

.forum-reply .forum-header > div {
  float: left;
}

.forum-reply .forum-header > div:first-child {
  width: 45px;
  position: relative;
  margin-top: 3px;
}

.forum-reply .forum-header > div:first-child img {
  border-radius: 50%;
}

.forum-reply .forum-header > div:first-child .badge {
  position: absolute;
  bottom: -4px;
  right: -2px;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  line-height: 23px;
  text-align: center;
  padding: 0;
  font-weight: 600;
}

.forum-reply .forum-header > div:nth-child(2) {
  padding-left: 20px;
  float: left;
}

.forum-reply .forum-header > div:nth-child(2) .forum-meta {
  margin-bottom: 0;
}

.forum-reply .forum-header > div:last-child {
  float: right;
  text-align: right;
}

.forum-reply .forum-header > div:last-child span {
  display: block;
  font-size: 14px;
}

.forum-reply .forum-header .forum-title h5,
.forum-reply .forum-header .forum-title {
  font-size: 15px;
  margin-bottom: 0px;
}

.forum-reply .forum-header .forum-title h5 .badge,
.forum-reply .forum-header .forum-title .badge {
  padding: 3px 5px;
  margin-left: 5px;
}

.forum-reply .forum-header .forum-title h5 {
  display: inline-block;
}

.forum-reply .forum-header .forum-meta {
  font-size: 14px;
  margin-bottom: 25px;
}

.forum-reply .forum-header .forum-meta span {
  margin-right: 5px;
  padding-right: 10px;
  border-right: 1px solid #d6d6d6;
}

.forum-reply .forum-header .forum-meta span i {
  margin-right: 5px;
  font-size: 12px;
}

.forum-reply .forum-header .forum-meta span:last-child:after {
  display: none;
}

.forum-reply .forum-body {
  margin-top: 15px;
  padding-left: 65px;
  font-size: 15px;
  border-radius: 3px;
}

.forum-reply .forum-body > p:last-child {
  margin-bottom: 0;
}

.forum-reply .forum-body .forum-user {
  margin-bottom: 15px;
}

.forum-reply .forum-body .forum-user h5 {
  font-size: 16px;
  margin: 0 0 3px 0;
}

.forum-reply .forum-body .forum-user span {
  font-size: 14px;
  display: inline-block;
}

.forum-reply .forum-body .forum-user span:after {
  color: #9c9c9c;
  content: "/";
  margin: 0 3px 0 8px;
  display: inline-block;
}

.forum-reply .forum-body .forum-user span:last-child:after {
  display: none;
}

.comments .comments-header::after {
  display: block;
  content: "";
  clear: both;
}

.comments .comments-header h5 {
  float: left;
  padding-top: 8px;
  margin-bottom: 0;
}

.comments > ul {
  margin-top: 30px;
  margin-bottom: 25px;
}

.comments > ul > li:last-child .comment {
  border-bottom: 0;
}

.comments > ul li .comment {
  width: 100%;
  display: table;
  border-bottom: 1px solid #eaeaea;
  padding: 20px 0;
}

.comments > ul li .comment:hover .comment-action .dropdown {
  display: inline-block !important;
}

.comments > ul li .comment > div {
  display: table-cell;
  vertical-align: top;
}

.comments > ul li .comment > div.comment-avatar {
  width: 60px;
  position: relative;
}

.comments > ul li .comment > div.comment-avatar .badge {
  position: absolute;
  top: 38px;
  right: 0px;
  padding: 0;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  line-height: 23px;
}

.comments > ul li .comment > div.comment-avatar img {
  border-radius: 50%;
}

.comments > ul li .comment > div.comment-post {
  padding-left: 22px;
  font-size: 15px;
}

.comments > ul li .comment > div.comment-post .comment-header {
  margin-bottom: 10px;
  display: block;
}

.comments > ul li .comment > div.comment-post .comment-header::after {
  display: block;
  content: "";
  clear: both;
}

.comments > ul li .comment > div.comment-post .comment-author {
  float: left;
}

.comments > ul li .comment > div.comment-post .comment-author h5 {
  display: inline-block;
  font-size: 16px;
  margin-bottom: 0px;
}

.comments > ul li .comment > div.comment-post .comment-author h5 > span {
  padding: 3px 5px;
  margin-left: 5px;
  vertical-align: top;
}

.comments > ul li .comment > div.comment-post .comment-author > span {
  font-size: 14px;
  display: inline-block;
  margin-right: 10px;
  padding-right: 10px;
}

.comments > ul li .comment > div.comment-post .comment-author > span:before {
  color: #9c9c9c;
  content: "\002022";
  margin: 0 5px 0 0;
  display: inline-block;
}

.comments > ul li .comment > div.comment-post .comment-action {
  float: right;
  padding-right: 10px;
}

.comments > ul li .comment > div.comment-post .comment-action .dropdown {
  display: none;
}

.comments > ul li .comment > div.comment-post .comment-action .dropdown .dropdown-menu-right {
  margin-right: -7px;
}

.comments > ul li .comment > div.comment-post .comment-action .dropdown > a {
  color: #848484;
}

.comments > ul li .comment > div.comment-post .comment-action .dropdown > a:hover {
  color: #5e5e5e;
}

.comments > ul li .comment > div.comment-post .comment-action .dropdown.show {
  display: inline-block;
}

.comments > ul li .comment > div.comment-post .comment-footer {
  display: block;
}

.comments > ul li .comment > div.comment-post .comment-footer ul {
  padding: 0;
}

.comments > ul li .comment > div.comment-post .comment-footer ul li {
  display: inline-block;
  font-size: 14px;
}

.comments > ul li .comment > div.comment-post .comment-footer ul li i {
  margin-right: 3px;
}

.comments > ul li .comment > div.comment-post .comment-footer ul li:after {
  font-size: 12px;
  color: #cccccc;
  content: "\002022";
  margin: 0 5px;
  display: inline-block;
}

.comments > ul li .comment > div.comment-post .comment-footer ul li:last-child:after {
  display: none;
}

.comments > ul li .comment > div.comment-post > span {
  font-size: 14px;
}

.comments > ul li .comment > div.comment-post > span i {
  margin-right: 3px;
}

.comments > ul li:first-child .comment {
  padding-top: 0;
}

.comments > ul li > ul {
  padding-left: 80px;
  padding-top: 20px;
}

.fb-comments iframe,
.fb-comments span,
.fb-comments {
  width: 100% !important;
}

.post {
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ececec;
  font-size: 15px;
}

.post::after {
  display: block;
  content: "";
  clear: both;
}

.post p + h5 {
  margin-top: 35px;
}

.post .post-title {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 0px;
}

.post .post-thumbnail {
  position: relative;
  margin-bottom: 25px;
  margin-top: 25px;
}

.post .post-thumbnail > a {
  display: block;
}

.post .post-thumbnail .badge {
  position: absolute;
  top: 20px;
  left: 20px;
  -webkit-box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.2);
  padding: 10px 13px;
}

.post .post-footer {
  margin: 0 -20px -10px -20px;
  padding: 0px 20px 20px 20px;
}

.post .post-header {
  display: table;
  margin-bottom: 25px;
}

.post .post-header > div {
  display: table-cell;
  vertical-align: middle;
}

.post .post-header > div:first-child {
  width: 55px;
  vertical-align: top;
}

.post .post-header > div:first-child img {
  border-radius: 50%;
}

.post .post-header > div:last-child {
  padding-left: 15px;
}

.post .post-header > div:last-child .post-title {
  font-size: 22px;
}

.post .post-header > div:last-child .post-meta {
  margin-top: 4px;
  margin-bottom: 0;
}

.post .post-meta {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 25px;
}

.post .post-meta span i {
  margin-right: 5px;
}

.post .post-meta span:after {
  color: #9c9c9c;
  content: "\002022";
  margin: 0 5px;
  display: inline-block;
}

.post .post-meta span:last-child:after {
  display: none;
}

.post-related {
  margin-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
}

.post-actions {
  margin: 25px 0;
  padding-bottom: 25px;
  border-bottom: 1px solid #eaeaea;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.post-actions::after {
  display: block;
  content: "";
  clear: both;
}

.post-actions .social-share span {
  top: auto;
}

.post-actions .post-tags {
  max-width: 80%;
}

.post-actions .post-tags::after {
  display: block;
  content: "";
  clear: both;
}

.post-actions .post-tags a {
  float: left;
  margin: 0 5px 5px 0;
}

.post-tags a {
  color: #7d7d7d;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  padding: 7px 10px;
  font-size: 13px;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  display: inline-block;
  font-weight: 400;
}

.post-tags a:hover {
  color: #505050;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
          box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
  border-color: #c7c7c7;
}

.pager {
  display: table;
  width: 100%;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 0;
}

.pager > div {
  display: table-cell;
  width: 50%;
  height: 130px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

.pager > div .overlay {
  background-color: #2d2d2d;
  opacity: .7;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.pager > div:hover .overlay {
  opacity: .5;
}

.pager > div h2 {
  font-size: 18px;
  color: #fff;
  margin: 0;
}

.pager > div h2 a {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  opacity: .9;
  font-weight: 300;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  padding-left: 60px;
}

.pager > div h2 a:focus, .pager > div h2 a:active, .pager > div h2 a:hover {
  opacity: 1;
  color: #fff;
}

.pager > div h2 a:focus:before, .pager > div h2 a:active:before, .pager > div h2 a:hover:before {
  opacity: 1;
}

.pager > div:last-child h2 a {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding-right: 60px;
}

.pager > div:last-child h2 a:before {
  display: none;
}

.pager > div:last-child h2 a:focus:after, .pager > div:last-child h2 a:active:after, .pager > div:last-child h2 a:hover:after {
  opacity: 1;
}

.post-single {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.post-timeline-date {
  text-align: center;
  position: relative;
  z-index: 2;
  display: block;
  background-color: #fafafa;
  padding: 13px 0;
  margin-top: -15px;
  margin-bottom: 15px;
}

.post-timeline-date span {
  font-weight: 500;
  color: #6b6b6b;
  border: 1px solid #e2e2e2;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
}

.post-timeline-date i {
  margin-right: 5px;
}

.post-timeline {
  margin: 0 -2.5%;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
}

.post-timeline::after {
  display: block;
  content: "";
  clear: both;
}

.post-timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 1px;
  background-color: rgba(0, 0, 0, 0.05);
  left: 50%;
  margin-bottom: 30px;
  margin-left: -1.5px;
}

.post-timeline .post {
  float: left;
  width: 45%;
  margin: 0 2.5% 45px 2.5%;
  padding: 20px;
  position: relative;
}

.post-timeline .post:nth-child(even) {
  float: right;
  margin-top: 55px;
  margin-bottom: 0px;
}

.post-timeline .post:nth-child(even) .post-timeline-dot {
  right: auto;
  left: -39px;
}

.post-timeline .post .post-title {
  font-size: 20px;
  line-height: 24px;
}

.post-timeline .post p {
  font-size: 14px;
}

.post-timeline .post .post-meta {
  margin-bottom: 0;
}

.post-timeline .post .post-thumbnail {
  margin: 15px -20px 20px -20px;
}

.post-timeline .post .post-footer {
  padding-bottom: 10px;
}

.post-timeline .post .post-timeline-dot {
  position: absolute;
  top: 16px;
  right: -38px;
  width: 16px;
  height: 16px;
  border: 2px solid #999999;
  background-color: #fafafa;
  border-radius: 50%;
  -webkit-transition: 0.25s;
  transition: 0.25s;
  -webkit-box-shadow: 0 0 0 5px #fafafa;
          box-shadow: 0 0 0 5px #fafafa;
  cursor: pointer;
}

.post-timeline .post .post-timeline-dot:hover {
  border-color: #2575dc;
}

.post-columns {
  -webkit-column-count: 3;
          column-count: 3;
  -webkit-column-gap: 25px;
          column-gap: 25px;
}

.post-columns .post {
  width: 100%;
  display: inline-block;
  padding: 20px 20px 10px 20px;
}

.post-columns .post .post-thumbnail {
  margin: 15px -20px 20px -20px;
}

.post-columns .post .post-title {
  font-size: 18px;
  line-height: 20px;
}

.post-columns .post .post-footer {
  padding-bottom: 15px;
  padding-top: 15px;
  border-top: 1px solid #eaeaea;
  background-color: #fafafa;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.post-columns .post .post-meta {
  margin-bottom: 0;
}

.post-columns .post p {
  font-size: 14px;
}

.post-columns .post .video-play i {
  width: 70px;
  height: 70px;
  line-height: 70px;
}

.post-md {
  display: table;
  border-color: #e8e8e8;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.post-md > div {
  display: table-cell;
  vertical-align: top;
}

.post-md .post-thumbnail {
  width: 30%;
}

.post-md .post-block {
  padding-left: 20px;
}

.post-md .post-block .post-title {
  font-size: 18px;
  line-height: 22px;
}

.post-md .post-block .post-meta {
  margin-bottom: 15px;
}

.post-md .post-block p {
  margin: 0;
  line-height: 20px;
}

.post-review {
  display: table;
  border-color: #e8e8e8;
  margin-bottom: 23px;
  padding-bottom: 23px;
}

.post-review > div {
  display: table-cell;
  vertical-align: top;
}

.post-review .post-header {
  margin-bottom: 0;
  width: 100%;
}

.post-review .post-header > div {
  vertical-align: top;
}

.post-review .post-header > div:first-child {
  width: 90%;
}

.post-review .post-header .badge {
  border-radius: 50%;
  width: 42px;
  height: 42px;
  line-height: 42px;
  padding: 0;
  font-size: 13px;
  font-weight: bold;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
}

.post-review .post-thumbnail {
  width: 150px;
}

.post-review .post-thumbnail a {
  position: relative;
}

.post-review .post-thumbnail .badge {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  line-height: 36px;
  padding: 0;
  font-size: 13px;
  font-weight: bold;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
          box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
  bottom: 8px;
  left: 8px;
  right: auto;
  top: auto;
}

.post-review .post-block {
  padding-left: 20px;
}

.post-review .post-block .post-title {
  font-size: 18px;
  line-height: 22px;
}

.post-review .post-block .post-meta {
  margin-bottom: 15px;
}

.post-review .post-block p {
  margin: 0;
  line-height: 20px;
}

.post-card {
  border: 1px solid #e4e4e4;
  -webkit-box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.03);
  background-color: #fff;
  padding: 20px 20px 10px 20px;
  border-radius: 4px;
}

.post-card .post-title {
  font-size: 22px;
}

.post-card.post-md {
  border: 1px solid #e4e4e4;
  padding: 15px;
  width: 100%;
  margin-bottom: 15px;
}

.post-card.post-md .post-thumbnail {
  width: 225px;
}

.post-card.post-md .post-title {
  font-size: 17px;
  line-height: 19px;
}

.post-card .post-footer {
  border-top: 1px solid #efefef;
  padding-top: 15px;
}

.post-carousel {
  position: relative;
}

.post-carousel .badge {
  margin-bottom: 15px;
  position: absolute;
  top: 20px;
  right: 30px;
  z-index: 3;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}

.post-carousel .post-block {
  position: absolute;
  bottom: 0;
  z-index: 2;
  left: 0;
  width: 100%;
  padding-left: 35px;
  padding-top: 60px;
  padding-bottom: 30px;
}

.post-carousel .post-block .post-title {
  color: #fff;
  font-size: 19px;
  font-weight: bold;
  margin: 0 0 7px 0;
}

.post-carousel .post-block a:hover {
  color: #fff;
}

.post-carousel .post-block .post-meta {
  font-size: 14px;
  margin-top: 5px;
  color: rgba(255, 255, 255, 0.6);
}

.post-carousel .post-block .post-meta span i {
  margin-right: 5px;
}

.post-carousel .post-block .post-meta span:after {
  color: #9c9c9c;
  content: "\002022";
  margin: 0 5px;
  display: inline-block;
}

.post-carousel .post-block .post-meta span:last-child:after {
  display: none;
}

.post-carousel .post-block .post-caption {
  background-color: #212121;
  padding: 20px 25px;
  display: inline-block;
  border-left: 4px solid #2575dc;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.post-profile {
  padding: 15px;
}

.post-profile .post-header {
  margin-bottom: 15px;
  position: relative;
  width: 100%;
}

.post-profile .post-header > div {
  vertical-align: top;
}

.post-profile .post-header > div:last-child .post-title {
  font-size: 16px;
}

.post-profile .post-header > div:last-child .post-title a + a:last-child:before {
  content: "\f0da";
  font-family: "FontAwesome";
  font-size: 13px;
  margin: 0 7px 0 3px;
  color: #ccc;
}

.post-profile .post-header > div:first-child {
  width: 45px;
}

.post-profile .post-header > div:first-child img {
  border-radius: 4px;
}

.post-profile .post-header > div .dropdown {
  position: absolute;
  right: 5px;
  top: 0;
}

.post-profile .post-thumbnail {
  margin: 0 0 15px 0;
}

.post-profile .post-footer {
  border: 0;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.post-profile .post-footer a:after {
  color: #9c9c9c;
  content: "\002022";
  margin: 0 8px;
  display: inline-block;
}

.post-profile .post-footer a:last-child:after {
  display: none;
}

.post-profile .post-footer a i {
  margin-right: 3px;
}

.masonry-3 {
  -webkit-column-gap: 10px;
          column-gap: 10px;
  -webkit-column-count: 3;
          column-count: 3;
}

figure {
  margin-bottom: 0px;
}

.figure-effect figure .figure-img {
  overflow: hidden;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  margin-bottom: 7px;
}

.figure-effect figure .figure-img a {
  display: block;
}

.figure-effect figure img {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.figure-effect figure:hover img {
  -webkit-transform: scale(1.08);
          transform: scale(1.08);
}

.figure-img .figure-likes {
  position: absolute;
  bottom: 18px;
  right: 18px;
  color: #fff;
  font-size: 15px;
}

.figure-img .figure-likes:before {
  content: "\f08a";
  font-family: "FontAwesome";
  margin-right: 6px;
}

.widget {
  margin-bottom: 45px;
}

.widget:last-child {
  margin-bottom: 0;
}

.widget .widget-title {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 0 8px 15px;
  border-left: 3px solid #2575dc;
  color: #424242;
  margin-bottom: 15px;
}

.widget .widget-title i {
  font-size: 15px;
}

.widget .widget-title span {
  font-weight: 300;
}

.widget img {
  width: 100%;
}

.widget-post > a {
  display: block;
}

.widget-post img {
  margin-bottom: 15px;
}

.widget-post h4 {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 0;
}

.widget-post span {
  margin-bottom: 10px;
  margin-top: 5px;
  font-size: 14px;
  display: inline-block;
}

.widget-post p {
  font-size: 14px;
  margin-bottom: 0;
}

.widget-post .widget-list {
  margin-top: 15px;
}

.widget-post .widget-list li {
  display: table;
  width: 100%;
  padding: 12px 0;
  border-top: 1px solid #e8e8e8;
}

.widget-post .widget-list li:last-child {
  padding-bottom: 0;
}

.widget-post .widget-list li > div {
  display: table-cell;
  vertical-align: top;
}

.widget-post .widget-list li > div.widget-img {
  width: 100px;
}

.widget-post .widget-list li > div:last-child {
  padding-left: 13px;
}

.widget-post .widget-list li > div img {
  margin: 0;
}

.widget-post .widget-list li > div h4 {
  font-size: 15px;
  line-height: 17px;
  margin: 0;
}

.widget-post .widget-list li > div span {
  font-size: 13px;
  margin: 0;
}

.widget-gallery ul li {
  margin-bottom: 5px;
}

.widget-gallery ul li a {
  overflow: hidden;
  display: block;
}

.widget-gallery ul li a img {
  -webkit-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.widget-gallery ul li a:hover img {
  -webkit-transform: scale(1.12);
          transform: scale(1.12);
}

.widget-video .widget-img {
  position: relative;
  margin-bottom: 15px;
}

.widget-video .widget-img span {
  position: absolute;
  bottom: 15px;
  right: 15px;
  left: auto;
  display: inline-block;
  background-color: rgba(25, 25, 25, 0.9);
  border-radius: 3px;
  font-size: 12px;
  padding: 2px 7px;
  color: #fff;
}

.widget-video .widget-img span:after {
  display: none;
}

.widget-video .widget-img .video-play-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  z-index: 3;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.widget-video .widget-img .video-play-icon i {
  background-color: #242629;
  display: inline-block;
  color: #fff;
  font-size: 20px;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: .1s ease-in-out;
  transition: .1s ease-in-out;
  border-radius: 50%;
}

.widget-video .widget-img .video-play-icon i:hover {
  color: #fff;
  background-color: #dc2745;
  -webkit-box-shadow: 0 1px 25px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 25px 0 rgba(0, 0, 0, 0.2);
}

.widget-video h4 {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 0;
}

.widget-video span {
  margin-top: 3px;
  font-size: 14px;
  display: inline-block;
}

.widget-video span i {
  margin-right: 3px;
}

.widget-video span:after {
  color: #9c9c9c;
  content: "\002022";
  margin: 0 3px 0 8px;
  display: inline-block;
}

.widget-video span:last-child:after {
  display: none;
}

.widget-video p {
  margin-top: 10px;
  font-size: 14px;
  margin-bottom: 0;
}

.widget-comments h4 {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 0;
  color: #636363;
}

.widget-comments span {
  margin-bottom: 10px;
  margin-top: 5px;
  font-size: 14px;
  display: inline-block;
}

.widget-comments p {
  font-size: 14px;
  margin-bottom: 0;
}

.widget-comments li {
  display: table;
  padding: 15px 0px;
  border-bottom: 1px solid #e8e8e8;
  width: 100%;
}

.widget-comments li:last-child {
  border: 0;
}

.widget-comments li > div {
  display: table-cell;
  vertical-align: top;
}

.widget-comments li > div:first-child {
  width: 34px;
}

.widget-comments li > div:first-child img {
  margin-top: 5px;
  border-radius: 50%;
}

.widget-comments li > div:last-child {
  padding-left: 15px;
}

.widget-comments li > div img {
  margin: 0;
}

.widget-comments li > div a {
  font-size: 14px;
  line-height: 18px;
  margin: 0;
}

.widget-comments li > div a b {
  font-weight: 500;
  color: #696969;
}

.widget-comments li > div a:hover {
  color: #696969;
}

.widget-comments li > div span {
  font-size: 13px;
  margin: 10px 0 0 0;
  display: block;
}

.widget-comments li > div span i {
  margin-right: 3px;
}

.widget-tabs .nav-tabs {
  padding-left: 5px;
}

.widget-tabs .widget-post .widget-list > li {
  padding: 13px 0px;
}

.widget-tabs .widget-post .widget-list > li:first-child {
  border-top: 0;
  padding-top: 5px;
}

.widget-tabs .widget-post .widget-list > li span {
  margin-top: 3px;
  font-size: 14px;
  display: inline-block;
}

.widget-tabs .widget-post .widget-list > li span i {
  margin-right: 3px;
}

.widget-tabs .widget-post .widget-list > li span:after {
  color: #9c9c9c;
  content: "\002022";
  margin: 0 3px 0 8px;
  display: inline-block;
}

.widget-tabs .widget-post .widget-list > li span:last-child:after {
  display: none;
}

.widget-tabs .widget-post .widget-list > li h4 {
  font-size: 15px;
  line-height: 18px;
}

.widget-list ul li a {
  display: block;
  padding: 10px 0;
  border-bottom: 1px solid #efefef;
  font-size: 14px;
}

.widget-list ul li:first-child a {
  padding-top: 5px;
}

.widget-list ul li:last-child a {
  border: 0;
}

.widget-game .widget-block {
  min-height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.widget-game .widget-block .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(37, 37, 37, 0.77)), to(#252525));
  background: linear-gradient(to bottom, rgba(37, 37, 37, 0.77) 0%, #252525 100%);
}

.widget-game .widget-block .widget-item {
  position: relative;
  z-index: 3;
  padding: 35px;
  color: #d0d0d0;
}

.widget-game .widget-block .widget-item h4 {
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 3px;
  color: #fff;
}

.widget-game .widget-block .widget-item h5 {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 30px;
}

.widget-game .widget-block .widget-item .meta {
  font-size: 14px;
}

.widget-game .widget-block .widget-item .badge {
  margin-right: 5px;
}

.widget-game .widget-block .widget-item ul li {
  display: inline-block;
  font-size: 14px;
}

.widget-game .widget-block .widget-item ul li a {
  color: #d0d0d0;
}

.widget-game .widget-block .widget-item ul li a:hover {
  text-decoration: underline;
  color: #fff;
}

.widget-game .widget-block .widget-item p {
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.widget-games a {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: block;
  height: 100px;
  width: 100%;
  padding: 25px 20px;
  position: relative;
}

.widget-games a:hover .overlay {
  opacity: .80;
}

.widget-games a .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #232323 0%, #232323 26%, rgba(37, 37, 37, 0.3) 100%);
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.widget-games a .widget-block {
  position: relative;
  z-index: 3;
  display: table;
  width: 100%;
  height: 100%;
}

.widget-games a .widget-block .count {
  display: table-cell;
  vertical-align: middle;
  font-weight: 700;
  float: left;
  font-size: 27px;
  color: rgba(255, 255, 255, 0.5);
}

.widget-games a .widget-block .description {
  display: table-cell;
  vertical-align: middle;
  padding-left: 14px;
  text-align: left;
  width: 95%;
}

.widget-games a .widget-block h5 {
  line-height: 22px;
  color: #fff;
  margin-bottom: 0;
  margin-top: -5px;
}

.widget-games a .widget-block .date {
  font-weight: 500;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
}

.widget-videos .widget-list li {
  margin-bottom: 20px;
}

.widget-videos .widget-list h4 {
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 0;
}

.widget-videos > .widget-block {
  padding-bottom: 25px;
}

.widget-videos span {
  margin-top: 3px;
  font-size: 14px;
  display: inline-block;
}

.widget-videos span i {
  margin-right: 3px;
}

.widget-videos span:after {
  color: #9c9c9c;
  content: "\002022";
  margin: 0 3px 0 8px;
  display: inline-block;
}

.widget-videos span:last-child:after {
  display: none;
}

.widget-videos p {
  font-size: 14px;
  margin-bottom: 0;
}

.widget-videos .widget-img {
  margin-bottom: 15px;
  position: relative;
}

.widget-videos .widget-img:hover:after {
  width: 100%;
}

.widget-videos .widget-img:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  z-index: 3;
  background: red;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.widget-videos .widget-img span {
  position: absolute;
  bottom: 10px;
  right: 10px;
  left: auto;
  display: inline-block;
  background-color: rgba(25, 25, 25, 0.9);
  border-radius: 3px;
  font-size: 12px;
  padding: 2px 7px;
  color: #fff;
}

.widget-share .widget-block {
  margin-top: 2px;
}

.widget-share .widget-block a {
  margin-bottom: 5px;
  margin-left: 3px;
}

.widget-share .widget-block span {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  padding: 7px 8px;
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
}

.widget-share .widget-block span:after, .widget-share .widget-block span:before {
  top: 100%;
  left: 18px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
}

.widget-share .widget-block span:after {
  border-top-color: #fff;
  border-width: 5px;
  margin-left: -5px;
}

.widget-share .widget-block span:before {
  border-top-color: #e6e6e6;
  border-width: 7px;
  margin-left: -7px;
}

.widget-newsletter .widget-block {
  padding: 60px 40px;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.widget-newsletter .widget-block .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: .7;
  z-index: 2;
  background-color: #2d2d2d;
}

.widget-newsletter .widget-block form {
  position: relative;
  z-index: 3;
}

.widget-newsletter .widget-block form .form-control {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.widget-newsletter .widget-block form .form-control:active, .widget-newsletter .widget-block form .form-control:focus {
  color: #fff;
}

.widget-newsletter .widget-block form .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.widget-newsletter .widget-block form p {
  color: white;
  line-height: 20px;
  padding: 0 0 20px 0;
  margin-bottom: 10px;
  text-align: center;
}

.widget-tags .post-tags a {
  margin-bottom: 5px;
}

.widget-about {
  margin-top: 40px;
}

.widget-about p {
  font-size: 15px;
}

.widget-about ul li {
  font-size: 14px;
  margin-bottom: 5px;
}

.widget-about ul li i {
  width: 20px;
  margin-right: 3px;
  text-align: center;
}

.widget-friends ul {
  margin: 0 -2px;
}

.widget-friends ul::after {
  display: block;
  content: "";
  clear: both;
}

.widget-friends ul li {
  width: 25%;
  float: left;
  padding: 0 2px;
  margin-bottom: 3px;
}

.widget-friends a {
  display: block;
}

.widget-friends img {
  border-radius: 3px;
}

.widget-forums ul li {
  border-bottom: 1px solid #e8e8e8;
  padding: 12px 10px;
  display: table;
  width: 100%;
}

.widget-forums ul li:nth-child(even) {
  background-color: #f5f5f5;
}

.widget-forums ul li:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

.widget-forums ul li > div {
  vertical-align: top;
  display: table-cell;
}

.widget-forums ul li .forum-icon {
  width: 30px;
}

.widget-forums ul li .forum-icon i {
  color: #636363;
  font-size: 18px;
}

.widget-forums ul li .forum-title h5 {
  font-size: 14px;
  margin-bottom: 0;
}

.widget-forums ul li .forum-title span {
  font-size: 13px;
}

.widget-forums ul li .forum-title span i {
  margin-right: 3px;
}

.widget-images ul {
  margin: 0 -2px;
}

.widget-images ul::after {
  display: block;
  content: "";
  clear: both;
}

.widget-images ul li {
  width: 33.33333%;
  float: left;
  padding: 0 2px;
  margin-bottom: 5px;
}

.widget-images img {
  border-radius: 6px;
}

.note-editor {
  border-color: #dcdcdc;
  width: 100%;
  display: block;
  border-radius: 3px;
}

.note-editor .dropdown-menu i {
  min-width: inherit;
}

.note-editor .btn i {
  margin: 0;
}

.note-editor .btn.btn-light {
  border-color: #dcdcdc;
  color: #636363;
}

.note-editor.note-frame {
  border-color: #dcdcdc;
}

.note-editor.note-frame .note-editing-area .note-editable {
  padding: 20px;
  color: #676767;
  font-size: 16px;
  font-family: Arial;
}

.note-editor .card-header {
  border-color: #dcdcdc;
  background-color: #f5f5f5;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.note-editor .card-block p {
  font-size: inherit;
}

.note-editor .dropdown-menu .dropdown-item i {
  display: none;
}

.note-editor .modal-header {
  display: block;
}

.note-popover .popover-content .note-para .dropdown-menu, .card-header.note-toolbar .note-para .dropdown-menu {
  min-width: 230px;
}

.social-share {
  margin-left: auto;
}

.social-share span {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  padding: 0 8px;
  margin-left: 5px;
  margin-right: 3px;
  line-height: 33px;
  font-size: 15px;
  position: relative;
  display: inline-block;
  top: -2px;
}

.social-share span:after, .social-share span:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
}

.social-share span:after {
  border-right-color: #fff;
  border-width: 5px;
  margin-top: -5px;
}

.social-share span:before {
  border-right-color: #e6e6e6;
  border-width: 7px;
  margin-top: -7px;
}

.chart {
  position: relative;
  display: inline-block;
  width: 110px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.easypiechart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 110px;
  line-height: 110px;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
}

.easypiechart canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.coming-soon {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  position: relative;
  z-index: 3;
}

.coming-soon h2 {
  color: #fff;
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 20px 30px;
  display: inline-block;
  border-radius: 4px;
}

.coming-soon p {
  color: rgba(255, 255, 255, 0.7);
}

.countdown {
  margin-bottom: 50px;
}

.countdown #clock > div {
  display: inline-block;
  margin: 0 5px;
  background-color: #222;
  padding: 20px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.countdown span {
  display: block;
  font-size: 36px;
  color: #fff;
  font-weight: 600;
}

.stream-alert {
  position: absolute;
  bottom: 40px;
  right: 30px;
  z-index: 3;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}

.stream-alert span {
  background-color: #ef284d;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  z-index: 4;
  top: 1px;
  right: 3px;
  border: 2px solid #222;
  -webkit-animation-name: bounceIn;
          animation-name: bounceIn;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.stream-alert p {
  position: absolute;
  top: -50px;
  right: 0;
  background-color: #ef284d;
  color: #fff;
  white-space: nowrap;
  font-size: 15px;
  padding: 5px 10px;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  -webkit-animation-name: fadeInDown;
          animation-name: fadeInDown;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  animation-fill-mode: both;
}

.stream-alert p:after {
  top: 100%;
  right: 6px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  border-color: rgba(239, 40, 77, 0);
  border-top-color: #ef284d;
  border-width: 7px;
  margin-left: -7px;
}

.stream-alert img {
  width: 64px;
  height: 64px;
  border: 3px solid #222;
  border-radius: 50%;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  -webkit-animation-name: bounceIn;
          animation-name: bounceIn;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.bubble-alert {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 3;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  display: none;
}

.bubble-alert .bubble-box {
  text-align: left;
  position: absolute;
  right: 0;
  bottom: 80px;
  width: 210px;
  z-index: 3;
  border-radius: 6px;
  padding: 0 20px 15px 20px;
  -webkit-box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.bubble-alert .bubble-box:after, .bubble-alert .bubble-box:before {
  top: 100%;
  right: 20px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
}

.bubble-alert .bubble-box:after {
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #fff;
  border-width: 8px;
  margin-left: -8px;
}

.bubble-alert .bubble-box:before {
  right: 19px;
  border-color: transparent;
  border-top-color: #d6d6d6;
  border-width: 9px;
  margin-left: -9px;
}

.bubble-alert .bubble-box i {
  position: absolute;
  top: 13px;
  right: 13px;
  font-size: 14px;
  z-index: 5;
}

.bubble-alert .bubble-box i:hover {
  color: #000;
}

.bubble-alert h5 {
  font-size: 14px;
  font-weight: 600;
  display: block;
  border-bottom: 1px solid #e6e6e6;
  padding: 13px 20px 12px 20px;
  margin: 0 -20px 12px -20px;
  background-color: #f1f1f1;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.bubble-alert p {
  color: #5f5f5f;
  font-size: 14px;
  margin-bottom: 10px;
}

.bubble-alert p b {
  display: inline-block;
}

.bubble-alert .yakuthemes {
  width: 60px;
  height: 60px;
  background-color: #454dbd;
  border-radius: 50%;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
}

.bubble-alert .bubble-bg {
  position: absolute;
  top: -120px;
  right: -5px;
  width: 280px;
  z-index: 4;
}

.bubble-alert:hover {
  color: inherit;
}

.select2-container--default .select2-selection--single {
  padding: 8px 15px;
  color: #878787;
  height: auto;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: inherit;
  line-height: inherit;
  padding: 0;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 100%;
  right: 8px;
}

.select2-container--default .select2-selection--single .select2-search--dropdown .select2-search__field {
  border-radius: 4px;
  outline: 0;
  font-size: 0.875rem;
  font-family: "Open Sans", sans-serif;
  padding: 6px 10px;
  color: #666;
}

.select2-container--default .select2-selection--single .select2-search--dropdown .select2-search__field:focus {
  border-color: #639ce6;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #2575dc;
}

.select2-container--default .select2-results__group {
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 500;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding: 4px 20px;
  font-size: 14px;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #639ce6;
}

.modal-body .select2-container {
  width: 100% !important;
  overflow-x: hidden;
  overflow-y: hidden;
}

.modal-body .select2-container--default .select2-selection--single {
  background-color: #fafafa;
}

.select2-results__option {
  padding: 6px 13px;
  font-size: 14px;
  margin: 0 -1px;
}

.select2-container--default .select2-results > .select2-results__options {
  overflow-x: hidden;
}

.select2-search--dropdown {
  padding: 5px 10px;
}

.select2-container--default .select2-search--dropdown .select2-search__field,
.select2-dropdown,
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
  border-color: #e2e2e2;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #fafafa;
  margin: 0;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #2575dc;
  margin: 0 -1px;
}

.select2-selection {
  outline: 0 !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #fff;
  margin-right: 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #fff;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #3b83e0;
  color: #fff;
  border-color: #3b83e0;
  font-size: 14px;
}

.flatpickr-calendar {
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 1px 3px rgba(0, 0, 0, 0.08);
          box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 1px 3px rgba(0, 0, 0, 0.08);
}

.flatpickr-day.today {
  background-color: #959ea9;
  color: #fff;
  border-color: #959ea9;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background-color: #2575dc;
  border-color: #2575dc;
  color: #fff;
}

.flatpickr-input[readonly] {
  background-color: #fff;
}

.fileinput {
  min-width: 330px;
}

.fileinput .form-control {
  padding-top: 0;
  padding-bottom: 0;
}

.fileinput .btn {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.g-recaptcha-outer {
  height: 76px;
  width: 300px;
  border-radius: 4px;
  background: #f9f9f9;
  border: 1px solid #e2e2e2;
  overflow: hidden;
}

.g-recaptcha-outer .g-recaptcha {
  position: relative;
  left: -2px;
  top: -1px;
}

.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.animated.fast {
  -webkit-animation-duration: .5s !important;
          animation-duration: .5s !important;
}

.animate1 {
  -webkit-animation-delay: .2s;
          animation-delay: .2s;
}

.animate2 {
  -webkit-animation-delay: .4s;
          animation-delay: .4s;
}

.animate3 {
  -webkit-animation-delay: .6s;
          animation-delay: .6s;
}

.animate4 {
  -webkit-animation-delay: .8s;
          animation-delay: .8s;
}

.animate5 {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.animate6 {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}

.animate7 {
  -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s;
}

.animate8 {
  -webkit-animation-delay: 1.6s;
          animation-delay: 1.6s;
}

.animate9 {
  -webkit-animation-delay: 1.8s;
          animation-delay: 1.8s;
}

.animate10 {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

.btn-demo .btn {
  margin-bottom: 5px;
  display: inline-block;
}

.blank {
  min-height: 600px;
}

.demo-popover {
  position: relative;
  padding-top: 10px;
}

.demo-popover .popover {
  position: relative;
  width: 100%;
  z-index: 3;
  margin-top: 0;
  display: inline-block;
}

.demo-popover .popover-body {
  color: #6b6b6b;
}

.demo-popover .bs-popover-left .arrow,
.demo-popover .bs-popover-right .arrow {
  top: 50%;
}

.demo-popover .bs-popover-top .arrow,
.demo-popover .bs-popover-bottom .arrow {
  left: 50%;
}

.demo-tooltip {
  position: relative;
}

.demo-tooltip .tooltip {
  position: relative;
  display: inline-block;
  position: relative;
  opacity: 1;
}

.demo-tooltip .bs-tooltip-left .arrow,
.demo-tooltip .bs-tooltip-right .arrow {
  top: 45%;
}

.demo-tooltip .bs-tooltip-top .arrow,
.demo-tooltip .bs-tooltip-bottom .arrow {
  left: 50%;
}

.icons {
  color: #4c4c4c;
  margin: 0 -10px;
  padding-top: 15px;
}

.icons i {
  font-size: 20px;
  width: 46.95px;
  height: 40px;
  cursor: pointer;
  text-align: center;
}

.icons h5 {
  margin-left: 13px;
  margin-top: 30px;
  margin-bottom: 25px;
}

.icons h5:first-child {
  margin-top: 0;
}

.demo-dropdown .dropdown-menu {
  position: static;
  display: block;
  float: left;
  margin-right: 30px;
}

.demo-modal {
  padding: 15px 0 70px 0;
}

.demo-modal .modal {
  overflow: inherit;
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
  opacity: 1;
}

.demo-modal .modal-dialog {
  margin: 0;
  min-width: 500px;
  -webkit-transform: none !important;
          transform: none !important;
}

.anchor {
  padding-top: 80px;
  margin-top: -80px;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.float-none {
  float: none !important;
}

.relative {
  position: relative;
}

.row-5 {
  margin: 0 -5px;
}

.row-5 > div {
  padding: 0 5px;
}

.row-3 {
  margin: 0 -3px;
}

.row-3 > div {
  padding: 0 3px;
}

.full-width {
  width: 100% !important;
}

.font-weight-300 {
  font-weight: 300;
}

.bg-primary {
  color: #fff;
  background-color: #2575dc !important;
}

.bg-danger {
  color: #fff;
  background-color: #e74c3c !important;
}

.bg-warning {
  color: #fff;
  background-color: #FF8E2B !important;
}

.bg-info {
  color: #fff;
  background-color: #52BAFF !important;
}

.bg-success {
  color: #fff;
  background-color: #0E9A49 !important;
}

.bg-inverse {
  color: #fff;
  background-color: #222 !important;
}

.bg-secondary {
  background-color: #fff !important;
}

.mx-auto {
  margin: 0 auto;
  float: none;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-muted {
  color: #969696 !important;
}

.text-primary {
  color: #1a63c1 !important;
}

.text-secondary {
  color: #dc2745 !important;
}

.text-success {
  color: #419e1c !important;
}

.text-warning {
  color: #f37e17 !important;
}

.text-danger {
  color: #de5142 !important;
}

.text-info {
  color: #2688ca !important;
}

.text-white {
  color: #fff !important;
}

.text-dark {
  color: rgba(0, 0, 0, 0.5) !important;
}

/* Reset */
.display-none {
  display: none !important;
}

.display-block {
  display: block !important;
}

.display-inline {
  display: inline !important;
}

.display-inline-block {
  display: inline-block !important;
}

.no-border {
  border: 0 !important;
}

.no-border-top {
  border-top: 0 !important;
}

.no-border-bottom {
  border-bottom: 0 !important;
}

.no-border-left {
  border-left: 0 !important;
}

.no-border-right {
  border-right: 0 !important;
}

.no-shadow {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.no-radius {
  border-radius: 0 !important;
}

.nowrap {
  white-space: nowrap !important;
}

.m-a-100 {
  margin: 100px !important;
}

.m-t-100 {
  margin-top: 100px !important;
}

.m-r-100 {
  margin-right: 100px !important;
}

.m-b-100 {
  margin-bottom: 100px !important;
}

.m-l-100 {
  margin-left: 100px !important;
}

.m-x-100 {
  margin-right: 100px !important;
  margin-left: 100px !important;
}

.m-y-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.p-a-100 {
  padding: 100px !important;
}

.p-t-100 {
  padding-top: 100px !important;
}

.p-r-100 {
  padding-right: 100px !important;
}

.p-b-100 {
  padding-bottom: 100px !important;
}

.p-l-100 {
  padding-left: 100px !important;
}

.p-x-100 {
  padding-right: 100px !important;
  padding-left: 100px !important;
}

.p-y-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.m-a-95 {
  margin: 95px !important;
}

.m-t-95 {
  margin-top: 95px !important;
}

.m-r-95 {
  margin-right: 95px !important;
}

.m-b-95 {
  margin-bottom: 95px !important;
}

.m-l-95 {
  margin-left: 95px !important;
}

.m-x-95 {
  margin-right: 95px !important;
  margin-left: 95px !important;
}

.m-y-95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

.p-a-95 {
  padding: 95px !important;
}

.p-t-95 {
  padding-top: 95px !important;
}

.p-r-95 {
  padding-right: 95px !important;
}

.p-b-95 {
  padding-bottom: 95px !important;
}

.p-l-95 {
  padding-left: 95px !important;
}

.p-x-95 {
  padding-right: 95px !important;
  padding-left: 95px !important;
}

.p-y-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.m-a-90 {
  margin: 90px !important;
}

.m-t-90 {
  margin-top: 90px !important;
}

.m-r-90 {
  margin-right: 90px !important;
}

.m-b-90 {
  margin-bottom: 90px !important;
}

.m-l-90 {
  margin-left: 90px !important;
}

.m-x-90 {
  margin-right: 90px !important;
  margin-left: 90px !important;
}

.m-y-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.p-a-90 {
  padding: 90px !important;
}

.p-t-90 {
  padding-top: 90px !important;
}

.p-r-90 {
  padding-right: 90px !important;
}

.p-b-90 {
  padding-bottom: 90px !important;
}

.p-l-90 {
  padding-left: 90px !important;
}

.p-x-90 {
  padding-right: 90px !important;
  padding-left: 90px !important;
}

.p-y-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.m-a-85 {
  margin: 85px !important;
}

.m-t-85 {
  margin-top: 85px !important;
}

.m-r-85 {
  margin-right: 85px !important;
}

.m-b-85 {
  margin-bottom: 85px !important;
}

.m-l-85 {
  margin-left: 85px !important;
}

.m-x-85 {
  margin-right: 85px !important;
  margin-left: 85px !important;
}

.m-y-85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

.p-a-85 {
  padding: 85px !important;
}

.p-t-85 {
  padding-top: 85px !important;
}

.p-r-85 {
  padding-right: 85px !important;
}

.p-b-85 {
  padding-bottom: 85px !important;
}

.p-l-85 {
  padding-left: 85px !important;
}

.p-x-85 {
  padding-right: 85px !important;
  padding-left: 85px !important;
}

.p-y-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.m-a-80 {
  margin: 80px !important;
}

.m-t-80 {
  margin-top: 80px !important;
}

.m-r-80 {
  margin-right: 80px !important;
}

.m-b-80 {
  margin-bottom: 80px !important;
}

.m-l-80 {
  margin-left: 80px !important;
}

.m-x-80 {
  margin-right: 80px !important;
  margin-left: 80px !important;
}

.m-y-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.p-a-80 {
  padding: 80px !important;
}

.p-t-80 {
  padding-top: 80px !important;
}

.p-r-80 {
  padding-right: 80px !important;
}

.p-b-80 {
  padding-bottom: 80px !important;
}

.p-l-80 {
  padding-left: 80px !important;
}

.p-x-80 {
  padding-right: 80px !important;
  padding-left: 80px !important;
}

.p-y-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.m-a-75 {
  margin: 75px !important;
}

.m-t-75 {
  margin-top: 75px !important;
}

.m-r-75 {
  margin-right: 75px !important;
}

.m-b-75 {
  margin-bottom: 75px !important;
}

.m-l-75 {
  margin-left: 75px !important;
}

.m-x-75 {
  margin-right: 75px !important;
  margin-left: 75px !important;
}

.m-y-75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

.p-a-75 {
  padding: 75px !important;
}

.p-t-75 {
  padding-top: 75px !important;
}

.p-r-75 {
  padding-right: 75px !important;
}

.p-b-75 {
  padding-bottom: 75px !important;
}

.p-l-75 {
  padding-left: 75px !important;
}

.p-x-75 {
  padding-right: 75px !important;
  padding-left: 75px !important;
}

.p-y-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.m-a-70 {
  margin: 70px !important;
}

.m-t-70 {
  margin-top: 70px !important;
}

.m-r-70 {
  margin-right: 70px !important;
}

.m-b-70 {
  margin-bottom: 70px !important;
}

.m-l-70 {
  margin-left: 70px !important;
}

.m-x-70 {
  margin-right: 70px !important;
  margin-left: 70px !important;
}

.m-y-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.p-a-70 {
  padding: 70px !important;
}

.p-t-70 {
  padding-top: 70px !important;
}

.p-r-70 {
  padding-right: 70px !important;
}

.p-b-70 {
  padding-bottom: 70px !important;
}

.p-l-70 {
  padding-left: 70px !important;
}

.p-x-70 {
  padding-right: 70px !important;
  padding-left: 70px !important;
}

.p-y-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.m-a-65 {
  margin: 65px !important;
}

.m-t-65 {
  margin-top: 65px !important;
}

.m-r-65 {
  margin-right: 65px !important;
}

.m-b-65 {
  margin-bottom: 65px !important;
}

.m-l-65 {
  margin-left: 65px !important;
}

.m-x-65 {
  margin-right: 65px !important;
  margin-left: 65px !important;
}

.m-y-65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

.p-a-65 {
  padding: 65px !important;
}

.p-t-65 {
  padding-top: 65px !important;
}

.p-r-65 {
  padding-right: 65px !important;
}

.p-b-65 {
  padding-bottom: 65px !important;
}

.p-l-65 {
  padding-left: 65px !important;
}

.p-x-65 {
  padding-right: 65px !important;
  padding-left: 65px !important;
}

.p-y-65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

.m-a-60 {
  margin: 60px !important;
}

.m-t-60 {
  margin-top: 60px !important;
}

.m-r-60 {
  margin-right: 60px !important;
}

.m-b-60 {
  margin-bottom: 60px !important;
}

.m-l-60 {
  margin-left: 60px !important;
}

.m-x-60 {
  margin-right: 60px !important;
  margin-left: 60px !important;
}

.m-y-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.p-a-60 {
  padding: 60px !important;
}

.p-t-60 {
  padding-top: 60px !important;
}

.p-r-60 {
  padding-right: 60px !important;
}

.p-b-60 {
  padding-bottom: 60px !important;
}

.p-l-60 {
  padding-left: 60px !important;
}

.p-x-60 {
  padding-right: 60px !important;
  padding-left: 60px !important;
}

.p-y-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.m-a-55 {
  margin: 55px !important;
}

.m-t-55 {
  margin-top: 55px !important;
}

.m-r-55 {
  margin-right: 55px !important;
}

.m-b-55 {
  margin-bottom: 55px !important;
}

.m-l-55 {
  margin-left: 55px !important;
}

.m-x-55 {
  margin-right: 55px !important;
  margin-left: 55px !important;
}

.m-y-55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

.p-a-55 {
  padding: 55px !important;
}

.p-t-55 {
  padding-top: 55px !important;
}

.p-r-55 {
  padding-right: 55px !important;
}

.p-b-55 {
  padding-bottom: 55px !important;
}

.p-l-55 {
  padding-left: 55px !important;
}

.p-x-55 {
  padding-right: 55px !important;
  padding-left: 55px !important;
}

.p-y-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.m-a-50 {
  margin: 50px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.m-r-50 {
  margin-right: 50px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.m-l-50 {
  margin-left: 50px !important;
}

.m-x-50 {
  margin-right: 50px !important;
  margin-left: 50px !important;
}

.m-y-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.p-a-50 {
  padding: 50px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

.p-r-50 {
  padding-right: 50px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

.p-l-50 {
  padding-left: 50px !important;
}

.p-x-50 {
  padding-right: 50px !important;
  padding-left: 50px !important;
}

.p-y-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.m-a-45 {
  margin: 45px !important;
}

.m-t-45 {
  margin-top: 45px !important;
}

.m-r-45 {
  margin-right: 45px !important;
}

.m-b-45 {
  margin-bottom: 45px !important;
}

.m-l-45 {
  margin-left: 45px !important;
}

.m-x-45 {
  margin-right: 45px !important;
  margin-left: 45px !important;
}

.m-y-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.p-a-45 {
  padding: 45px !important;
}

.p-t-45 {
  padding-top: 45px !important;
}

.p-r-45 {
  padding-right: 45px !important;
}

.p-b-45 {
  padding-bottom: 45px !important;
}

.p-l-45 {
  padding-left: 45px !important;
}

.p-x-45 {
  padding-right: 45px !important;
  padding-left: 45px !important;
}

.p-y-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.m-a-40 {
  margin: 40px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-r-40 {
  margin-right: 40px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.m-x-40 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}

.m-y-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.p-a-40 {
  padding: 40px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-r-40 {
  padding-right: 40px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.p-l-40 {
  padding-left: 40px !important;
}

.p-x-40 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.p-y-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.m-a-35 {
  margin: 35px !important;
}

.m-t-35 {
  margin-top: 35px !important;
}

.m-r-35 {
  margin-right: 35px !important;
}

.m-b-35 {
  margin-bottom: 35px !important;
}

.m-l-35 {
  margin-left: 35px !important;
}

.m-x-35 {
  margin-right: 35px !important;
  margin-left: 35px !important;
}

.m-y-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.p-a-35 {
  padding: 35px !important;
}

.p-t-35 {
  padding-top: 35px !important;
}

.p-r-35 {
  padding-right: 35px !important;
}

.p-b-35 {
  padding-bottom: 35px !important;
}

.p-l-35 {
  padding-left: 35px !important;
}

.p-x-35 {
  padding-right: 35px !important;
  padding-left: 35px !important;
}

.p-y-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.m-a-30 {
  margin: 30px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-r-30 {
  margin-right: 30px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-x-30 {
  margin-right: 30px !important;
  margin-left: 30px !important;
}

.m-y-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.p-a-30 {
  padding: 30px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.p-x-30 {
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.p-y-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.m-a-25 {
  margin: 25px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-r-25 {
  margin-right: 25px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.m-x-25 {
  margin-right: 25px !important;
  margin-left: 25px !important;
}

.m-y-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.p-a-25 {
  padding: 25px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-r-25 {
  padding-right: 25px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}

.p-x-25 {
  padding-right: 25px !important;
  padding-left: 25px !important;
}

.p-y-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.m-a-20 {
  margin: 20px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-x-20 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.m-y-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.p-a-20 {
  padding: 20px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-x-20 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.p-y-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.m-a-15 {
  margin: 15px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-x-15 {
  margin-right: 15px !important;
  margin-left: 15px !important;
}

.m-y-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.p-a-15 {
  padding: 15px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-l-15 {
  padding-left: 15px !important;
}

.p-x-15 {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.p-y-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.m-a-10 {
  margin: 10px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-x-10 {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.m-y-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.p-a-10 {
  padding: 10px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-x-10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.p-y-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.m-a-5 {
  margin: 5px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-x-5 {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.m-y-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.p-a-5 {
  padding: 5px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.p-x-5 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.p-y-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.m-a-0 {
  margin: 0px !important;
}

.m-t-0 {
  margin-top: 0px !important;
}

.m-r-0 {
  margin-right: 0px !important;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.m-l-0 {
  margin-left: 0px !important;
}

.m-x-0 {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.m-y-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.p-a-0 {
  padding: 0px !important;
}

.p-t-0 {
  padding-top: 0px !important;
}

.p-r-0 {
  padding-right: 0px !important;
}

.p-b-0 {
  padding-bottom: 0px !important;
}

.p-l-0 {
  padding-left: 0px !important;
}

.p-x-0 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.p-y-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

@media (max-width: 1600px) {
  #header .nav > ul > li.mega-menu > ul {
    background-image: none !important;
  }
  .lightbox .lightbox-block .lightbox-video {
    max-width: 1200px;
  }
}

@media (max-width: 1300px) {
  .owl-list .owl-nav {
    display: none;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

@media (max-width: 1200px) {
  .post-timeline .post .post-timeline-dot {
    right: -33px;
  }
  .post-timeline .post:nth-child(even) .post-timeline-dot {
    left: -34px;
  }
  .post-actions .post-tags {
    max-width: 60%;
  }
  .forum .forum-head > div:last-child {
    width: 30%;
  }
  .toolbar.toolbar-profile .profile-avatar {
    padding: 5px;
  }
  .toolbar.toolbar-profile .profile-avatar img {
    width: 160px;
    height: 160px;
  }
  .toolbar.toolbar-profile ul.toolbar-nav {
    padding-left: 160px;
    float: right;
  }
  .hero-profile .hero-block {
    padding-left: 190px;
  }
  .lightbox .lightbox-block .lightbox-video {
    max-width: 1000px;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .container {
    max-width: 1024px;
  }
}

@media (max-width: 1024px) {
  #header .dropdown-profile a:after,
  #header .dropdown-profile a > span {
    display: none;
  }
  #header .dropdown-profile img {
    margin: 0;
    width: 32px;
    height: 32px;
  }
}

@media (max-width: 992px) {
  body {
    overflow-x: hidden;
  }
  .bubble-alert {
    display: none !important;
  }
  .container {
    max-width: 100% !important;
  }
  #header .navbar-left .nav {
    display: none;
  }
  .navbar-backdrop {
    margin: 0 -5px;
  }
  .navbar-toggle {
    float: left;
    display: inline-block;
    color: #fff !important;
    font-size: 16px;
    padding: 4px 8px;
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.04);
    border-radius: 4px;
    margin-right: 15px;
  }
  .navbar-toggle:focus, .navbar-toggle:active, .navbar-toggle:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.03);
  }
  .nav-responsive {
    display: none;
    position: fixed;
    z-index: 9999;
    top: 50px;
    padding-bottom: 50px;
    background-color: #242629;
    overflow-y: scroll;
    height: 100%;
    width: 100%;
  }
  .nav-responsive > ul {
    padding: 0;
  }
  .nav-responsive > ul > li a {
    display: block;
    font-weight: 500;
    font-size: 15px;
    padding: 5px 10px;
    color: #a7a7a7;
  }
  .nav-responsive > ul > li a:hover {
    background-color: #202225 !important;
    color: #fff;
  }
  .nav-responsive > ul > li > a {
    padding: 12px 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.03);
  }
  .nav-responsive > ul > li.has-dropdown > a:after {
    content: "\f078";
    font-family: "FontAwesome";
    font-size: 13px;
    float: right;
    margin-left: 5px;
  }
  .nav-responsive > ul > li > ul {
    display: none;
  }
  .nav-responsive > ul > li.open {
    background-color: #202225 !important;
  }
  .nav-responsive > ul > li.open > ul {
    display: block;
  }
  .nav-responsive > ul > li.open > a:focus,
  .nav-responsive > ul > li.open > a:hover,
  .nav-responsive > ul > li.open > a {
    color: #fff;
    background-color: #202225 !important;
    border-color: rgba(255, 255, 255, 0.05);
  }
  .nav-responsive > ul > li.mega-menu ul {
    background-image: none !important;
  }
  .nav-responsive > ul > li.mega-menu ul .title {
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
    display: block;
  }
  .nav-responsive > ul > li.mega-menu ul a {
    padding: 5px 0;
  }
  .nav-responsive > ul > li.mega-menu ul a i {
    width: 20px;
  }
  .nav-responsive > ul > li.mega-menu .col {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    max-width: 50%;
  }
  .nav-responsive > ul > li.mega-games li a {
    padding: 0;
  }
  .nav-responsive > ul > li.mega-games li h4 {
    margin: 0;
  }
  .nav-responsive > ul > li.mega-games li h4 a {
    padding: 5px 0;
  }
  .nav-responsive > ul > li.mega-games li h4 a:hover {
    background-color: transparent !important;
  }
  .nav-responsive > ul > li.mega-games li span:not(.badge) {
    font-size: 14px;
    display: block;
    margin-bottom: 20px;
    font-weight: 400;
  }
  .nav-responsive > ul > li.mega-games li .img {
    position: relative;
    margin-bottom: 5px;
  }
  .nav-responsive > ul > li.mega-games li .badge {
    position: absolute;
    bottom: 13px;
    left: 13px;
  }
  .nav-responsive > ul > li ul {
    padding: 0 0 10px 0;
  }
  .nav-responsive > ul > li ul li a {
    font-weight: 400;
    font-size: 14px;
  }
  .navbar-open {
    overflow: hidden;
  }
  .navbar-open .nav-responsive {
    display: block;
  }
  .navbar-search {
    padding: 5px 0 10px 0;
  }
  .navbar-search .container {
    padding: 0 10px;
  }
  section {
    padding: 20px 0;
  }
  section.bg-image-sm {
    padding: 50px 0;
  }
  section.bg-image:not(.bg-image-sm) {
    background-image: none;
    padding: 0 !important;
  }
  section.bg-image:not(.bg-image-sm) .overlay {
    display: none;
  }
  section.bg-image:not(.bg-image-sm) .container {
    padding: 0;
  }
  #header .logo {
    margin-left: 5px;
  }
  .owl-slide .carousel-caption .carousel-title {
    font-size: 30px;
  }
  .post .post-title {
    font-size: 22px;
  }
  .post .post-meta {
    margin-bottom: 15px;
  }
  .post .post-thumbnail {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .lightbox .lightbox-block .lightbox-video {
    max-width: 760px;
  }
  .breadcrumbs .container {
    padding: 0;
  }
  .post-actions {
    display: block;
  }
  .post-actions .post-tags {
    width: 100%;
    max-width: 100%;
    display: block;
  }
  .post-actions .social-share {
    margin-top: 20px;
    display: block;
    width: 100%;
  }
  .toolbar form {
    display: none;
  }
  .video-play h5 {
    left: 20px;
    top: auto;
    bottom: 20px;
    margin: 0;
  }
  .hero-block .hero-right {
    display: none;
  }
  .hero-game .hero-block {
    text-align: center;
  }
  .hero-game .hero-block .hero-left {
    float: none;
  }
  .hero-profile {
    min-height: 320px;
  }
  .hero-profile .hero-block {
    text-align: center;
    padding-left: 0;
  }
  .hero-profile .hero-block h5 {
    float: none;
    margin-bottom: 20px;
  }
  .hero-profile .hero-block .btn-add {
    float: none;
    display: none !important;
    margin-bottom: 15px;
  }
  .sidebar {
    border: 0;
    padding-left: 0px;
    margin-top: 40px;
  }
  .review {
    padding: 0 20px;
  }
  .review .review-bad,
  .review .review-good {
    margin-top: 30px;
    padding-left: 0;
  }
  .forum .forum-head > div:nth-child(1) {
    width: 5%;
  }
  .forum .forum-head > div:nth-child(3n), .forum .forum-head > div:nth-child(4n) {
    width: 10%;
  }
  .forum .forum-footer > div:last-child,
  .forum .forum-head > div:last-child {
    width: 15%;
    display: none;
  }
  .forum .forum-group .forum-row .forum-latest {
    display: none;
  }
  .toolbar.toolbar-profile {
    background-color: transparent;
    border: 0;
    height: 0;
  }
  .toolbar.toolbar-profile .profile-avatar {
    margin-top: -300px;
    padding: 0;
    left: 0;
    right: 0;
    text-align: center;
    background-color: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .toolbar.toolbar-profile .profile-avatar a {
    background-color: #fff;
    padding: 4px;
    border-radius: 4px;
    display: inline-block;
  }
  .toolbar.toolbar-profile .profile-avatar img {
    width: 200px;
    height: 200px;
  }
  .toolbar.toolbar-profile ul.toolbar-nav {
    padding-left: 0;
    float: none;
  }
}

@media (max-width: 767px) {
  .owl-slide .carousel-caption {
    padding: 0 20px;
  }
  #header .logo,
  #header .navbar,
  #header {
    height: 50px;
  }
  #header .nav > ul > li > a {
    line-height: 50px;
  }
  .navbar-search-open .navbar-search {
    top: 50px;
  }
  #header .logo {
    padding: 12px 0;
  }
  #header .nav > ul > li > a > .badge {
    top: 5px;
  }
  #header .nav > ul > li > ul {
    top: 50px;
  }
  .fixed-header {
    padding-top: 50px;
  }
  #footer {
    padding: 5px;
  }
  #footer .footer-title {
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .hidden-xs {
    display: inline !important;
  }
  .pager {
    display: block;
    width: 100%;
  }
  .pager > div {
    display: block;
    width: 100%;
  }
  .pagination-results > span {
    display: none;
  }
  .post-columns {
    -webkit-column-count: 2;
            column-count: 2;
  }
  .post-timeline-date span {
    display: block;
  }
  .post-timeline {
    padding: 0 15px;
  }
  .post-timeline .post {
    width: 100%;
    margin: 0 0 30px 0 !important;
    float: none;
  }
  .post-timeline .post .post-timeline-dot {
    display: none;
  }
  .post-timeline .post:nth-child(even) {
    float: none;
    margin: 0 0 30px 0;
  }
  .post-timeline-date {
    padding: 13px 5px;
    margin-bottom: 5px;
  }
  .video-play h5 {
    display: none;
  }
  .forum-post .forum-attachment {
    float: none;
    display: block;
    margin-bottom: 30px;
    margin-right: 0;
  }
  .post-carousel .post-block {
    padding-left: 10px;
    padding-bottom: 10px;
  }
  .post-carousel .post-block .post-caption {
    padding: 15px 15px;
  }
  .post-carousel .post-block .post-title {
    font-size: 16px;
    line-height: 20px;
  }
  .lightbox .lightbox-block .lightbox-video {
    max-width: 500px;
  }
}

@media (max-width: 576px) {
  .card-lg .card-block {
    padding: 25px;
  }
  .promo > div {
    text-align: center;
    display: block;
  }
  .promo .btn {
    margin-top: 25px;
  }
  #header .container {
    padding: 0 13px;
  }
  .header-scroll #header {
    -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.6);
            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.6);
  }
  .owl-slide .carousel-caption .carousel-title {
    font-size: 34px;
    line-height: 1;
    margin-bottom: 15px;
  }
  .owl-slide .owl-nav {
    display: none;
  }
  .owl-carousel .owl-dots {
    bottom: 25px;
  }
  .owl-slide .carousel-caption p {
    font-size: 15px;
    line-height: 20px;
  }
  .owl-slide .carousel-caption .btn {
    margin-top: 17px;
  }
  .p-y-80 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .heading {
    max-width: 90%;
    margin-bottom: 30px;
  }
  .heading i {
    font-size: 26px;
    width: 55px;
    height: 55px;
    line-height: 50px;
    margin-bottom: 15px;
  }
  .heading h2 {
    font-size: 23px;
    margin-bottom: 10px;
  }
  .heading p {
    font-size: 15px;
  }
  #header .navbar-right.nav > ul > li > a {
    padding: 0 8px;
  }
  #header .logo {
    margin: 0 auto;
    float: none;
    position: absolute;
    left: 0;
    right: 0;
    display: inline-block;
    text-align: center;
  }
  #header .logo img {
    width: auto;
  }
  .nav-responsive > ul > li.mega-menu .col {
    max-width: 100%;
  }
  .hidden-xs {
    display: none !important;
  }
  .post .post-title {
    font-size: 20px;
    line-height: 24px;
  }
  .post-card {
    padding: 15px 15px 10px 15px;
  }
  .post .post-header > div:first-child {
    display: none;
  }
  .post .post-header > div:last-child {
    padding-left: 0;
  }
  .post .post-header > div:last-child .post-title {
    font-size: 20px;
    line-height: 24px;
  }
  .post.post-profile .post-header > div:last-child .post-title {
    font-size: 16px;
  }
  .post-columns {
    -webkit-column-count: 1;
            column-count: 1;
  }
  .video-play .video-play-icon i {
    width: 74px;
    height: 74px;
    line-height: 74px;
    font-size: 20px;
  }
  .toolbar-custom form {
    margin-bottom: 15px;
    padding-right: 0 !important;
  }
  .review .chart {
    margin-top: 10px;
  }
  .forum .forum-footer > div:nth-child(1), .forum .forum-footer > div:nth-child(4n),
  .forum .forum-head > div:nth-child(1),
  .forum .forum-head > div:nth-child(4n) {
    display: none;
  }
  .forum .forum-footer > div:nth-child(3n),
  .forum .forum-head > div:nth-child(3n),
  .forum .forum-group .forum-row > div:nth-child(3n) {
    text-align: right;
  }
  .forum .forum-group .forum-row > div:nth-child(1), .forum .forum-group .forum-row > div:nth-child(4n) {
    display: none;
  }
  .hero .hero-block {
    padding: 0 20px;
  }
  .hero .hero-block .hero-title {
    font-size: 26px;
  }
  .hero .hero-block p {
    font-size: 14px;
  }
  .hero-profile .hero-block h5 {
    margin-bottom: 30px;
  }
  .post-carousel .post-block {
    padding: 0;
  }
  .post-carousel .post-block .post-caption {
    padding: 15px 15px;
    display: block;
    border: 0;
  }
  .post-carousel .post-block .post-title {
    font-size: 14px;
    margin: 0;
    line-height: 20px;
    font-weight: 500;
    color: #a7a7a7;
  }
  .post-carousel .post-block .post-meta {
    display: none;
  }
  .lightbox .lightbox-block .lightbox-video {
    max-width: 400px;
  }
  .dropdown-notification {
    display: none !important;
  }
}

@media (max-width: 500px) {
  .post-md > div {
    display: block;
  }
  .post-md .post-thumbnail {
    width: 100%;
  }
  .post-md .post-block {
    padding-left: 0;
  }
  .post-review > div {
    display: block;
  }
  .post-review .post-thumbnail {
    width: 100%;
  }
  .post-review .post-thumbnail .badge {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    top: 20px;
    right: 20px;
    left: auto;
  }
  .post-review .post-block {
    padding-left: 0;
  }
  .toolbar {
    height: auto;
  }
  .toolbar h5 {
    font-size: 16px;
    padding: 0;
  }
  .forum-post .forum-header > div:last-child,
  .forum-reply .forum-header > div:last-child {
    display: none;
  }
  .hero .hero-block .btn {
    display: block;
    margin-left: 0 !important;
  }
}

@media (max-width: 400px) {
  .comments > ul li .comment > div.comment-avatar {
    width: 40px;
  }
  .comments > ul li .comment > div.comment-post {
    padding-left: 15px;
  }
  .comments > ul li .comment > div.comment-avatar .badge {
    display: none;
  }
  .comments > ul li > ul {
    padding-left: 0px;
  }
  .lightbox .lightbox-block .lightbox-video {
    max-width: 300px;
  }
}
