* {	border: 0;  text-decoration: none; cellpadding: 0px; cellspacing: 0px;

}
body{
   font-family: 'Montserrat',Tahoma, Verdana, Arial;
}
body {
    size: 8pt;
    font-size: 8pt;
    border-collapse: collapse;
    text-decoration: none;
    cellpadding: 0px; cellspacing: 0px; margin:0;padding:0;position:relative;

}
.fontNormal{
    font-family: Tahoma, Verdana, Arial;
}
.fontElectro{
    font-family: 'Electrolize', sans-serif;
}
.fontCards{
    font-family: 'Pirata One', cursive;
}
.fontFake{
    font-family: 'Montserrat',Tahoma, Verdana, Arial;
}
.fontTeacher{
    font-family: 'Gochi Hand', cursive;
}
.fontWalk{
    font-family: 'VT323', monospace;
}
a2 { font-size: 8pt; color: green; font-weight: bold; margin: 0px; }
a1 { font-size: 8pt; color: red; font-weight: bold; margin: 0px; }
/* FORMS */
.formTextarea { border: 1px solid #626262; background: #414346; font-size: 10pt; color: #979797; }
.formText { text-align: center; }
.formText.blue{
    background-color: white;
    font-size: 34px;
    height: auto;
    color: #135F7D;
    width: 235px;
    border: 2px solid #135F7D;
    border-radius: 8px;
}
.formSubmit{
    display: inline-block;
    background-image: url(/gfx/place/rooms/collect/but.png);
    background-repeat: no-repeat;
    width: 154px;
    height: 48px;
    position: relative;
    cursor:pointer;
    &.big{
        width: 226px;
        height: 95px;
        background-image: url(/gfx/place/rooms/nonegirls/but.png);
    }
    &.withIcon{
        width: 212px;
        height: 56px;
        background-image: url(/gfx/place/dailyquest/button/back.png);
        &.unable{
            background-image: url(/gfx/place/dailyquest/button/back_un.png);
        }
        .icon{
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            width: 59px;
            height: 59px;
            background-position: left center;
            left: -9px;
            top: -2px;
        }
        >.in{
            left: 51px;
            right: 10px;
            top: 7px;
            bottom: 8px;
        }
    }
    &:hover{
        background-position: left bottom;
    }
    &:active{
        background-position: left center;
    }
    &.unable:not(.premium){
        background-image: url(/gfx/place/rooms/collect/butunable.png);
    }
    >.in{
        .flexColumnCenterAround;
        color: white;
        font-size: 22px;
        position: absolute;
        left:3px;
        right: 3px;
        top:3px;
        bottom:3px;
        .myTextBorder3p(#637D15)
    }
}
.formSubmit.long{
    background:none;
    width: auto;
    >.in{
        position: relative;
        top:auto;
        left: auto;
        right: auto;
        bottom: auto;
        height: 48px;
        padding: 0px 20px;
        min-width: 105px;
        white-space: nowrap;
    }

    &:hover>.up{
        background-position: left bottom;
    }
    &:active>.up{
        background-position: left center;
    }
    .up{
        width: 134px;
        height: 48px;
        position: absolute;
        left:0px;
        top:0px;
        background-repeat: no-repeat;
        background-image: url(/gfx/place/explainenergy/but_left_right.png);
    }
    .down{
        width: 24px;
        height: 48px;
        position: absolute;
        right:0px;
        top:0px;
        background-repeat: no-repeat;
        background-position: right top;
        background-image: url(/gfx/place/explainenergy/but_left_right.png);
    }
    &:hover>.down{
        background-position: right bottom;
    }
    &:active>.down{
        background-position: right center;
    }
    >.ext{
        height: 48px;
        position: absolute;
        background-repeat: repeat-x;
        top:0px;
        left:20px;
        right: 20px;
        background-image: url(/gfx/place/explainenergy/but_ext.png);
    }
    &:hover>.ext{
        background-position: left bottom;
    }
    &:active>.ext{
        background-position: left center;
    }
    &.unable{
        .up{
            background-image: url(/gfx/place/explainenergy/but_left_right_un.png);
        }
        .down{
            background-image: url(/gfx/place/explainenergy/but_left_right_un.png);
        }
        >.ext{
            background-image: url(/gfx/place/explainenergy/but_ext_un.png);
        }
    }
    &.little{
        height: 41px;
        .up{
            height: 41px;
            width: 88px;
            background-image: url(/gfx/place/premiumshop/confirm/green_ext.png);
        }
        .down{
            height: 41px;
            width: 24px;
            background-image: url(/gfx/place/premiumshop/confirm/green_ext.png);
        }
        >.in{
            .myTextBorder3p(#378958);
            height: 41px;
        }
        >.ext{
            height: 41px;
            left:20px;
            right: 20px;
            background-image: url(/gfx/place/premiumshop/confirm/green_ext2.png);
        }
        &.little.premiumWidth{
            >.in{
                width: 121px;
            }
        }
    }
}

.formSubmit.premium{
    display: inline-block;
    background-image: url(/gfx/place/premiumshop/blue.png);
    width: 161px;
    height: 41px;
    position: relative;
    cursor:pointer;
    color:white;
    &:hover{
        background-position: left bottom;
    }
    &:active{
        background-position: left center;
    }
    &:after{
        content:"";
        position: absolute;
        width: 104px;
        height: 91px;
        background-image: url(/gfx/place/premiumshop/energy/icons.png);
        background-position: -29px 0px;
        right: -27px;
        top:50%;
        margin-top: -46px;
        background-repeat: no-repeat;
    }

    >.in{
        .flexRowCenterCenter;
        color: white;
        font-size: 26px;
        position: absolute;
        left:3px;
        right: 3px;
        top:3px;
        height: auto;
        bottom:3px;
        z-index: 1;
        white-space: nowrap;
        .myTextBorder3p(#007A99);
        .price{
            display: inline-block;
            position:relative;
            &.old{
                font-size:17px;
                margin-right:10px;
                &:after{
                    position: absolute;
                    left:-5px;
                    right: -5px;
                    content:"";
                    background-color: white;
                    height: 1px;
                    top:10px;
                    border: 1px solid red;
                    border-radius: 4px;
                }
            }
        }
    }
    &.bonusLess>.in{
        right: 29px;
    }
}
.formCheckbox,.formRadiobox{
    width: 230px;
    font-size: 16px;
    .qradio{
        margin-right: 10px;
    }
    input[type="checkbox"],input[type="radio"] {
        display:none;
    }
    input[type="checkbox"] + label,input[type="radio"] + label{
        cursor: pointer;
        .flexRowCenterCenter;
        .flexNoWrap;
    }
    input[type="checkbox"] + label .qradio,#qreg input[type="radio"] + label .qradio{
        width:31px;
        height:30px;
        background-image:url(/gfx/window_welcome/formradio.png);
        cursor: pointer;
        background-repeat: no-repeat;
        position: relative;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        background-position: right top;;

    }
    input[type="checkbox"]:checked + label .qradio,input[type="radio"]:checked + label .qradio {
        background-position: left top;;
    }
}
.flagsBox{
    position:relative;
    .flag{
        width: 20px;
        height: 12px;
        cursor: pointer;
        margin: 3px;
        border:1px solid rgba(0,0,0,0.2);
        background-repeat: no-repeat;
        background-position: center;
    }

    .fwin {
        position: absolute;
        left:-5px;
        top:-5px;
        background-color: rgba(255,255,255,0.6);
        padding: 2px 5px;

        .flexColumnLeftAroundCenter;
        .flexNoWrap;
        display: none;
        border-radius:5px;

        .flag{
            background-color: rgba(0,0,0,0.6);
        }
    }
    .fwin a{
        display: inline-block;
        margin:3px 0;
    }
}

.formText.register{
    background-image: url(/gfx/window_welcome/formtext.png) !important;
    width: 183px !important;
    height: 36px !important;
    color:#FF6EA6 !important;
    background-color: transparent !important;
    font-size: 24px !important;
    border: none !important;
    border-radius: 9px;
    .fontNormal;
    padding: 0px 10px !important;
    text-align:left;

}
/*.formSubmit {font-size: 9pt; min-height: 25px; color: #000; padding: 5px 0px 3px 0px; border: 0px;
background: url('../../gfx/forms/button_gold_mid.png') repeat-x;width:150px;cursor:pointer;}*/


/**/
.bold { font-weight: bold; }




#content {
	padding: 15px 15px 15px 0;
	width: 690px; /* org. 784*/
	height: auto;
}


#profile_content {
	padding: 10px;
	background: #414346;
}
.clear {
    display: block;
    clear: both;
    float: none !important;
    height: 0px !important;
    padding: 0px !important;
    margin: 0px !important;
    min-height: 0px !important;
    width: 0px;
}
.left { float: left; }
.right { float: right !important; }
.aleft{
    text-align: left !important;;
}
.aright{
    text-align: right !important;;
}
.acenter{
    text-align: center !important;;
}

#content_main{
    .place{
        position: relative;
        min-height: 400px;
    }
    .place>.book{
        position: absolute;
        z-index: 2;
    }
    .place>.book.popupPaper{
        top:10px;
    }
}
#content_main .place>.book .closeBook,#myGameCoverWindow .closeCover.main,.closeCustom{
    position: absolute;
    right: -8px;
    top: -8px;
    width: 37px;
    height: 38px;
    cursor: pointer;
    z-index: 10;
    background-image: url(/gfx/ui/close.png);
    &:hover{
        background-position: left bottom;
    }
    &:active{
        background-position: left center;
    }
    &:after{
        position: absolute;
        content: "";
        left:-5px;
        top:-5px;
        right: -5px;
        bottom: -5px;
    }
}
.customBox{
    padding: 10px;
    border: 1px solid #dadada;
    border-radius: 12px;
    text-align: center;
    position: relative;
    width: 120px;
    height: 120px
}
.customBox>.imageCustom{
    padding: 10px;
    width: 100px;
    height: 100px;
}
.customBox>.bonus{
    position: absolute;
    top:10px;
    right: 10px;
    background-color: white;
    padding: 5px;
    border-radius: 5px;
}
.place.opacity:before {
    position: absolute;
    opacity: 0.3;
    content: "";
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background-color: black;
    z-index: 2;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
.place .item{
    cursor: pointer;
}



.closeQuick{
    position: absolute;
    right: 7px;
    top: 9px;
    width: 25px;
    height: 25px;
    background-color: red;
    border: 1px solid white;
    cursor: pointer;
    border-radius: 15px;
    z-index: 10;
}
.closeQuick.fa.fa-times:before {
    font-size: 21px;
    color: white;
    left: 4px;
    top: 1px;
    position: absolute;
}
.closeQuick:after {
    position: absolute;
    content: "";
    left: -5px;
    top: -5px;
    right: -5px;
    bottom: -5px;
}



#myGameCoverWindow{
    position: absolute;
    font-size: 13px;
}
#myGameCoverQueue{
    display: none;
}
.iconText{
    display: inline-block;
    padding: 6px 0px 6px 30px;
    font-weight: bold;
    position: relative;
    min-height: 1em;
}
.iconText:after{
    content: "";
    position: absolute;
    left:2px;
    top:2px;
    bottom: 2px;
    width: 25px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.iconText.card.spade:after{
    background-image: url(/gfx/custom/cards/spade.png);
}
.iconText.card.heart:after{
    background-image: url(/gfx/custom/cards/heart.png);
}
.iconText.card.diamond:after{
    background-image: url(/gfx/custom/cards/diamond.png);
}
.iconText.card.club:after{
    background-image: url(/gfx/custom/cards/club.png);
}
.text.shadow_white{
    text-shadow: 1px 1px 0 white, -1px 1px 0 white, 1px -1px 0 white, -1px -1px 0 white;
}
.iconText.action.leave:after{
    background-image: url(/gfx/custom/pogame/icons/leave.png);
}
.iconText.action.up:after{
    background-image: url(/gfx/custom/pogame/icons/up.png);
}
.iconText.action.wait:after{
    background-image: url(/gfx/custom/pogame/icons/wait.png);
}
.iconText.action.begin:after{
    background-image: url(/gfx/custom/pogame/icons/begin.png);
}
.iconText.action.eject:after{
    background-image: url(/gfx/custom/pogame/icons/eject.png);
}

.customIconBox{
    width: 48px;
    height: 48px;
    background-image: url(/gfx/place/ganggoal/boxcustom.png);
    margin: 10px;
    display: inline-block;
    border:1px solid rgba(255,255,255,0.7);
    position: relative;
    color:white;
}
.customIconBox>.iconText{
    text-align: center;
    padding: 30px 2px 0px 2px;
    height: 14px;
    width: 44px;
}


.customIconBox>.iconText:after{
    left:2px;
    right: 2px;
    top:2px;
    bottom: auto;
    height: 28px;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.customIconBox.orange{
    border:1px solid #f39c05;
}
.customIconBox.big{
    width: 78px;
    height: 78px;
    background-size: cover;

}
.customIconBox.big>.iconText{
    text-align: center;
    padding: 59px 4px 0px 4px;
    height: 20px;
    width: 70px;
}
.customIconBox.big>.iconText:after{
    height: 53px;
    top:6px;

}

.myRoll{
    position: relative;
}
.myRoll>.in{
    position: relative;
    float: left;
    border:1px solid black;
    padding: 10px;
    border-radius: 12px;
}
.myRoll>.in>.in_wrap{
    overflow: hidden;
    position: relative;
}
.myRoll>.in>.in_wrap>.move{
    position: absolute;
    left:0px;top:0px;
}
.myRoll>.left,.myRoll>.right{
    width: 50px;
    position: relative;
    font-size: 22px;
    color: #57585A;
    cursor: pointer;
}
.myRoll>.left:after,.myRoll>.right:after{
    position: absolute;
    left:50%;
    top:50%;
}
.myRoll>.left:hover:after,.myRoll>.right:hover:after{
    opacity: 0.7;
}
.myRoll>.left:after{
    content: "\25C0";
}
.myRoll>.right:after{
    content: "\25B6";
}

.myCircleProgress{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;






    width: 200px;
    height: 200px;
    overflow: hidden;
    .cellImg{
        position: relative;
        width: 50%;
        height: 50%;
        overflow: hidden;
        .img{
            position: absolute;
            left:0px;
            top:0px;
            background-color: tan;
            width: 100%;
            height: 200%;
            &[data-type="0"]{
            }
            &[data-type="1"]{
                width: 200%;
                height: 100%;
                left: -100%;

            }
            &[data-type="2"]{
                top: -100%;
            }
            &[data-type="3"]{
                width: 200%;
                height: 100%;
            }
        }

    }
}
.hide{
    display: none !important;
}
.hideOpa{
    opacity: 0 !important;
}
.pointer{
    cursor: pointer;
}
.unselectable{
    -moz-user-select: -moz-none;
   -khtml-user-select: none;
   -webkit-user-select: none;
   -o-user-select: none;
   user-select: none;
}
.tocenter,.tocenterH,.tocenterV{
    position: absolute;
}



#smoke .win_shadow{
    z-index: auto;
    left:6px;top:4px;
    border-radius:5px;
}
#smoke{
    position:absolute;
    z-index:1001;
    color: white;

}
#smoke .lay{
    padding: 8px;
    border: 1px solid #aaa;
    -webkit-box-shadow: 0 0 5px #aaa;
    border-radius:5px;
    height: 100%;
    position:relative;
    background-color: #2A2A2A;


}
#smoke .lay .cap{
    text-align: center;
    font-weight: bold;
    border-bottom: 1px dashed  #aaa;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size:16px;
    color: white;
}
#smoke .lay .desc{
    text-align: center;
    margin-top: 2px;
    font-size:14px;
    color: white;
}
.mysmoke,.mysmoke_var{
    display: none !important;
}
.acenter{
    text-align: center;
}
.closeImage{
    width: 25px;
    height: 25px;
    background-color: red;
    border: 1px solid white;
    cursor: pointer;
    border-radius: 15px;
    position: relative;
}

.closeImage.fa.fa-times:before {
    font-size: 21px;
    color: white;
    left: 4px;
    top: 1px;
    position: absolute;
}
.textfill{
        width: 100%;
        height: 100%;
}
.textfill.center{
    .flexColumnCenterAround;
}
.textfill.center>span{
   display: block;
   text-align: center;
}
.textfill.nowrap{
    white-space: nowrap;
}

.imageCustom{
    width: 80px;
    height: 80px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.fourDirection{
    position: absolute;
    left:0px;
    right: 0px;
    bottom: 0px;
    top:0px;
}
.nowrap{
    white-space:nowrap;
}
.cutAll{
    width: 1px !important;
    height: 1px !important;;
    overflow: hidden !important;;
    opacity: 0 !important;;
}

.mySelect.hide{
    display: none !important;
}
.MySeWhole{
     position: relative;
     min-height:10px;
     border-radius:14px;
     background-color:#FFFFFF;
     /*background-color: #F0BA66;*/
     border:3px solid #898989;
}
.myEl{
     margin-bottom: 3px;
     padding: 0 2px;
}
.myEl:nth-child(odd){
    background: #F8F8F8;
}
.MySeWhole .myEl:not(.na):hover{
    background: #FFFFFF;
    cursor: move;
}

.MySeShade{
     width: 100%;
     height: 100%;
     padding:3px;
     position: absolute;
     left:-3px;
     top:-3px;
     background: black;
     border-radius:14px;
     opacity: 0.2;
}
.MySeInside{
     vertical-align: middle;
     padding:5px 25px 5px 5px;
     size:30px;
     margin-left:7px;
}
.MySeBox{
     margin-top: -1px;
     position:absolute;
     left:-3px;
     padding: 0px 10px 6px 10px;

      background-color: #F2F2F2;
     border-radius:14px;
     z-index: 3;
     border-top: none;

     border-top-right-radius:0px;

      border-top-left-radius:0px;

     border:3px solid #898989;
     border-top-width: 0px;
}
.MySeRight{
     position:absolute;
     right: 5px;
     top:50%;
     margin-top: -7px;
     background:url('/gfx/form/drop.png');
     width: 20px;
     height: 14px;
}
.mySelect{
     position: absolute;/*temporary!!!*/ } .MyScInside{
     overflow: hidden;
     position: relative;
}
.MyScMove{
     position: absolute;
     top:0px;
     left:0px;
     right: 0px;
}
.myScroll{
     position: relative;
}
.mySelect .myScroll{
     position: absolute;
}
.MyScLeft{
     position: absolute;
     right: 0px;
     top:0px;
     width: 30px;
     cursor: pointer;
}
.MyScTrack{
     margin:0px auto;
     position:relative;
     height:100%;
     width:6px;

      background:url(/gfx/custom/ui/scroll.png);
     border-radius:4px;
}
.MyScThumb{
    position:absolute;
    width:19px;
    height:19px;
    left:-6px;
/*   background:url('/gfx/new/handle.png');
    background: url(/gfx/new/slider_dot.png) center center no-repeat;*/
    cursor: pointer;
    width: 20px;
    height: 20px;
        left: -7px;
            background-color: #dadada;
    border-radius: 20px;
    opacity: 0.9;

}
.mySlider {
    position: relative;
    width: 150px;
    height: 26px;
    background-color: #521335;
    border: 2px solid #90195A;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
    padding:0px 1px;
    &:after{
        content:"";
        position: absolute;
        width: 71px;
        height: 6px;
        left:1px;
        top:1px;
        background-image: url(/gfx/place/skills/reflex.png);
    }
}
.mySlider .allfill{
    height: 150px;
    width: 150px;
    height: 26px;

}
.mySlider.vertical{
    width: 26px;
    height: 150px;
    background-position: center 0%;
}
.mySlider.vertical .allfill{
    height: 150px;
    width: 26px;
    margin-left: -9px;
}
.mySlider .fill{
    position: absolute;
    background-color: #FE415B;
    height: 24px;
    border-radius: 3px;
    left:1px;
    top:1px;
}
.mySlider.vertical .fill{
    left:11px;
    background: black;
    width: 5px;
    top:auto;
    bottom:0px;

}
.mySlider .myHandler {
    position: absolute;
    left: -8px;
    height: 16px;
    width: 16px;
    padding:  5px 0px;
    top: 0px;
    background-size: contain;
    background-position:center center;
    cursor: pointer;
}
.mySlider.vertical .myHandler {
    left: 2px;
    top: -8px;
    padding:  0px 5px;
    background-position: center 0%;

}
.flexNoWrap{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}
.flexColumnCenterBetween{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.flexColumnBetweenCenter{
     display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.flexColumnBottomCenter{
       display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;


}
.flexColumnCenterAround{
     display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;


}
.flexColumnCenterCenter{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.flexRowAround{
    display: flex;justify-content: space-around;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}
.flexRowCenterCenter{
     display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.flexList{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;


    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;





}
.flexListCenter{
      display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-align-content: space-around;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.flexListCenterTop{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}
.flexRowAroundCenter{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.flexRowBetweenCenter{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;


}
.flexRowRightBottom{
      display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
}
.flexColumnLeftAroundCenter{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-content: flex-start;
    align-items: flex-start;


    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;




}
.flexColumnRightAroundCenter{
     display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
}
.flexColumnRight{
       display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
}
.flexRowBetweenBottom{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
}
.flexRowAroundBottom{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
}
.flexRowBetweenTop{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}
.flexRowLeft{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}
.flexRowLeftCenter{
     display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.flexColumnLeftBetweenCenter{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;


     display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;


}


#messageBox{
    position: absolute;left:0px;
    top:0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0,0,0,0.5);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-content: stretch;
    align-items: center;


     display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

}
#messageBox #messageContent{
    padding: 20px;
    background-color: black;
    color:white;
    position: relative;
}

.flexTable .flexRow{
     display: flex;
     justify-content: space-between;
     align-items: center;
     position: relative;


    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

}
.flexTable .flexRow>.cell{
    padding: 0px 10px;
    width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: space-between;
    align-items: center;


    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: space-between;
    -ms-flex-line-pack: justify;
    align-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;



}
.flexTable .flexRow .cell.avatarCell .avatar{
    width: 50px;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

input[type="checkbox"],input[type="radio"] {
    display:none;
}
input[type="checkbox"] + label.myCheckboxCont .myCheckbox,input[type="radio"] + label.myCheckboxCont .myCheckbox {
    display:inline-block;
    width:17px;
    height:17px;
    background:url(/gfx/custom/ui/checkbox.png) left center no-repeat;
    cursor:pointer;
    position: relative;
}
input[type="checkbox"]:checked + label.myCheckboxCont .myCheckbox,input[type="radio"]:checked + label.myCheckboxCont .myCheckbox {
    background-position: right center;
}
label.myCheckboxCont{
    cursor: pointer;
}

.textDimensionCalculation {
    position: absolute;
    visibility: hidden;
    height: auto;
    width: auto;
}


#qLOG{
    padding: 10px;
    background-color: white;
    left:0px;
    top:0px;
    position: absolute;
    color:black;
    display: none;
    z-index: 1000;
}
#qLOG #closeL{
    position: absolute;
    bottom:-20px;
    height: 20px;
    right: 0px;
    width: 20px;
    cursor: pointer;
    background-color: red;
}



.myPageSelector{
    position: relative;

}
.myPageSelector>.mainContent{
    position: relative;
    overflow: hidden;

}
.myPageSelector>.mainContent>.myPSMove{
    position: absolute;
    left:0px;
    right: 0px;
}
.myPageSelector>.mainNavi{
    height: 50px;
    font-size: 16px;
    white-space: nowrap;

    display: flex;
    justify-content: space-between;
    align-items: center;

      display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.myPageSelector>.mainNavi .nArr{
    width: 34px;
    height: 40px;
    cursor: pointer;
    position: relative;
    background-position: 0 -80px;
    background-repeat: no-repeat;
}

.myPageSelector>.mainNavi .nArr:hover{
    background-position: 0 0px;
}
.myPageSelector>.mainNavi .nArr:active{
    background-position: 0 -40px;
}
.myPageSelector>.mainNavi .nLeft:after,.myPageSelector>.mainNavi .nRight:after{
    content: "";
    position: absolute;
    top:-5px;
    bottom: -5px;
    left:-5px;
    right: -5px;
}
.myPageSelector>.mainNavi .nLeft{
    background-image: url(/gfx/ui/all_left.png);
    margin-left: 10px;


}
.myPageSelector>.mainNavi .nRight{
    background-image: url(/gfx/ui/all_right.png);
    margin-right: 10px;
}

.myFormInput{
    position: relative;
    padding: 2px 40px;
}
.myFormInput .myFormInputTrigger{

}
.myFormInput{
    .minusText,.plusText{
        position: absolute;
        width: 36px;
        height: 36px;
        top:50%;
        margin-top: -18px;
        cursor: pointer;
        background-image: url(/gfx/place/girls/gift/plusminus.png)
    }
    .plusText{
        right: 0px;
        background-position: right top;
    }
    .plusText:hover{
        background-position: right bottom;
    }
    .plusText:active{
        background-position: right center;
    }
    .minusText{
        left: 0px;
    }
    .minusText:hover{
        background-position: left bottom;
    }
    .minusText:active{
        background-position: left center;
    }

}

.blinkItem{
    position: absolute;
    top:0px;
    left:0px;
    right: 0px;
    bottom: 0px;
    border: 2px #FFF;
    border-style: solid;
    box-shadow: 0px 0px 10px 5px rgba(230, 200, 9, 0.75), inset 0px 0px 5px 2px rgba(230, 200, 9, 0.75);
    border-radius: 5px;
}
.rotate25{
    -webkit-transform: rotate(25deg);
    -moz-transform: rotate(25deg);
    -o-transform: rotate(25deg);
    transform: rotate(25deg);
}
.fillBorder.custom{
    position: relative;
    border:1px solid black;
    padding: 1px;
    height: 15px;
    background-color: white;
    text-align: center;
}
.fillBorder.custom>.fill{
    height: 15px;
    background-color: red;
}
.fillBorder.custom.unable>.fill{
    background-color: grey;
}
.fillBorder.custom .text{
    white-space: nowrap;
    font-size: 15px;
}


.mirrorFlip{
    -moz-transform: scale(-1, 1);
-webkit-transform: scale(-1, 1);
-o-transform: scale(-1, 1);
-ms-transform: scale(-1, 1);
transform: scale(-1, 1);
}





.inputArrowLeft,.inputArrowRight{
    width: 34px;
    height: 40px;
    cursor: pointer;
    position: relative;
    background-position: 0 -80px;
}

.inputArrowLeft:hover,.inputArrowRight:hover{
    background-position: 0 0px;
}
.inputArrowLeft:active,.inputArrowRight:active{
    background-position: 0 -40px;
}
.inputArrowLeft:after,.inputArrowRight::after{
    content: "";
    position: absolute;
    top:-5px;
    bottom: -5px;
    left:-5px;
    right: -5px;
}
.inputArrowLeft{
    background-image: url(/gfx/ui/all_left.png);

}
.inputArrowRight{
    background-image: url(/gfx/ui/all_right.png);
}
.bookView{
    position: relative;
    background-repeat: no-repeat;
    >.up{
       position: absolute;
       left:0px;
       right:0px;
       top:0px;
       background-repeat: no-repeat;
    }
    >.down{
       position: absolute;
       right: 0px;
       left:0px;
       bottom:0px;
       background-repeat: no-repeat;
    }
}
.bookView.big{
    width: 875px;
    height: 616px;
    background-position: 0px 35px;
    background-image: url(/gfx/place/rooms/book_big/win_in2.jpg);
    >.up{
        height: 37px;
        background-image: url(/gfx/place/rooms/book_big/win_border.png?2);

    }
    >.down{
        height: 42px;
        background-position: left bottom;
        background-image: url(/gfx/place/rooms/book_big/win_border.png?2);
    }
    >.content{
        position: absolute;
        top: 16px;
        left: 18px;
        bottom: 26px;
        right: 18px;
    }
}
.bookView.note{
    width: 649px;
    height: 460px;
    background-position: 0px 35px;
    background-image: url(/gfx/place/preregi/win.jpg);
    >.up{
        height: 37px;
        background-image: url(/gfx/place/preregi/updow.png);

    }
    >.down{
        height: 42px;
        background-position: left bottom;
        background-image: url(/gfx/place/preregi/updow.png);
    }
    >.content{
        position: absolute;
        top: 16px;
        left: 18px;
        bottom: 26px;
        right: 18px;
        .bonusImg{
            width: 109px;
            height: 87px;
            background-repeat: no-repeat;
            background-image: url(/gfx/place/preregi/bonus.png);
        }
    }
}

.bookView.blue{
    background-position: 0px 35px;
    width:902px;
    height: 504px;
    .myTextBorder3p(#135F7D);
    color:white;
    background-image:url(/gfx/place/explainbonus/back.jpg);


    &>.up{
        height:37px;
        background-image: url(/gfx/place/explainbonus/updown.png);
        height: 37px;
    }
    &>.down{
        height:42px;
        background-position: left bottom;
        background-image: url(/gfx/place/explainbonus/updown.png);
    }
    &>.down2{
        height:49px;
        width: 902px;
        position: absolute;
        left:0px;
        bottom:0px;
        background-repeat: no-repeat;
        background-position: left bottom;
        background-image: url(/gfx/window/bottom.png);
    }
    &>.content{
        position: absolute;
        top:25px;
        left:20px;
        right:20px;
        bottom:33px;
    }

}
.myScale{
    position: relative;
}
.bookView{
    >.capBig{
        position: absolute;
        left:0px;
        right: 0px;
        top:-35px;
        .flexRowAroundCenter;
        >.in{
            min-width: 235px;
            height: 52px;
            position: relative;
            .flexRowAroundCenter;
            z-index: 1;
            -webkit-box-shadow: 0px 9px 37px -5px rgba(107,57,23,1);
            -moz-box-shadow: 0px 9px 37px -5px rgba(107,57,23,1);
            box-shadow: 0px 9px 37px -5px rgba(107,57,23,1);
            >.content{
                z-index: 1;
                padding: 0px 22px;
                position: relative;
                .myTextBorder3p(#90195A);
                color:white;
                font-size:28px;
            }
            >.up{
                position: absolute;
                top: 0px;
                right: -6px;
                width: 115px;
                height: 55px;
                background-image: url(/gfx/place/rooms/book_big/leftright.png);
                background-position: right top;
            }
            >.down{
                position: absolute;
                top: 0px;
                left: -4px;
                width: 108px;
                height: 55px;
                background-image: url(/gfx/place/rooms/book_big/leftright.png);
            }
            &:after{
                content: "";
                position: absolute;
                right: 107px;
                left: 102px;
                top: 0px;
                height: 55px;
                background-image: url(/gfx/place/rooms/book_big/ext.png);
            }
        }
    }

    background-repeat:no-repeat;

    &>.up{
        position: absolute;
        background-repeat: no-repeat;
        top:0px;
        left:0px;
        right: 0px;
    }
    &>.down{
        position: absolute;
        left:0px;
        right: 0px;
        background-position: left bottom;
        background-repeat:no-repeat;
        bottom:0px;
    }
    &>.content{
        position: absolute;
        top:25px;
        left:20px;
        right:20px;
        bottom:33px;
    }

}
.textEllipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.reflexGeneral{
    position: absolute;
    background-image: url(/gfx/place/premiumshop/reflex.png);
    background-repeat:no-repeat;
    z-index:1;
    display:none;
    &[data-type="1"]{
        width: 65px;
        height: 61px;
    }
    &[data-type="2"]{
        width: 46px;
        height: 43px;
        background-position: -68px 0px;
    }
    &[data-type="3"]{
        width: 53px;
        height: 49px;
        background-position: -116px 0px;
    }
    &[data-type="4"]{
        width: 41px;
        height: 38px;
        background-position: -171px 0px;
    }
    &[data-type="5"]{
        width: 45px;
        height: 42px;
        background-position: 0px -64px;
    }
    &[data-type="6"]{
        width: 31px;
        height: 30px;
        background-position: -65px -76px;
    }
    &[data-type="7"]{
        width: 29px;
        height: 28px;
        background-position: -114px -78px;
    }
    &[data-type="8"]{
        width: 25px;
        height: 27px;
        background-position: -187px -79px;
    }
}

.myFlash{
    background-image: url(/gfx/place/girls/new/needs/flash.png);
    position: absolute;
}
.myFlash[flash_id="0"]{
    background-image: none;
}
.myFlash[flash_id="1"]{
    width: 24px;
    height: 25px;
    margin-top: -12px;
    margin-left: -12px;
}
.myFlash[flash_id="2"]{
    width: 14px;
    height: 17px;
    background-position: 0px -25px;
    margin-top: -8px;
    margin-left: -7px;
}
.myFlash[flash_id="3"]{
    width: 17px;
    height: 16px;
    background-position: 0px -41px;
    margin-top: -8px;
    margin-left: -8px;
}
.myFlash[flash_id="4"]{
    width: 27px;
    height: 31px;
    background-position: 0px 57px;
    margin-top: -15px;
    margin-left: -13px;
}
.myFlash[flash_id="5"]{
    width: 14px;
    height: 16px;
    background-position: left bottom;
    margin-top: -8px;
    margin-left: -7px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently*/
}
.boxShadow(@color){
    border: 2px #FFF;
    border-style: solid;
    box-shadow: 0 0 10px 5px @color, inset 0 0 5px 2px @color;
    border-radius: 5px;
}

.rotation(@deg){
    -ms-transform: rotate(@deg*1deg); /* IE 9 */
    -webkit-transform: rotate(@deg*1deg); /* Chrome, Safari, Opera */
    transform: rotate(@deg*1deg);
}

.myTextBorderNone{
    text-shadow:none;
}
.myTextBorder05p(@color){
    text-shadow:  1px 1px @color;
}
.myTextBorder1p(@color){
    text-shadow: -1px 0 @color, 0 1px @color, 1px 0 @color, 0 -1px @color;
}
.myTextBorder2p(@color){
    text-shadow: -1px 0 @color, -1px 1px @color, 1px 1px @color,0 1px @color, 1px 0 @color, 0 -1px @color,0 2px @color,-1px 2px @color,1px 2px @color;
}
.myTextBorder3p(@color){
    text-shadow: -1px 0 @color, -1px 1px @color, 1px 1px @color,0 1px @color, 1px 0 @color, 0 -1px @color,
    0 2px @color,-1px 2px @color,1px 2px @color,2px 2px @color,
    0 3px @color,-1px 3px @color,1px 3px @color;
}
.myTextBorder4p(@color){
    text-shadow: @color 3px 0px 0px, @color 2.83487px 0.981584px 0px, @color 2.35766px 1.85511px 0px, @color 1.62091px 2.52441px 0px, @color 0.705713px 2.91581px 0px, @color -0.287171px 2.98622px 0px, @color -1.24844px 2.72789px 0px, @color -2.07227px 2.16926px 0px, @color -2.66798px 1.37182px 0px, @color -2.96998px 0.42336px 0px, @color -2.94502px -0.571704px 0px, @color -2.59586px -1.50383px 0px, @color -1.96093px -2.27041px 0px, @color -1.11013px -2.78704px 0px, @color -0.137119px -2.99686px 0px, @color 0.850987px -2.87677px 0px, @color 1.74541px -2.43999px 0px, @color 2.44769px -1.73459px 0px, @color 2.88051px -0.838247px 0px;
}
.myTextBorder6p(@color){
    text-shadow: @color 6px 0px 0px, @color 5.91686px 0.995377px 0px, @color 5.66974px 1.96317px 0px, @color 5.2655px 2.87655px 0px, @color 4.71532px 3.71022px 0px, @color 4.03447px 4.44106px 0px, @color 3.24181px 5.04883px 0px, @color 2.35931px 5.51667px 0px, @color 1.41143px 5.83163px 0px, @color 0.424423px 5.98497px 0px, @color -0.574341px 5.97245px 0px, @color -1.55719px 5.79441px 0px, @color -2.49688px 5.45578px 0px, @color -3.36738px 4.96596px 0px, @color -4.14455px 4.33852px 0px, @color -4.80686px 3.59083px 0px, @color -5.33596px 2.74364px 0px, @color -5.71718px 1.8204px 0px, @color -5.93995px 0.84672px 0px, @color -5.99811px -0.150428px 0px, @color -5.89004px -1.14341px 0px, @color -5.61874px -2.1047px 0px, @color -5.19172px -3.00766px 0px, @color -4.62082px -3.82727px 0px, @color -3.92186px -4.54081px 0px, @color -3.11421px -5.12852px 0px, @color -2.22026px -5.57409px 0px, @color -1.26477px -5.86518px 0px, @color -0.274238px -5.99373px 0px, @color 0.723898px -5.95617px 0px, @color 1.70197px -5.75355px 0px, @color 2.63288px -5.39147px 0px, @color 3.49082px -4.87998px 0px, @color 4.25202px -4.23324px 0px, @color 4.89538px -3.46919px 0px, @color 5.40307px -2.60899px 0px, @color 5.76102px -1.67649px 0px, @color 5.95932px -0.697531px 0px;
}
