@import (reference) "../main/general";
.myLink{
    cursor: pointer;
}
#windowAllCont{
    background-image: url(/gfx/window/scale/back_low2.jpg);
   background-position: center top;
   background-repeat: no-repeat;
   background-color: #453CA5;
   /*width: 100vw;
   height:100vh;*/
   overflow: hidden;
   position: fixed;
   left:0px;
   right: 0px;
   top:0px;
   bottom:0px;
   min-height: calc(100% - 0);
   &.bigResolution{
       background-position: center center;
       #gameWhole>.bimg.bbottomText{
           display: block;
       }
   }
}
#gameFull{
    position: relative;
    z-index: 3;
    overflow: hidden;

}
#gameWhole{
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
    /*
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
    */
    width: 1318px;
    height: 914px;
    position: absolute;
    top:50%;
    margin-top: -457px;
    left:50%;
    margin-left:-659px;
    /*will-change: transform;*/

    &.space{
        height: 934px;
    }
    &.space #gameWindowSpace{
        height: 20px;
    }
    >.bimg{
        position:absolute;
        background-repeat:no-repeat;
        z-index:2;
        &.bleft{
            background-image: url(/gfx/window/scale/img_left.png);
            width: 318px;
            height: 880px;
            right:100%;
            margin-right: -4px;
            top:0px;
        }
        &.bright{
            background-image: url(/gfx/window/scale/img_right.png);
            width: 303px;
            height: 776px;
            left:100%;
            margin-left: -4px;
            top:0px;
        }
        &.bbottom{
            background-image: url(/gfx/window/scale/img_bottom.png);
            width: 1172px;
            height: 116px;
            top:100%;
            margin-top:-4px;
            left:50%;
            margin-left: -586px;
        }
        &.bbottomText{
            background-image: url(/gfx/window/scale/text.png?4);
            width: 1224px;
            height: 116px;
            top:100%;
            margin-top:166px;
            left:50%;
            margin-left: -612px;
            display: none;
        }
        &.ball{
            z-index: 1;
            position: absolute;
            left:3px;
            top:24px;
            right: 3px;
            bottom:3px;
            -webkit-box-shadow: 0px 0px 77px 12px rgba(0, 0, 0, 0.98);
            -moz-box-shadow: 0px 0px 77px 12px rgba(0, 0, 0, 0.98);
            box-shadow: 0px 0px 77px 12px rgba(0, 0, 0, 0.98);
        }
    }
}
#gameWindow{
    width: 1280px;

    position: relative;
    overflow: hidden;
    padding: 18px 19px 19px 19px;
    >#content_main {
        position: relative;
        background-color: rgba(0,0,0,0.95);
        min-width: 1280px;
        min-height: 780px;
    }
    >.bup{
        position: absolute;
        left:50%;
        width: 1278px;
        height: 20px;
        margin-left: -639px;
        top:0px;
        background-image: url(/gfx/window/scale/up.png);
        background-repeat: no-repeat;
        z-index: 200;
    }
    >.bdown{
        position: absolute;
        left:50%;
        width: 1278px;
        height: 21px;
        margin-left: -639px;
        bottom:0px;
        background-image: url(/gfx/window/scale/up.png);
        background-repeat: no-repeat;
        background-position: left bottom;
        z-index: 3;
        z-index: 200;
    }
    >.bleft{
        position: absolute;
        left:0px;
        top:0px;
        background-image: url(/gfx/window/scale/left.png);
        background-repeat: no-repeat;
        width: 21px;
        height: 914px;
        z-index: 200;
    }
    >.bright{
        position: absolute;
        right:0px;
        top:0px;
        background-image: url(/gfx/window/scale/left.png);
        background-repeat: no-repeat;
        background-position: right top;
        width: 21px;
        height: 914px;
        z-index: 200;
    }
}
#profileBelt{
    position: relative;
    height: 97px;
    z-index: 200;
}
#profileBelt>#backUp{
    background-image: url(/gfx/belt_top/belt_ext.jpg);
    width: 1280px;
    height: 91px;
}
#profileBelt>#backUp2{
    background-image: url(/gfx/belt_top/belt_ext_down.png);
    width: 1280px;
    height: 17px;
}
#profileBelt>#backUp3{
    background-image: url(/gfx/belt_top/belt_down.png);
    width: 175px;
    height: 144px;
    position: absolute;
    top:0px;
    left:9px;
}

#profileBelt #profileContent{
    position: absolute;
    left:0px;
    right: 0px;
    top:0px;
/*    height:108px;*/
    height: 91px;
    padding-bottom: 17px;
}

#profileBelt #rowBelts{
    margin-left: 196px;
    margin-left: 188px;
    height: 88px;

    display: flex;
    justify-content: space-around;
    align-items: center;


     display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;


}
#profileBelt #rowBelts #beltTime{
    background-image: url(/gfx/belt_top/time_belt.png);
    width: 141px;
    height: 48px;
    position: relative;
}
#profileBelt #rowBelts #beltTime:hover{
    background-position: right top;
}
#profileBelt #rowBelts #beltTime .buttonPlus{
    position: absolute;
    right: 5px;
    top:5px;
}
#profileBelt #rowBelts #beltTime .icon{
    background-image: url(/gfx/belt_top/clock.png);
    width: 61px;
    height: 66px;
    position: absolute;
    left:-5px;
    top:0px;
    margin-top:-7px;
}
#profileBelt #rowBelts #beltTime #timeText{
    position: absolute;
        left: 51px;
    right: 43px;
    bottom: 7px;
    top: 7px;

    display: flex;
    justify-content: center;
    align-items: center;


     display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;


    color: white;
    font-weight: bold;
    font-size: 20px;
}
#profileBelt #rowBelts #beltEnergy{
    width: 385px;
    height: 50px;
    background-image: url(/gfx/belt_top/belt_icons.png);
    position: relative;
}
#profileBelt #rowBelts #beltEnergy #hider{
    width: 16px;
    height: 27px;
    background-image: url(/gfx/belt_top/belt_hider.png);
    position: absolute;
    right: 5px;
    top: -2px;
}
#profileBelt #rowBelts #beltEnergy #beltEnergyMain{
    background-image: url(/gfx/belt_top/win_blue.png);
    position: absolute;
    left:-39px;
    top:-5px;
    width: 104px;
    height: 63px;
    color:white;
}
#profileBelt #rowBelts #beltEnergy #beltEnergyMain #energyText{
    position: absolute;
    left: 24px;
    right: 18px;
    top: 13px;
    bottom: 29px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;

     display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    .myTextBorder3p(#25A4BA);
}
#profileBelt #rowBelts #beltEnergy #beltEnergyMain .icon{
    background-image: url(/gfx/belt_top/icon_light.png);
    position: absolute;
    left:-1px;
    top:0px;
    margin-top: 1px;
    width: 38px;
    height: 57px;
}
#profileBelt #rowBelts #beltEnergy #beltEnergyMain:hover .icon{
    background-position: right top;
}
#profileBelt #rowBelts #beltEnergy #beltEnergyMain #textImg{
    position: absolute;
    left:18px;
    top:0px;
    margin-top: 34px;
    width: 66px;
    height: 11px;
    background-image: url(/gfx/belt_top/slotfree.png);
}
#profileBelt #rowBelts #beltEnergy #beltEnergyBars{
    height: 23px;
    width: 340px;
    position: absolute;
    top: 13px;
    left: 39px;
}
#profileBelt #rowBelts #beltEnergy #beltEnergyFlash{
     height: 15px;
    width: 325px;
    position: absolute;
    top: 13px;
    right: 31px;
    background-image: url(/gfx/belt_top/belt_flash.png);
}
#profileBelt #rowBelts #beltEnergy #beltEnergyBars .belt{
    position: relative;
    height: 23px;
    width: 100px;
    background-image: url(/gfx/belt_top/belt_color.png);
    float: left;
    background-repeat: repeat-x;
}
#profileBelt #rowBelts #beltEnergy #beltEnergyBars .belt>.fillOver{
    position: absolute;
    left:0px;
    top:-5px;
    right: 0px;
    bottom: -5px;
    z-index: 2;
}
#profileBelt #rowBelts #beltEnergy #beltEnergyBars .belt>.fillOver .iconNumber{
    height: 28px;
    width: 39px;
    position: absolute;
    left:50%;
    margin-left: -17px;
    bottom: -35px;
    background-image: url(/gfx/belt_top/pointer.png);
    color:#E3F7FF;
    .fontNormal;
}
#profileBelt #rowBelts #beltEnergy #beltEnergyBars .belt.beltSkill .iconNumber{
    background-position: -39px 0px;
}
#profileBelt #rowBelts #beltEnergy #beltEnergyBars .belt.beltCash .iconNumber{
    background-position: -78px 0px;
}
#profileBelt #rowBelts #beltEnergy #beltEnergyBars .belt.beltPosition .iconNumber{
    background-position: 0px 0px;
}
#profileBelt #rowBelts #beltEnergy #beltEnergyBars .belt.beltFight .iconNumber{
    background-image: url(/gfx/belt_top/pointer_fio.png);
}

#profileBelt #rowBelts #beltEnergy #beltEnergyBars .belt .iconNumber>.in{
    position: absolute;
    left:0px;
    right: 0px;
    bottom: 7px;
    top: 7px;
    display: flex;
    justify-content: center;
    align-items: center;

     display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}



#profileBelt #rowBelts #beltEnergy #beltEnergyBars .belt.beltSkill .icon{
    height: 41px;
    width: 34px;
    position: absolute;
    left:50%;
    margin-left: -17px;
    top: 0px;
    margin-top: -3px;
    background-image: url(/gfx/belt_top/icon_cap.png);

}
#profileBelt #rowBelts #beltEnergy #beltEnergyBars .belt.beltCash .icon{
    height: 40px;
    width: 24px;
    position: absolute;
    left:50%;
    margin-left: -12px;
    top: 0px;
    margin-top: -3px;
    background-image: url(/gfx/belt_top/icon_dolar.png);
}
#profileBelt #rowBelts #beltEnergy #beltEnergyBars .belt.beltPosition .icon{
    height: 40px;
    width: 36px;
    position: absolute;
    left:50%;
    top:0px;
    margin-top: -3px;
    margin-left: -18px;
    background-image: url(/gfx/belt_top/icon_cam.png);
}
#profileBelt #rowBelts #beltEnergy #beltEnergyBars .belt.beltFight .icon{
    height: 41px;
    width: 24px;
    position: absolute;
    left:50%;
    top:0px;
    margin-top: -3px;
    margin-left: -13px;
    background-image: url(/gfx/belt_top/icon_fight.png);
}
#profileBelt #rowBelts #beltEnergy #beltEnergyBars .belt:hover .icon{
    background-position: right top;
}




#profileBelt #rowBelts #beltEnergy #beltEnergyBars .belt>.fillOver .iconObj{
    position: absolute;
    right: 0px;
    top:0px;
    bottom: 0px;
    width: 2px;
}



#profileBelt #rowBelts #beltCash{
    width: 232px;
    height: 50px;
    background-image: url(/gfx/belt_top/cash_belt.png);
    position: relative;
    z-index: 2;
    &:hover,&.hover{
        background-position: right top;
    }
    #cashFloatJs{
        position: absolute;
        width: 60px;
        height: 140px;
        left:90px;
        top:0px;
    }
}
#profileBelt #rowBelts #beltCash .icon{
   background-image: url(/gfx/belt_top/dolar_big.png);
   width: 46px;
   height: 66px;
   position: absolute;
   left:11px;
   margin-top: -4px;
   top:0px;
}
#profileBelt #rowBelts #beltCash .buttonPlus{
    position: absolute;
    right: 5px;
    top:6px;
}
#profileBelt #rowBelts #beltCash #cashText{
    position: absolute;
    top: 6px;
    bottom: 7px;
    left: 54px;
    right: 49px;
    display: flex;
    justify-content: center;
    align-items: center;

     display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    color:#FFFF7D;
    font-weight: bold;
    font-size: 20px;
}
#profileBelt #rowBelts #beltPremium{
    background-image: url(/gfx/belt_top/premium_belt.png);
    width: 199px;
    height: 52px;
    position: relative;
}
#profileBelt #rowBelts #beltPremium:hover{
    background-position: right top;
}
#profileBelt #rowBelts #beltPremium:active{
    background-position: center top;
}
#profileBelt #rowBelts #beltPremium .icon{
    position: absolute;
    background-image: url(/gfx/belt_top/premium.png);
    width: 105px;
    height: 94px;
    left:-21px;
    top:0px;
    margin-top: -20px;
}
#profileBelt #rowBelts #beltPremium .buttonPlus{
    position: absolute;
    right: 6px;
    top:7px;
}
#profileBelt #rowBelts #beltPremium #premiumText{
    position: absolute;
    top: 6px;
    bottom: 7px;
    left: 54px;
    right: 49px;
    display: flex;
    justify-content: center;
    align-items: center;

     display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;


    color:white;
    font-weight: bold;
    font-size: 20px;
    .myTextBorder05p(black);
}
#profileBelt #rowBelts .buttonPlus{
    background-image: url(/gfx/belt_top/plus.png);
    width: 37px;
    height: 37px;
}
#profileBelt #rowBelts .buttonPlusTrigger{
    &:hover,&.hover{
        .buttonPlus{
            background-position: right top;
        }
    }
}
#profileBelt #rowBelts .buttonPlusTrigger:active .buttonPlus{
    background-position: center top;
}

#profileBelt #rowBelts #beltEnergy #beltEnergyBars .belt.beltSkill{
    background-position: left center;
}
#profileBelt #rowBelts #beltEnergy #beltEnergyBars .belt.beltCash{

    background-position: left bottom;
}
#profileBelt #rowBelts #beltEnergy #beltEnergyBars .belt.beltPosition{
    background-position: left top;
}
#profileBelt #rowBelts #beltEnergy #beltEnergyBars .belt.beltFight{
    background-image: url(/gfx/belt_top/belt_color_fio.png);
}
#profileBelt .missionRow{
    position: absolute;
    left:62px;
    top:1px;
    width: 72px;
    height: 114px;
    .img{
        .fourDirection;
        background-image: url(/gfx/belt_top/oskar.png);
        background-position: left bottom;
        background-repeat: no-repeat;
    }
    &:hover .img{
        background-position: left top;
    }
}
#profileBelt .missionRow.blink:after{
    content:"";
    background-image: url(/gfx/belt_top/wyk.png);
    width: 30px;
    height: 53px;
    position: absolute;
    right: -2px;
    bottom:10px;
}
#profileBelt .buttonCircle{
    width: 44px;
    height: 42px;
    background-image: url(/gfx/belt_top/but.png);
    position: relative;
}
#profileBelt .buttonCircle:hover{
    background-position: right top;
}
#profileBelt .buttonCircle:active{
    background-position: center top;
}
#profileBelt .buttonCircle>.img{
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    width: 28px;
    height: 25px;
    top:50%;
    left:50%;
    margin-left: -14px;
    margin-top: -12px;
    background-image: url(/gfx/belt_top/icons.png);
}
#profileBelt .buttonCircle.first{
    position: absolute;
    top:26px;
    left:6px;
    .pointer;
}
#profileBelt .buttonCircle.sound .img{
    background-position: 0px -58px;
    width: 28px;
    height: 28px;
    margin-left: -12px;
    margin-top: -12px;
}
#profileBelt .buttonCircle.sound[data-type="off"] .img{
    background-position: left bottom;
}
#profileBelt .buttonCircle.settings .img{
    background-position: 0px -29px;
    width: 28px;
    height: 27px;
    margin-left: -13px;
    margin-top: -11px;
}
#profileBelt .buttonCircle.home .img{
    background-position: 0px 0px;
    width: 27px;
    height: 27px;
    margin-left: -13px;
    margin-top: -12px;
}
#profileBelt .buttonCircle.logout .img{
    background-position: 0px 0px;
    width: 24px;
    height: 29px;
    margin-left: -12px;
    margin-top: -12px;
    background-image: url(/gfx/belt_top/logout.png);
}

#myGameCover{
    position: absolute;
    top: 112px;
    left: 14px;
    bottom: 17px;
    right: 14px;
    z-index: 101;
    display: none;
}
#gameWhole.space #myGameCover{
    top:20px;
}
#myGameCover #myGameCoverOpacity{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    position: relative;
}
#myGameCover .closeCover.main{
    position: absolute;
    cursor: pointer;
    z-index: 10;
}



.shOne{
    display: inline-block;
    padding-left: 36px;
    padding-right: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    position: relative;
    font-size:16px;
    white-space: nowrap;
    &.big{
        font-size:24px;
        padding-left: 75px;
        &:before{
            margin-top: -30px;
            width: 60px;
            height: 60px;
        }
    }
}
.shOne:before{
    content: "";
    position: absolute;
    left:3px;
    top:50%;
    margin-top: -13px;
    width: 26px;
    height: 26px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;


}
.shOne.energySkill:before{
    background-image: url(/gfx/window/skill.png);
}
.shOne.energyPosition:before{
    background-image: url(/gfx/window/position.png);
}
.shOne.energyWork:before{
    background-image: url(/gfx/window/work.png);
}
.shOne.premium:before{
    background-image: url(/gfx/icons/premium.png);
}
.shOne.cash:before{
    background-image: url(/gfx/window/dolor.png);
}
.shOne.happy:before{
    background-image: url(/gfx/icons/happy.png);
}
.shOne.energy:before{
    background-image: url(/gfx/window/energy.png);
}
.shOne.time:before{
    background-image: url(/gfx/icons/clock.png);
}
.shOne.skill:before{
    background-image: url(/gfx/window/skill.png);
}

.shOne.skillfight[data-type='1']:before{
    background-image: url(/gfx/place/girlown/skill1_big.png);
}
.shOne.skillfight[data-type='2']:before{
    background-image: url(/gfx/place/girlown/skill2_big.png);
}
.shOne.skillfight[data-type='3']:before{
    background-image: url(/gfx/place/girlown/skill3_big.png);
}
.shOne.skillfight[data-type='4']:before{
    background-image: url(/gfx/place/girlown/skill4_big.png);
}

.shOne.nutaku_gold:before{
    background-image: url(/gfx/place/premiumshop/nutaku_gold.png);
}
.shOne.wordsGood:before{
    background-image: url(/gfx/place/girls/ninja/good4.png);
}
.shOne.wordsBad:before{
    background-image: url(/gfx/place/girls/ninja/bad4.png);
}
.shOne.timeOne:before{
    background-image: url(/gfx/place/rooms/time.png);
    width: 22px;
    height: 24px;
    margin-top: -11px;
}
.shOne.fame:before{
    background-image: url(/gfx/place/fight/fame_big.png);
}
.shOne.defense:before{
    background-image: url(/gfx/icons/defense_min.png);
}
.shOne.shower_cap:before{
    background-image: url(/gfx/icons/shower_cap.png);
}
.shOne.cosmetic{
    &:before{
        background-image: url(/gfx/cosmetic/icon_back.png);
    }
    .img{
        .imageCustom;
        width:54px;
        height: 54px;
        position: absolute;
        left:5px;
        top:50%;
        margin-top: -26px;
    }
}
#content_main .place{
    position: relative;
    width: 1280px;
    height: 780px;
    overflow: hidden;
}
.qsmoke{
    position:absolute;
    padding: 10px;
    background-image: url(/gfx/place/girls/smoke_ext.png);
    border-radius: 12px;
    width: 260px;
    min-height: 50px;
}
.qsmoke:after{
    content: "";
    background-image: url(/gfx/place/girls/smoke_right.png);
    position: absolute;
    right: -60px;
    top:10px;
    width: 60px;
    height: 55px;
    opacity: 0.9;
}
.myout{
    border: 13px solid #E944B0;
    border-radius: 25px;
    background-color: #2CC7FF;
}
.myout:after{
    content: "";
    position: absolute;
    background-image: url(/gfx/place/girls/border_flash.png);
    width: 24px;
    height: 44px;
    left: -12px;
    top: -12px;
}
.myout:before{
    content: "";
    position: absolute;
    background-image: url(/gfx/place/girls/border_flash.png);
    width: 17px;
    height: 13px;
    background-position: -24px 0px;
    top: -10px;
    right: -10px;
}
.gameCoverContent.myout:after{
    top: 0px;
    left: 0px;
}
.gameCoverContent.myout:before{
    right: 2px;
    top: 2px;
}
.cell.blank{
    opacity: 0;
    cursor:default;
}
.imageCustom>.number.count{
    position: absolute;
    top:8px;
    right: 8px;
    color: black;
    padding: 4px;
    font-weight: bold;
    font-size:15px;
    border-radius: 6px;
    background-color: rgba(255,255,255,0.9);
}
.placeEvent{
    position: relative;
    width: 600px;
    height: 320px;
}
#tmpUseIt{
    position: absolute;
    opacity: 0;
    width: 0px;
    height: 0px;
    left: 0px;
    top:0px;
    overflow: hidden;
}
#mainBeltRightDownCorner{
    position: absolute;
    left: 19px;
    bottom: 19px;
    width: 256px;
    height: 78px;

    .mainObj{
        display: none;
        border-top-right-radius:15px;
        background-color: rgba(0,0,0,0.4);
        height:100%;
        &[data-type="bonus"]{
            .content{
                position: relative;
                padding: 5px 0px 0px 95px;
                width: 155px;
                height: 65px;
                color:white;
                font-size:20px;
                .flexColumnCenterAround;
                text-align:center;
                .val{
                    font-size:30px;
                }
            }
            .imgMain{
                position: absolute;
                left: 5px;
                top: 5px;
                height: 68px;
                width: 72px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url(/gfx/place/premiumshop/big_icons/multitime.png);
            }
            &[data-color="low"]{
                .myTextBorder3p(#138099);
            }
            &[data-color="medium"]{
                //b0e91d
                //176,233,29
                .myTextBorder3p(#637D15);
            }
            &[data-color="high"]{
                ///ff3aae
                //255,58,174
                .myTextBorder3p(#CE002C);
            }
        }
    }


}
#mainBeltRightDownCorner #mainEmailConf.mainObj{
    background-color: rgba(0,0,0,0.4);
    position: relative;
    .flexRowCenterCenter;
    padding: 0px 5px;
    height:78px;
    border-top-right-radius:15px;
    cursor:pointer;
    .iconErr{
        background-image: url(/gfx/window/warning.png);
        width: 55px;
        height: 55px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin-right: 5px;
    }
    .textObj{
        width: 184px;
        height: 60px;
        margin-right: 5px;
        color:white;
        .flexColumnLeftAroundCenter;
        .rowLogin{
            width: 130px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size:16px;

        }
        .rowDesc{
            width: 100%;
            text-align: center;
            font-size: 13px;
        }
    }
    .iconDiam{
        background-image: url(/gfx/place/premiumshop/premium_icons/p1.png);
        width: 57px;
        height: 39px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        right: 3px;
        top: 0px;
        .text{
            position: absolute;
            left: 25px;
            top: 16px;
            .myTextBorder3p(#171717);
            font-size: 16px;
            color:white;

        }
    }
}
#mainErrorReporting{
    background-color: rgba(0,0,0,0.4);
    position: absolute;
    right: 30px;
    bottom: 19px;
    .flexColumnCenterAround;
    height:78px;
    border-radius:15px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    cursor:pointer;
    &.hover{
        background-color: rgba(255,203,53,0.4);
    }
    >.icon{
        width: 64px;
        height: 41px;
        background-image: url(/gfx/window/error.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        padding: 0px 5px;
    }
    >.text{
        font-size:16px;
        color:white;
        padding: 0px 5px;
    }
}
// #ic_premium{
//     position: absolute;
//     right: 19px;
//     bottom: 18px;
//     width: 252px;
//     height:79px;
//     background-repeat: no-repeat;
//     background-image: url(/gfx/place/premium/down_ui/back.png);
//     cursor:pointer;
//     >.img{
//         background-repeat: no-repeat;
//         background-image: url(/gfx/place/premium/down_ui/gift.png);
//         width: 66px;
//         height: 71px;
//         position: absolute;
//         top:3px;
//         right:10px;
//     }
//     >.content{
//         position: absolute;
//         top:3px;
//         left:10px;
//         width: 158px;
//         height: 71px;
//         .flexColumnCenterAround;
//         >.name{
//             .myTextBorder3p(#0b273d);
//             color:white;
//             font-size: 23px;
//
//         }
//         #ic_premium_timer{
//             .myTextBorder2p(#0b273d);
//             color:#ff7a8d;
//             font-size:22px;
//         }
//     }
//     &.valen{
//         background-image: url(/gfx/place/premiumone/pack_valen/down/down_premium.png);
//         >.img{
//             background-image: url(/gfx/place/premiumone/pack_valen/down/icon.png);
//             top: 5px;
//         }
//
//          >.content{
//             >.name{
//                 .myTextBorder3p(#17283a);
//
//             }
//             #ic_premium_timer{
//                 .myTextBorder2p(#17283a);
//                 color:#c1ff04;
//             }
//         }
//     }
//     &.spring{
//         background-image: url(/gfx/place/premiumone/pack_spring/down/back.png);
//         >.img{
//             background-image: url(/gfx/place/premiumone/pack_spring/down/gift.png);
//             top: 5px;
//         }
//
//          >.content{
//             >.name{
//                 .myTextBorder3p(#113633);
//
//             }
//             #ic_premium_timer{
//                 .myTextBorder2p(#113633);
//                 color:#96e4ff;
//             }
//         }
//     }
// }
#mainButtonsBar{
    position: absolute;
    left:50%;
    width: 740px;
    margin-left: -368px;

    bottom: 21px;
    z-index: 2;


    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;


    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;



    &:before{
        content: "";
        position: absolute;
        left:-10px;
        right: -10px;
        bottom:-10px;
        top:-20px;
        background-color: rgba(0,0,0,0.4);
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }

    .row{
        width: 97px;
        height: 41px;
        text-align: center;
        position: relative;
        margin-bottom:15px;
        .back{
            position: absolute;
            width: 88px;
            height: 35px;
            bottom: 6px;
            left:50%;
            margin-left: -44px;
            background-image: url(/gfx/belt_down/back.png);
            background-repeat: no-repeat;
        }
        &.prem .back{
            background-image: url(/gfx/belt_down/back_pre.png);
        }
        .flash{
            width: 104px;
            height: 72px;
            position: absolute;
            bottom:0px;
            left:50%;
            margin-left: -52px;
            background-image: url(/gfx/belt_down/flash.png);
            display: none;
        }
        .front{
            position: absolute;
            width: 97px;
            height: 41px;
            bottom:0px;
            left:0px;
            background-image: url(/gfx/belt_down/front.png);
            background-repeat: no-repeat;
            border-bottom-left-radius: 7px;
            border-bottom-right-radius: 7px;
        }
        &.prem .front{
            background-image: url(/gfx/belt_down/front_pre.png);
        }
        .icon{
            position: absolute;
            background-image: url(/gfx/belt_down/icons.png);
            left:50%;
            top:0px;
        }
        .icon:after{
            content: "";
            position: absolute;
            bottom:-40px;
            left:0px;
            right: 0px;
            top:0px;
        }
        .icon[data-type="map"]{
            width: 71px;
            height: 56px;
            margin-top: -25px;
            margin-left: -40px;
        }
        .icon[data-type="girls"]{
            width: 85px;
            height: 72px;
            margin-top: -32px;
            margin-left: -42px;
            background-position: 0px -56px;
        }
        .icon[data-type="cash"]{
            width: 74px;
            height: 65px;
            margin-top: -26px;
            margin-left: -37px;
            background-position: 0px -128px;
        }
        .icon[data-type="cams"]{
            width: 79px;
            height: 61px;
            margin-top: -26px;
            margin-left: -40px;
            background-position: 0px -252px;
        }
        .icon[data-type="skills"]{
            width: 77px;
            height: 59px;
            margin-top: -24px;
            margin-left: -38px;
            background-position: 0px -193px;
        }
        .icon[data-type="premiumshop"]{
            width: 75px;
            height: 54px;
            margin-top: -23px;
            margin-left: -37px;
            background-position: 0px -313px;
        }
        .icon[data-type="pvp"]{
            width: 78px;
            height: 71px;
            margin-left: -39px;
            margin-top: -31px;
            background-image: url(/gfx/belt_down/girlpvp.png);
        }
        .butText{
            position: absolute;
            left:0px;
            right: 0px;
            bottom:0px;
            height: 24px;
            color:white;
            font-size:16px;
            .myTextBorder3p(#3A1928);
            .flexColumnCenterAround;
        }
        &[data-active="1"]{
            .icon{
                /*top:-35px !important;*/
            }

            &[data-blink="1"]{
                .front{
                    background-image: url(/gfx/belt_down/front_down.png);
                    background-repeat: no-repeat;
                }
                &.prem .front{
                    background-position: left bottom;
                }
                .flash{
                    display: block;
                }
            }
        }
        &:hover .butText{
 //           .myTextBorder3p(white);
            color:#0CB8E8;
        }
    }
}
.arrowView{
    background-image: url(/gfx/ui/arrows/blue.png);
    .imageCustom;
    width: 61px;
    height: 80px;
    z-index:2;

    &[data-rotation="90"]{
        .rotation(90);
    }
    &[data-rotation="180"]{
        .rotation(180);
    }
    &[data-rotation="270"]{
        .rotation(270);
    }
    &.green{
        background-image: url(/gfx/ui/arrows/green.png);
    }
    &.red{
        background-image: url(/gfx/ui/arrows/red.png);
    }
    &.yellow{
        background-image: url(/gfx/ui/arrows/yellow.png);
    }

}
.moduleArrow{
    .arrowView;
}
.book,.placeEvent,.bookView{
    >.capBig{
        position: absolute;
        left:0px;
        right: 0px;
        top:-35px;
        .flexRowAroundCenter;
        >.in{
            min-width: 235px;
            height: 52px;
            position: relative;
            .flexRowAroundCenter;
            z-index: 1;
            -webkit-box-shadow: 0px 9px 37px -5px rgba(107,57,23,1);
-moz-box-shadow: 0px 9px 37px -5px rgba(107,57,23,1);
box-shadow: 0px 9px 37px -5px rgba(107,57,23,1);
            >.content{
                z-index: 1;
                padding: 0px 22px;
                position: relative;
                .myTextBorder3p(#90195A);
                color:white;
                font-size:28px;
            }
            >.up{
                position: absolute;
                top: 0px;
                right: -6px;
                width: 115px;
                height: 55px;
                background-image: url(/gfx/place/rooms/book_big/leftright.png);
                background-position: right top;
            }
            >.down{
                position: absolute;
                top: 0px;
                left: -4px;
                width: 108px;
                height: 55px;
                background-image: url(/gfx/place/rooms/book_big/leftright.png);
            }
            /*&:before{
                content:"";
                position: absolute;
                background-image: url(/gfx/place/rooms/book_big/down.png);
                height: 9px;
                bottom:-9px;
                left: 0px;
                right: 0px;
            }*/
            &:after{
                content: "";
                position: absolute;
                right: 107px;
                left: 102px;
                top: 0px;
                height: 55px;
                background-image: url(/gfx/place/rooms/book_big/ext.png);
            }
        }
    }

    &.withGirls{
        >.content{
            right: 175px;
            left: 175px;
        }
        >.girlBack{
            position: absolute;
            background-position: center bottom;
            background-repeat: no-repeat;
            background-size: contain;
            top:0px;
            bottom:20px;
            width:245px;
            &[data-img="1"]{
                left: -22px;
                background-image: url(/gfx/girls/cherry/position/ready/0.png?1);
                -webkit-transform : scale(-1,1);
                -moz-transform    : scale(-1,1);
                -ms-transform     : scale(-1,1);
                -o-transform      : scale(-1,1);
                transform         : scale(-1,1);
            }
            &[data-img="2"]{
                right: -31px;
                background-image: url(/gfx/girls/ice/position/ready/0.png);
            }
        }
    }




}
.book.custom{

    &.medium{
        background-position: 0px 36px;
        background-repeat: no-repeat;
        width:649px;
        height:460px;
        background-image:url(/gfx/place/girls/gift/win_center.jpg);
        &>.up{
            position: absolute;
            left:0px;
            top:0px;
            width:649px;
            height:38px;
            background-position: left bottom;
            background-image:url(/gfx/place/girls/gift/win.png);
        }
        &>.down{
            position: absolute;
            left:0px;
            bottom:0px;
            width:649px;
            height:41px;
            background-image:url(/gfx/place/girls/gift/win.png);
        }
        &>.content{
            position: absolute;
            top:40px;
            left:20px;
            right:20px;
            bottom:40px;
             display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
        }
    }
    &.medium2{
        background-position: 0px 35px;
        background-repeat: no-repeat;
        width:649px;
        height:402px;
        background-image:url(/gfx/place/rooms/filmduring/center.jpg);
        &>.up{
            position: absolute;
            left:0px;
            top:0px;
            width:649px;
            height:37px;
            background-repeat: no-repeat;
            background-image:url(/gfx/place/rooms/filmduring/updown.png?2);
        }
        &>.down{
            position: absolute;
            left:0px;
            bottom:0px;
            width:649px;
            height:42px;
            background-repeat: no-repeat;
            background-image:url(/gfx/place/rooms/filmduring/updown.png?2);
            background-position: left bottom;
        }
        &>.content{
            position: absolute;
            top: 23px;
            left: 20px;
            right: 20px;
            bottom: 27px;
        }
    }
}
.myPreloader{
    .fourDirection;
    background-color: rgba(0,0,0,0.95);
    z-index: 199;
    canvas{
        position: absolute;
        left:50%;
        top:50%;
        width: 400px;
        height: 400px;
        z-index: 200;
        margin-left: -200px;
        margin-top: -200px;
    }
}
.book.popupQuestTop{
    top:50px !important;
    margin-top: 0px !important;
}
.tutorialBox{
    position:relative;
    >.content{
        position:absolute;
        top: 14px;
        left: 26px;
        right: 26px;
        bottom: 15px;
        >.cap,>.desc{
            width: 100%;
        }
        .textTut{
            width: 100%;
            height: 100%;
            &.center{
                .flexColumnCenterAround;
                >span{
                    width: 100%;
                }
            }
        }
        >.cap{
            color:white;
            .myTextBorder3p(#125682);
            font-size:23px;
            .textTut>span{
                text-align: center;

            }
        }
        >.desc{
            color:#067DC9;
            font-size:20px;
        }
        .flexColumnCenterBetween;


    }
    &:before{
        content:"";
        position: absolute;
        width: 64px;
        height:64px;
        background-image: url(/gfx/window/tutorial/icon_info.png);
        background-repeat: no-repeat;
        left: 36px;
            top: -37px;
    }
    &.wide{
        background-image:url(/gfx/window/tutorial/long.png);
        width: 630px;
        height:170px;
        .cap{
            height: 40px;
        }
        .desc{
            height: 90px;
            &.textfill.center > span{
                text-align: left;
            }
        }
    }
    &.mediumLong{
        background-image:url(/gfx/window/tutorial/medium.png);
        width:382px;
        height:310px;
        .cap{
            height: 60px;
        }
        .desc{
            height: 200px;
        }
    }
    &.small{
        background-image:url(/gfx/window/tutorial/small.png);
        width:382px;
        height:271px;
        .cap{
            height: 50px;
        }
        .desc{
            height: 180px;
        }
    }
}
.myButton.bigNext{
    background-image: url(/gfx/place/rooms/book_big/but.png);
    background-repeat: no-repeat;
    width: 154px;
    height: 48px;
    padding-right: 18px;
    position: relative;
    color:white;
    font-size:22px;
    cursor: pointer;
    &.springEvent {
        position: absolute;
        right: 20px;
        bottom: 20px;
        z-index: 5;
    }
    .myTextBorder3p(#657F15);
    &.unable{
        background-image: url(/gfx/place/rooms/book_big/but_unable.png);
    }

    &:hover{
        background-position: left bottom;
        .img:after{
            content: "";
            position: absolute;
            right: 1px;
            top: 5px;
            width: 43px;
            height: 41px;
            background-image: url(/gfx/place/rooms/book_big/icons_set.png);
            background-position: -28px -245px;
        }
    }
    &:hover.unable .img:after{
        display: none;
    }
    &:active{
        background-position: left center;
    }
    .in{
        .flexColumnCenterAround;
        .fourDirection;
        left: 3px;
        right: 28px;
    }
    .img{
        position: absolute;
        right:0px;
        top:0px;
        width: 48px;
        height: 48px;
        background-image: url(/gfx/place/rooms/book_big/icons_set.png);
        background-position: 0px -197px;

    }
}
.fakelayBanners {
    width: 700px;
    height: 180px;
    background-size: contain;
    border: 1px solid #ffffff;
}

.endlessfunGamesLogo {
  background: url("/gfx/master/endlesgameIcon.png") no-repeat;
  width: 237px;
  height: 243px;
  cursor: pointer;
  &:hover {
    background-position: 0 -246px;
  }
  &:active {
    background-position: 0 -492px;
  }
  &.absolute {
    position: absolute;
    z-index: 5;
    &.bottom {
      bottom:10px;
    }
    &.left {
      left:10px;
    }
    &.right {
      right:10px;
    }
    &.top {
      top: 10px;
    }
  }
}

.place.splashscreen{
  display:flex;
  .allGirlProfiles {
    position: relative;
    bottom:0;
    margin:591px auto;
  }
  .formSubmit {
    background: url(/gfx/place/beatthebunny/button.png);
    width: 364px;
    height: 113px;
    bottom: 117px;
    left: 50%;
    margin-left: -165px;
    position: absolute;
    z-index: 1;
    >.in {
      height: 100px;
      .myTextBorder3p(#325112);
      >.textfill {
        height: 80%;
      }
    }
    &:hover{
      background-position: 0 -117px;
    }
    &:active{
      background-position: 0 -234px;
    }
  }
  .fakelayPromo {
    top:unset;
    bottom:20px;
    left:20px;
    margin: 0;
  }
  .endlessfunGamesLogo {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}
